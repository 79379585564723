import React, { useContext } from "react";
import { PlateDataContext } from "../PlateForm/PlateDataContext";

export default function InfoUnit(props) {
  const { name, hidden, value } = props;
  const { unit } = useContext(PlateDataContext);

  return (
    <div className={`form-group form-group-noError ${hidden ? "hidden" : ""}`}>
      <div className="label">{name}:</div>
      <div className="info-text">
        {value[unit.keyName]}
        {/* <small className="unit">{unit.displayUC}</small> */}
      </div>
      {/* {error && <div className="error">{error}</div>} */}
    </div>
  );
}
