import { getHoleData } from "../Features/FeatureHole/helpers";

//6/9/21
//BrainFart: This would be a shit ton easier if the OS macro gave me a
//  point list. Then I would just have to draw circle at each point.
//
//  This would give ONE location to create the geometry. I currently
//  create it in the featureScript, previewFeatureHole, and python gCode.
//
//  Could also add tool.
//
//
// Warning: This does not solve centerline radius preview.

const previewFeatureHole = (
  ctxRefPreview,
  previewData,
  currentView,
  scale,
  xCenter,
  yCenter,
  x,
  y
) => {
  // for layout visual reference border
  //console.log(x, y);
  // ctxRefPreview.current.beginPath();
  // ctxRefPreview.current.rect(1, 1, xCenter * 2 - 2, yCenter * 2 - 2);
  // // ctxRefPreview.current.rect(
  // //   xCenter - (x * scale) / 2,
  // //   yCenter - (y * scale) / 2,
  // //   x * scale,
  // //   y * scale
  // // );
  // ctxRefPreview.current.stroke();
  // end for layout visual reference border

  // cubeFace vs currentView?
  // I think it is best to set currentView for cubeFace for now
  // this already works, bonus would be to disable view buttons

  // future
  // i think we want to have a checkbox option to show hidden holes
  // this will show cross holes and blind holes from other side
  // ideally we can also type in depth

  // strategy should follow OS
  // 1. we want to determine current cubeFace and draw in the matching view
  // DONE
  // 2. Set origin and offsets

  let xStart = 0;
  let yStart = 0;

  //console.log("previewData.featureOrigin", previewData.featureOrigin);
  switch (previewData.featureOrigin) {
    case "CENTER":
      xStart = xCenter + previewData.xOffset.meter * scale;
      yStart = yCenter - previewData.yOffset.meter * scale;
      break;

    case "TOP_LEFT":
      xStart = xCenter - (x / 2) * scale + previewData.xOffset.meter * scale;
      yStart = yCenter - (y / 2) * scale - previewData.yOffset.meter * scale;
      break;

    case "TOP_RIGHT":
      xStart = xCenter + (x / 2) * scale + previewData.xOffset.meter * scale;
      yStart = yCenter - (y / 2) * scale - previewData.yOffset.meter * scale;
      break;

    case "BOTTOM_RIGHT":
      xStart = xCenter + (x / 2) * scale + previewData.xOffset.meter * scale;
      yStart = yCenter + (y / 2) * scale - previewData.yOffset.meter * scale;
      break;

    case "BOTTOM_LEFT":
      xStart = xCenter - (x / 2) * scale + previewData.xOffset.meter * scale;
      yStart = yCenter + (y / 2) * scale - previewData.yOffset.meter * scale;
      break;

    default:
      break;
  }

  // 3. Set circles from hole specification

  const diameters = getHoleData(previewData);

  const outerRadius = (diameters.outerDiameter / 2) * scale;
  const innerRadius = (diameters.innerDiameter / 2) * scale;

  // 4. Pattern following pattern

  //  "featurePattern": "NONE",
  switch (previewData.featurePattern) {
    case "NONE":
      ctxRefPreview.current.beginPath();
      ctxRefPreview.current.arc(xStart, yStart, outerRadius, 0, 2 * Math.PI);
      ctxRefPreview.current.stroke();
      ctxRefPreview.current.moveTo(xStart + innerRadius, yStart);
      ctxRefPreview.current.arc(xStart, yStart, innerRadius, 0, 2 * Math.PI);
      ctxRefPreview.current.moveTo(xStart + -5, yStart);
      ctxRefPreview.current.lineTo(xStart + 5, yStart);
      ctxRefPreview.current.moveTo(xStart, yStart + 5);
      ctxRefPreview.current.lineTo(xStart, yStart - 5);
      ctxRefPreview.current.stroke();

      break;

    case "LINEAR":
      switch (previewData.patternOrigin) {
        case "CENTER":
          if (previewData.numberX > 1) {
            xStart =
              xStart -
              (previewData.patternX.meter * scale * (previewData.numberX - 1)) /
                2;
          }

          if (previewData.numberY > 1) {
            yStart =
              yStart +
              (previewData.patternY.meter * scale * (previewData.numberY - 1)) /
                2;
          }
          break;

        case "TOP_LEFT":
          // if (previewData.numberX > 1) {
          //   xStart = xStart;
          // }

          if (previewData.numberY > 1) {
            yStart =
              yStart +
              previewData.patternY.meter * scale * (previewData.numberY - 1);
          }

          break;

        case "TOP_RIGHT":
          if (previewData.numberX > 1) {
            xStart =
              xStart -
              previewData.patternX.meter * scale * (previewData.numberX - 1);
          }

          if (previewData.numberY > 1) {
            yStart =
              yStart +
              previewData.patternY.meter * scale * (previewData.numberY - 1);
          }
          break;

        case "BOTTOM_RIGHT":
          if (previewData.numberX > 1) {
            xStart =
              xStart -
              previewData.patternX.meter * scale * (previewData.numberX - 1);
          }

          // if (previewData.numberY > 1) {
          //   yStart = yStart;
          // }
          break;

        case "BOTTOM_LEFT":
          // if (previewData.numberX > 1) {
          //   xStart = xStart;
          // }

          // if (previewData.numberY > 1) {
          //   yStart = yStart;
          // }
          break;

        default:
          break;
      }

      let xPos = 0;
      let yPos = 0;
      let xInc;
      let yInc;
      for (xInc = 0; xInc < previewData.numberX; xInc += 1) {
        for (yInc = 0; yInc < previewData.numberY; yInc += 1) {
          ctxRefPreview.current.beginPath();
          ctxRefPreview.current.arc(
            xStart + xPos,
            yStart - yPos,
            outerRadius,
            0,
            2 * Math.PI
          );
          ctxRefPreview.current.moveTo(
            xStart + xPos + innerRadius,
            yStart - yPos
          );
          ctxRefPreview.current.arc(
            xStart + xPos,
            yStart - yPos,
            innerRadius,
            0,
            2 * Math.PI
          );
          ctxRefPreview.current.moveTo(xStart + xPos + -5, yStart - yPos);
          ctxRefPreview.current.lineTo(xStart + xPos + 5, yStart - yPos);
          ctxRefPreview.current.moveTo(xStart + xPos, yStart - yPos + 5);
          ctxRefPreview.current.lineTo(xStart + xPos, yStart - yPos - 5);
          ctxRefPreview.current.stroke();
          //console.log("xPos, yPos", xPos, yPos);

          yPos = yPos + previewData.patternY.meter * scale;
        }

        xPos = xPos + previewData.patternX.meter * scale;
        yPos = 0;
      }

      break;

    case "CIRCULAR":
      //***** Angle Increment ****
      //ctxRefPreview.current.beginPath();

      let angleIncrement = 0;
      if (
        Number(previewData.arcAngle) === 360 ||
        Number(previewData.arcAngle) === -360
      ) {
        angleIncrement = previewData.arcAngle / previewData.numberCircular;
      } else {
        angleIncrement =
          previewData.arcAngle / (previewData.numberCircular - 1);
      }
      //console.log("angleIncrement", angleIncrement);
      //console.log("previewData.startAngle", previewData.startAngle);
      // console.log(
      //   "previewData.patternDiameter.meter",
      //   previewData.patternDiameter.meter
      // );

      const radius = (previewData.patternDiameter.meter / 2) * scale;

      ctxRefPreview.current.translate(xStart, yStart);

      ctxRefPreview.current.beginPath();
      ctxRefPreview.current.moveTo(radius, 0);
      ctxRefPreview.current.stroke();

      ctxRefPreview.current.beginPath();
      ctxRefPreview.current.rotate((previewData.startAngle * Math.PI) / 180);
      let antiClockwise = false;
      if (previewData.arcAngle < 0) antiClockwise = true;
      ctxRefPreview.current.arc(
        0,
        0,
        (previewData.patternDiameter.meter / 2) * scale,
        (-90 * Math.PI) / 180,
        ((previewData.arcAngle - 90) * Math.PI) / 180,
        antiClockwise
      );
      ctxRefPreview.current.moveTo(0, -radius + outerRadius + 10);
      ctxRefPreview.current.lineTo(0, -radius - outerRadius - 10);

      ctxRefPreview.current.stroke();

      for (let aCount = 0; aCount < previewData.numberCircular; aCount += 1) {
        // let currentAngle =
        //   Number(previewData.startAngle) + angleIncrement * aCount;

        // console.log("///////////////////////////////////");
        // console.log("previewData.startAngle", previewData.startAngle);
        // console.log("aCount", aCount);
        // console.log("angleIncrement", angleIncrement);
        // console.log("currentAngle", currentAngle);

        ctxRefPreview.current.beginPath();
        // Note rotate is incremental
        if (aCount !== 0) {
          ctxRefPreview.current.rotate((angleIncrement * Math.PI) / 180);
        }
        ctxRefPreview.current.arc(0, -radius, outerRadius, 0, 2 * Math.PI);
        ctxRefPreview.current.moveTo(0 + innerRadius, -radius);
        ctxRefPreview.current.arc(0, -radius, innerRadius, 0, 2 * Math.PI);
        ctxRefPreview.current.moveTo(-5, -radius);
        ctxRefPreview.current.lineTo(+5, -radius);
        ctxRefPreview.current.moveTo(0, -radius + 5);
        ctxRefPreview.current.lineTo(0, -radius - 5);
        if (aCount === previewData.numberCircular - 1) {
          // if (
          //   Number(previewData.arcAngle !== 360) ||
          //   previewData.arcAngle !== -360
          // ) {
          //   ctxRefPreview.current.moveTo(0, -radius + outerRadius + 10);
          //   ctxRefPreview.current.lineTo(0, -radius - outerRadius - 10);
          // }
          if (
            Number(previewData.arcAngle !== 360) &&
            Number(previewData.arcAngle !== -360)
          ) {
            ctxRefPreview.current.moveTo(0, -radius + outerRadius + 10);
            ctxRefPreview.current.lineTo(0, -radius - outerRadius - 10);
          }
        }
        ctxRefPreview.current.stroke();
      }

      break;

    default:
      break;
  }
};

export default previewFeatureHole;
