import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Layout from "../core/Layout";
import axios from "axios";
import { isAuth } from "./helpers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const Signup = () => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    buttonText: "Submit",
  });

  const { name, email, password, buttonText } = values;

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const clickSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, buttonText: "Submitting" });
    console.log("ClickSubmit values: ", values)
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API}/signup`,
      data: { name, email, password },
    })
      .then((response) => {
        console.log("SIGNUP SUCCESS", response);
        setValues({
          ...values,
          name: "",
          email: "",
          password: "",
          buttonText: "Submitted",
        });
        toast.success(response.data.message);
      })
      .catch((error) => {
        console.log("SIGNUP ERROR", error.response.data);
        setValues({ ...values, buttonText: "Submit" });
        toast.error(error.response.data.error);
      });
  };

  const signupForm = () => (
    <form className="signin">
      <div className="form-group form-group-main">
        <i
          className="far fa-user login-icon"
          style={{
            color: "hsl(194, 55%, 30%)",
          }}
        ></i>
        <input
          onChange={handleChange("name")}
          value={name}
          type="text"
          placeholder="Name"
          className="input input-main"
        />
      </div>
      <div className="form-group form-group-main">
        <i
          className="fas fa-envelope-square login-icon"
          style={{
            color: "hsl(194, 55%, 30%)",
          }}
        ></i>
        <input
          onChange={handleChange("email")}
          value={email}
          type="email"
          placeholder="Email"
          className="input input-main"
        />
      </div>
      <div className="form-group form-group-main">
        <i
          className="fas fa-lock login-icon"
          style={{
            color: "hsl(194, 55%, 30%)",
          }}
        ></i>
        <input
          onChange={handleChange("password")}
          value={password}
          type="password"
          placeholder="Password"
          className="input input-main"
        />
      </div>
      <button onClick={clickSubmit} className="btn btn-login">
        <i className="fas fa-sign-in-alt login-icon"></i>
        {buttonText}
      </button>
    </form>
  );

  return (
    <Layout imageOn={true}>
      <div className="signin">
        <div className="info info-signin">
          <ToastContainer />
          {isAuth() ? <Redirect to="/" /> : null}
          {/* {JSON.stringify({ name, email, password })} */}
          <h1>Signup</h1>
          {signupForm()}
          <Link
            to="/auth/password/forgot"
            className="cta"
            style={{
              fontSize: "12px",
              textDecorationLine: "underline",
            }}
          >
            Forgot Password
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default Signup;
