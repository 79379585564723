import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import App from "./App";
import Signup from "./auth/Signup";
// import Signin from "./auth/Signin";
import Activate from "./auth/Activate";
import Settings from "./core/Settings";
import Privacy from "./core/Privacy";
import Terms from "./core/Terms";
import Admin from "./core/Admin";
import Plate from "./onshape/Plate";
import PartNumber from "./partNumber/PartNumber";
import SettingsRoute from "./auth/SettingsRoute";
import PrivacyRoute from "./auth/PrivacyRoute";
import TermsRoute from "./auth/TermsRoute";
import AdminRoute from "./auth/AdminRoute";
import PlateFormRoute from "./auth/PlateFormRoute";
import PartNumberRoute from "./auth/PartNumberRoute";
import Forgot from "./auth/Forgot";
import Reset from "./auth/Reset";
import PurchaseSuccess from "./Procurement/PurchaseSuccess";

// TODO: 1. prevent user from going to bogus routes (done) 2. prevent logged in user from going to signin page
//        3. allow part numbers to bring up part
// https://www.youtube.com/watch?v=lhMKvyLRWo0&t=196s
// https://stackoverflow.com/questions/34119793/react-router-redirection-after-login  (ITBruise answer)
// https://www.youtube.com/watch?v=VI3Mo6FJCZA&feature=youtu.be

const Routes = () => {
  // useEffect(() => {
  //   console.log("routes called");
  // });

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={App} />
        <Route path="/signup" exact component={Signup} />
        <Route path="/auth/activate/:token" exact component={Activate} />
        <SettingsRoute path="/settings" exact component={Settings} />
        <PrivacyRoute path="/privacy" exact component={Privacy} />
        <TermsRoute path="/terms" exact component={Terms} />
        <AdminRoute path="/admin" exact component={Admin} />
        <PlateFormRoute path="/plate" exact component={Plate} />
        <Route path="/auth/password/forgot" exact component={Forgot} />
        <Route path="/auth/password/reset/:token" exact component={Reset} />
        <PurchaseSuccess
          path="/purchase_success"
          exact
          component={PurchaseSuccess}
        />
        {/* Capture Part Number */}
        <PartNumberRoute path="/:partNumber" component={PartNumber} />
        {/* this redirects in the event of a bogus route*/}
        <Route
          path="/"
          render={() => (
            <div>
              <h1>404 Not Found</h1>
            </div>
          )}
        />
        <Route path="*" component={App} />{" "}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
