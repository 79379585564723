import React from "react";
// import { useState, useEffect } from "react";
import { withResizeDetector } from "react-resize-detector";
//import ReactResizeDetector from "react-resize-detector";
import PlateCanvas from "./PlateCanvas";

const s = {
  columnSub: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    flex: 1,
    fontSize: "30px",
    textAlign: "center",
    // backgroundColor: "lightgreen",
    // border: "1px dotted maroon",
  },
};

const MainFrame = ({
  width,
  height,
  view,
  previewData,
  currentView,
  targetRef,
}) => {
  // For testing only
  // const [count, setCount] = useState(0);
  // useEffect(() => {
  //   if (width || height) {
  //     setCount((count) => count + 1);
  //   }
  // }, [width, height, setCount]);

  return (
    <div ref={targetRef} style={s.columnSub}>
      {/* {console.log(`PlateCanvasWrapper resized ${count} times`)}
      {console.log(`PlateCanvasWrapper Width: ${width}, Height: ${height}`)} */}
      <div className="viewport">
        <PlateCanvas
          targetRef={targetRef}
          parentWidth={width}
          parentHeight={height}
          src={view.src}
          alt={view.alt}
          rotation={view.rotation}
          x={view.x}
          y={view.y}
          z={view.z}
          xText={view.xText}
          yText={view.yText}
          previewData={previewData}
          currentView={currentView}
        />
      </div>
    </div>
  );
};

const MainFrameWithResizeDetector = withResizeDetector(MainFrame);

const PlateCanvasWrapper = ({ view, previewData, currentView }) => {
  // console.log("view", view);
  // console.log("previewData", previewData);
  // console.log("currentView", currentView);
  return (
    <MainFrameWithResizeDetector
      view={view}
      previewData={previewData}
      currentView={currentView}
    />
  );
};

export default PlateCanvasWrapper;
