import React, { useState, useContext, useEffect } from "react";
import { PlateDataContext } from "../PlateForm/PlateDataContext";
import Accordion from "../Accordion/Accordion";
import FeatureExternal from "../Features/FeatureExternal";
import FeatureHole from "../Features/FeatureHole/FeatureHole";
import genericFeatureHole from "../json/genericFeatureHole_v15.json";
import { getCookie, isAuth } from "../../../auth/helpers";
import axios from "axios";
import "../FeatureList/FeatureList.css";
const token = getCookie("token");

export default function FeatureList() {
  const { onshapeData, userData, setUserData, showDevData } = useContext(
    PlateDataContext
  );
  const [currentAccordionOpen, setCurrentAccordionOpen] = useState(() => 0);
  const [updateButtonDisabled, setUpdateButtonDisabled] = useState(false);

  useEffect(() => {
    if (currentAccordionOpen === 0) {
      setUpdateButtonDisabled(false);
    } else {
      setUpdateButtonDisabled(true);
    }
  }, [currentAccordionOpen]);

  //FIXME: Need to make this keep the accordion open
  const handleAddHole = async () => {
    // "/user/feature-hole-data/:id"
    console.log("add hole");

    const loadProfile = () => {
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_API}/user/feature-hole-data/${
          isAuth()._id
        }`,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          console.log("FEATURE HOLE RETRIEVAL", response.data);
          // check if empty
          if (response.data === "") {
            setUserData({
              ...userData,
              ...{
                plate: {
                  features: [...userData.plate.features, genericFeatureHole],
                },
              },
            });
            // setCurrentAccordionOpen(2);
            // // setActive();
            // console.log("CDCDSCDSCDSCDSC");
            // console.log(currentAccordionOpen);
          } else {
            setUserData({
              ...userData,
              ...{
                plate: {
                  features: [...userData.plate.features, response.data],
                },
              },
            });
            // setCurrentAccordionOpen(2);
            // console.log("xsxsdxs");
            // console.log(currentAccordionOpen);
          }
        })
        .catch((error) => {
          console.log(
            "FEATURE HOLE RETRIEVAL ERROR",
            error.response.data.error
          );
          setUserData({
            ...userData,
            ...{
              plate: {
                features: [...userData.plate.features, genericFeatureHole],
              },
            },
          });
        });
    };

    loadProfile();
  };

  //****The below can benefit from useReducer Hook as there are a lot of renders. There are some examples with accordions.
  //**** */ Too tired to deal with it right now */
  return (
    <div className="feature-list">
      {/* <h1>List of Features</h1> */}
      <ul className="feature-list-item">
        {userData.plate.features.map((feature, index) => {
          const accordionIndex = index + 1;
          return (
            <li key={index} className="feature-list-item">
              {/* key: {index}, name: {feature.featureType} */}
              {(() => {
                switch (feature.featureType) {
                  case "featureExternal":
                    //console.log("external ", features);
                    return (
                      <Accordion
                        currentAccordionOpen={currentAccordionOpen}
                        setCurrentAccordionOpen={setCurrentAccordionOpen}
                        accordionIndex={accordionIndex}
                        content={<FeatureExternal featureIndex={index} />}
                      />
                    );
                  case "featureHole":
                    return (
                      <Accordion
                        currentAccordionOpen={currentAccordionOpen}
                        setCurrentAccordionOpen={setCurrentAccordionOpen}
                        accordionIndex={accordionIndex}
                        content={<FeatureHole featureIndex={index} />}
                      />
                    );
                  // case "featurePocket":
                  //   return <PocketAccordion />;
                  // case "featureSlot":
                  //   return <PocketAccordion />;
                  // case "featureMotorMount":
                  //   return <featureMotorMountAccordion />;
                  default:
                    return null;
                }
              })()}
            </li>
          );
        })}
      </ul>
      <ul className="panel nav-list panel-btn data-section">
        <li>
          <button
            className="btn"
            onClick={handleAddHole}
            disabled={updateButtonDisabled}
          >
            Add Hole
          </button>{" "}
        </li>
        {showDevData && (
          <>
            <li>
              <button
                className="btn"
                onClick={() =>
                  console.log("onshapeData", JSON.stringify(onshapeData))
                }
              >
                onshapeData
              </button>
            </li>
            <li>
              <button
                className="btn"
                onClick={() => console.log("userData", userData)}
              >
                userData
              </button>
            </li>
          </>
        )}
      </ul>
    </div>
  );
}
