import {
  //cubeFaces,
  //startOrigins,
  //holeFeatureTypes,
  metricTaps,
  inchTaps,
  metricClearanceSizes,
  metricCBoreSizes,
  metricCSinkSizes,
  inchClearanceSizes,
  fits,
  //featurePatterns,
} from "./constantsFeatureHoles";

// Returns values in M
export function getHoleData(featureNode) {
  let outerDiameter = 0;
  let innerDiameter = 0;
  let pitch = null;
  let pitchMeter = 0;
  let plugChamferTeeth = null;
  let bottomChamferTeeth = null;
  let maxDepthMultiplier = null;

  if (featureNode.standard === "METRIC") {
    switch (featureNode.holeFeatureType) {
      case "TAP":
        const tapSize = metricTaps.filter(
          (x) => x.value === featureNode.metricTap
        );
        // console.log("tapSize", tapSize);
        outerDiameter = tapSize[0].outerPreviewSize;
        innerDiameter = tapSize[0].innerPreviewSize;
        pitch = tapSize[0].pitch;
        pitchMeter = pitch / 1000;
        plugChamferTeeth = tapSize[0].plugChamferTeeth;
        bottomChamferTeeth = tapSize[0].bottomChamferTeeth;
        maxDepthMultiplier = tapSize[0].maxDepthMultiplier;
        break;

      case "CLEARANCE_HOLE":
        const clearanceSize = metricClearanceSizes.filter(
          (x) => x.value === featureNode.metricClearanceSize
        );
        outerDiameter = 0;
        innerDiameter = clearanceSize[0][featureNode.fit];
        break;

      case "CBORE":
        const cboreSize = metricCBoreSizes.filter(
          (x) => x.value === featureNode.metricClearanceSize
        );
        outerDiameter = cboreSize[0].outerPreviewSize;
        innerDiameter = cboreSize[0].innerPreviewSize;
        break;

      case "CSINK":
        const csinkSize = metricCSinkSizes.filter(
          (x) => x.value === featureNode.metricClearanceSize
        );
        outerDiameter = csinkSize[0].outerPreviewSize;
        innerDiameter = csinkSize[0].innerPreviewSize;
        break;

      case "DRILL":
        break;

      default:
        break;
    }
  } else if (featureNode.standard === "INCH") {
    switch (featureNode.holeFeatureType) {
      case "TAP":
        {
          const index = inchTaps.findIndex(
            (tap) => tap.value === featureNode.inchTap
          );
          // console.log(index);
          const innerValueUnit = inchTaps[index]["tapDrillDiameter"];
          const outerValueUnit = inchTaps[index]["majorDiameter"];
          // console.log(innerValueUnit);
          // console.log(outerValueUnit);
          const innerMetricValue =
            innerValueUnit.replace(/(-?\d+.\d+)(\s\w+)/, `$1`) * 0.0254;
          // console.log(innerMetricValue);
          const outerMetricValue =
            outerValueUnit.replace(/(-?\d+.\d+)(\s\w+)/, `$1`) * 0.0254;
          // console.log(outerMetricValue);
          outerDiameter = outerMetricValue;
          innerDiameter = innerMetricValue;
          pitch = inchTaps[index]["pitch"];
          pitchMeter = ((1 / Number(pitch.match(/\d+/))) * 25.4) / 1000;
          plugChamferTeeth = inchTaps[index]["plugChamferTeeth"];
          bottomChamferTeeth = inchTaps[index]["bottomChamferTeeth"];
          maxDepthMultiplier = inchTaps[index]["maxDepthMultiplier"];
        }
        break;

      case "CLEARANCE_HOLE":
        {
          const index = fits.findIndex((fit) => fit.value === featureNode.fit);
          // console.log(index);
          let fitLabel = fits[index].label;
          // console.log(fitLabel);
          const clearanceSize = inchClearanceSizes.filter(
            (x) => x.value === featureNode.inchClearanceSize
          );
          const innerValueUnit =
            clearanceSize[0]["entries"][fitLabel]["holeDiameter"];
          // console.log(innerValueUnit);
          const innerMetricValue =
            innerValueUnit.replace(/(-?\d+.\d+)(\s\w+)/, `$1`) * 0.0254;
          // console.log(innerMetricValue);
          outerDiameter = 0;
          innerDiameter = innerMetricValue;
        }
        break;

      case "CBORE":
        {
          const index = fits.findIndex((fit) => fit.value === featureNode.fit);
          // console.log(index);
          let fitLabel = fits[index].label;
          // console.log(fitLabel);
          const clearanceSize = inchClearanceSizes.filter(
            (x) => x.value === featureNode.inchClearanceSize
          );
          const innerValueUnit =
            clearanceSize[0]["entries"][fitLabel]["holeDiameter"];
          const outerValueUnit =
            clearanceSize[0]["entries"][fitLabel]["cBoreDiameter"];
          // console.log(innerValueUnit);
          // console.log(outerValueUnit);
          const innerMetricValue =
            innerValueUnit.replace(/(-?\d+.\d+)(\s\w+)/, `$1`) * 0.0254;
          // console.log(innerMetricValue);
          const outerMetricValue =
            outerValueUnit.replace(/(-?\d+.\d+)(\s\w+)/, `$1`) * 0.0254;
          // console.log(outerMetricValue);
          outerDiameter = outerMetricValue;
          innerDiameter = innerMetricValue;
        }
        break;

      case "CSINK":
        {
          const index = fits.findIndex((fit) => fit.value === featureNode.fit);
          // console.log(index);
          let fitLabel = fits[index].label;
          // console.log(fitLabel);
          const clearanceSize = inchClearanceSizes.filter(
            (x) => x.value === featureNode.inchClearanceSize
          );
          const innerValueUnit =
            clearanceSize[0]["entries"][fitLabel]["holeDiameter"];
          const outerValueUnit =
            clearanceSize[0]["entries"][fitLabel]["cSinkDiameter"];
          // console.log(innerValueUnit);
          // console.log(outerValueUnit);
          const innerMetricValue =
            innerValueUnit.replace(/(-?\d+.\d+)(\s\w+)/, `$1`) * 0.0254;
          // console.log(innerMetricValue);
          const outerMetricValue =
            outerValueUnit.replace(/(-?\d+.\d+)(\s\w+)/, `$1`) * 0.0254;
          // console.log(outerMetricValue);
          outerDiameter = outerMetricValue;
          innerDiameter = innerMetricValue;
        }
        break;

      case "DRILL":
        break;

      default:
        break;
    }
  }

  return {
    outerDiameter: outerDiameter,
    innerDiameter: innerDiameter,
    pitch: pitch,
    pitchMeter: pitchMeter,
    plugChamferTeeth: plugChamferTeeth,
    bottomChamferTeeth: bottomChamferTeeth,
    maxDepthMultiplier: maxDepthMultiplier,
  };
}

export function convertMeterToLengthUnit(meterValue) {
  meterValue = Number(meterValue);
  const mmValue = meterValue * 1000;
  const inchValue = mmValue / 25.4;
  return {
    meter: meterValue,
    mm: mmValue.toFixed(2),
    inch: inchValue.toFixed(3),
  };
}

export function determineHoleZ(cubeFace, userData) {
  let holeZ = 0;
  switch (cubeFace) {
    case "TOP_FACE":
      holeZ = userData.plate.features[0].zHeight;
      break;
    case "FRONT_FACE":
      holeZ = userData.plate.features[0].yWidth;
      break;
    case "BOTTOM_FACE":
      holeZ = userData.plate.features[0].zHeight;
      break;
    case "BACK_FACE":
      holeZ = userData.plate.features[0].yWidth;
      break;
    case "LEFT_FACE":
      holeZ = userData.plate.features[0].xLength;
      break;
    case "RIGHT_FACE":
      holeZ = userData.plate.features[0].xLength;
      break;
    default:
      break;
  }
  return holeZ;
}

// http://www.tapmatic.com/tapping_questions_drill_depth_clearance.ydev
export function getTapBottomClearanceMeter(chamferTeeth, pitchMeter) {
  return Number(chamferTeeth * pitchMeter + pitchMeter + 0.001);
}

export function getTipLengthMeter(drillDiameterMeter) {
  const radiusMeter = drillDiameterMeter / 2;
  return Number(radiusMeter * Math.tan((31 * Math.PI) / 180));
}
