import React, { useEffect, useContext } from "react";
import { PlateDataContext } from "../PlateForm/PlateDataContext";

export default function StandardBox(props) {
  const {
    name,
    keyName,
    featureIndex,
    setNeedsUpdate,
    needsUpdate,
    featureNode,
    auxChange,
  } = props;
  const { userData, setUserData, onshapeData } = useContext(PlateDataContext);

  useEffect(() => {
    // console.log(keyName, "loaded");
    return () => {
      setNeedsUpdate(false); // no longer testing this
      // console.log(keyName, "unloaded");
    };
  }, [setNeedsUpdate]);

  // compares featureData with onshapeData
  useEffect(() => {
    if (featureNode == null) return;

    // console.log("featureNode",  featureNode);

    if (typeof onshapeData.plate.features[featureIndex] == "undefined") {
      setNeedsUpdate(true);
      return;
    }

    // console.log(
    //   "onshapeData",
    //   keyName,
    //   onshapeData.plate.features[featureIndex][keyName]
    // );

    if (featureNode === onshapeData.plate.features[featureIndex][keyName]) {
      setNeedsUpdate(false);
    } else {
      setNeedsUpdate(true);
    }
  }, [
    userData,
    featureIndex,
    featureNode,
    keyName,
    onshapeData.plate.features,
    setNeedsUpdate,
  ]);

  const radioOnChange = (event) => {
    // console.log("featureNode", featureNode);
    let { value } = event.target;
    const tempData = { ...userData };
    tempData.plate.features[featureIndex][keyName] = value;
    if (!auxChange) {
      setUserData({
        ...userData,
        ...tempData,
      });
    } else {
      auxChange(tempData);
    }
  };

  return (
    <form className="form-group">
      <div className="label">{name}: </div>
      <div className={`input options ${needsUpdate && "input_needsUpdate"}`}>
        <label className="radio radio1st">
          <input
            type="radio"
            name="standard"
            value="METRIC"
            checked={featureNode === "METRIC"}
            onChange={radioOnChange}
          />
          Metric
        </label>
        <label className="radio">
          <input
            type="radio"
            name="standard"
            value="INCH"
            checked={featureNode === "INCH"}
            onChange={radioOnChange}
          />
          Inch
        </label>
        {/* <button
          className="btn control_button"
          onClick={() => {
            console.log(featureNode);
          }}
        >
          featureNode
        </button> */}
      </div>
    </form>
  );
}
