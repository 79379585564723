import React, { useEffect, useCallback } from "react";
import Layout from "../core/Layout";
import axios from "axios";
import { isAuth, getCookie, signout } from "../auth/helpers";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const PurchaseSuccess = () => {
  const history = useHistory();

  const token = getCookie("token");

  const loadProfile = useCallback(() => {
    console.log("loadProfile called");
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API}/user/${isAuth()._id}`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        console.log("PRIVATE PROFILE UPDATE", response);
      })
      .catch((error) => {
        console.log("PRIVATE PROFILE UPDATE ERROR", error);
        if (error.response.status === 401) {
          signout(() => {
            history.push("/");
          });
        }
      });
  }, [history, token]);

  useEffect(() => {
    loadProfile();
  }, [loadProfile]);

  useEffect(() => {
    localStorage.removeItem("quoteLineItems");
  }, []);

  return (
    <Layout imageOn={true}>
      <div className="signin">
        <div className="info info-signin">
          <ToastContainer />
          <h1 style={{ margin: "20px" }}>Your purchase was successful!</h1>
          <p style={{ margin: "10px" }}>
            You will receive an email confirmation soon. We will start on your
            parts shortly.
          </p>
          <b style={{ margin: "20px" }}>Thanks for the business!</b>
          <div />
          <button
            style={{ margin: "20px" }}
            onClick={() => {
              history.push("/plate");
            }}
            className="btn"
          >
            Return to program
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default PurchaseSuccess;
