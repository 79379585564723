import React from "react";
//import "@google/model-viewer"; // this causes errors on server
// ALTERNATIVE: react-three-fiber
// ALTERNATIVE: https://www.npmjs.com/package/react-360-cli
// ALTERNATIVE: https://github.com/dwqdaiwenqi/react-3d-viewer
//https://github.com/react-spring/react-three-fiber#ecosystem
// import "@google/model-viewer/dist/model-viewer-legacy"; //https://github.com/google/model-viewer/pull/1437
import "@google/model-viewer/dist/model-viewer";

export default function ModelViewer(gltf3D) {
  // if (typeof gltf3D === "string") {
  //   console.log(gltf3D);
  // } else {
  //   console.log(JSON.stringify(gltf3D));
  //   console.log(model);
  // }

  //TODO: This can go away. Real issue was AWS eventual consistency
  // useEffect(() => {
  //   /console.log("hello modelviewer");
  //   console.log("gltf3D", gltf3D);
  //   return () => {
  //     gltf3D = null;
  //     console.log("goodbye modelviewer");
  //     console.log("gltf3D", gltf3D);
  //   };
  // }, []);

  return (
    <model-viewer
      class="viewport"
      src={gltf3D.gltf3D.gltf3D}
      exposure=".25"
      camera-controls
      interaction-prompt="when-focused"
      max-camera-orbit="Infinity 180deg Infinity"
      min-camera-orbit="-Infinity 0deg 0"
      alt="part"
    />
  );
}
