import React, { useContext } from "react";
import { PlateDataContext } from "../PlateForm/PlateDataContext";

export default function PlateCanvasControls({ currentView, enumView }) {
  const { viewsDisabled, setCurrentView } = useContext(PlateDataContext);

  const isDisabled = (viewName) => {
    if (viewsDisabled && currentView !== viewName) {
      return true;
    } else return false;
  };

  return (
    <div className="ortho-view-container">
      <ul className="panel">
        <li>
          <button
            className={`btn ${
              currentView === enumView.LEFT ? "btn-activated" : ""
            }`}
            onClick={() => setCurrentView(enumView.LEFT)}
            disabled={isDisabled(enumView.LEFT)}
          >
            {`Left`}
          </button>
        </li>
        <li>
          <div>
            <ul className="panel nav-list panel-btn panel-btn-vert">
              <li>
                <button
                  className={`btn ${
                    currentView === enumView.BACK ? "btn-activated" : ""
                  }`}
                  onClick={() => setCurrentView(enumView.BACK)}
                  disabled={isDisabled(enumView.BACK)}
                >
                  Back
                </button>
              </li>
              <li>
                <button
                  className={`btn ${
                    currentView === enumView.TOP ? "btn-activated" : ""
                  }`}
                  onClick={() => setCurrentView(enumView.TOP)}
                  disabled={isDisabled(enumView.TOP)}
                >
                  Top
                </button>
              </li>
              <li>
                <button
                  className={`btn ${
                    currentView === enumView.FRONT ? "btn-activated" : ""
                  }`}
                  onClick={() => setCurrentView(enumView.FRONT)}
                  disabled={isDisabled(enumView.FRONT)}
                >
                  Front
                </button>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <button
            className={`btn ${
              currentView === enumView.RIGHT ? "btn-activated" : ""
            }`}
            onClick={() => setCurrentView(enumView.RIGHT)}
            disabled={isDisabled(enumView.RIGHT)}
          >
            Right
          </button>
        </li>
        <li>
          <button
            className={`btn ${
              currentView === enumView.BOTTOM ? "btn-activated" : ""
            }`}
            onClick={() => setCurrentView(enumView.BOTTOM)}
            disabled={isDisabled(enumView.BOTTOM)}
          >
            Bottom
          </button>
        </li>
      </ul>
    </div>
  );
}
