import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Layout from "./Layout";
import axios from "axios";
import { isAuth, getCookie, signout, updateUser } from "../auth/helpers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const Settings = ({ history }) => {
  const [values, setValues] = useState({
    role: "",
    showDevData: false,
    name: "",
    email: "",
    password: "",
    buttonText: "Submit",
  });

  const token = getCookie("token");

  const loadProfile = useCallback(() => {
    console.log("loadProfile called");
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API}/user/${isAuth()._id}`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        console.log("PRIVATE PROFILE UPDATE", response);
        const { role, name, email, showDevData } = response.data;
        const tempVal = {
          role: role,
          showDevData: showDevData,
          name: name,
          email: email,
          password: "",
          buttonText: "Submit",
        };
        setValues({ ...tempVal });
      })
      .catch((error) => {
        console.log("PRIVATE PROFILE UPDATE ERROR", error);
        if (error.response.status === 401) {
          signout(() => {
            history.push("/");
          });
        }
      });
  }, [history, token]);

  useEffect(() => {
    loadProfile();
  }, [loadProfile]);

  const { role, name, email, password, buttonText } = values;

  const handleChange = (name) => (event) => {
    //console.log("event", event);
    setValues({ ...values, [name]: event.target.value });
  };

  const clickSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, buttonText: "Submitting" });
    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API}/user/update`,
      headers: { Authorization: `Bearer ${token}` },
      data: { name, password },
    })
      .then((response) => {
        console.log("PRIVATE PROFILE UPDATE SUCCESS", response);
        updateUser(response, () => {
          setValues({ ...values, buttonText: "Submitted" });
          toast.success("Profile updated successfully");
        });
      })
      .catch((error) => {
        console.log("PRIVATE PROFILE UPDATE ERROR", error.response.data.error);
        setValues({ ...values, buttonText: "Submit" });
        toast.error(error.response.data.error);
      });
  };

  const updateForm = () => (
    <form className="signin">
      <div className="form-group form-group-main">
        <i
          className="far fa-user-circle login-icon"
          style={{
            color: "hsl(194, 55%, 30%)",
          }}
        ></i>
        <input
          defaultValue={role}
          type="text"
          placeholder="Role"
          className="input input-main"
          disabled
        />
      </div>
      <div className="form-group form-group-main">
        <i
          className="far fa-user login-icon"
          style={{
            color: "hsl(194, 55%, 30%)",
          }}
        ></i>
        <input
          onChange={handleChange("name")}
          value={name}
          type="text"
          placeholder="Name"
          className="input input-main"
        />
      </div>
      <div className="form-group form-group-main">
        <i
          className="fas fa-envelope-square login-icon"
          style={{
            color: "hsl(194, 55%, 30%)",
          }}
        ></i>
        <input
          onChange={handleChange("email")}
          value={email}
          type="email"
          placeholder="Email"
          className="input input-main"
        />
      </div>
      <div className="form-group form-group-main">
        <i
          className="fas fa-lock login-icon"
          style={{
            color: "hsl(194, 55%, 30%)",
          }}
        ></i>
        <input
          onChange={handleChange("password")}
          value={password}
          type="password"
          placeholder="Password"
          className="input input-main"
        />
      </div>
      <button onClick={clickSubmit} className="btn btn-login">
        <i className="fas fa-sign-in-alt login-icon"></i>
        {buttonText}
      </button>
    </form>
  );

  return (
    <Layout imageOn={true}>
      <div className="signin">
        <div className="info info-signin">
          <ToastContainer />
          <h1>Settings</h1>
          <button
            onClick={() => {
              signout(() => {
                history.push("/");
              });
            }}
            className="btn btn-login"
          >
            Signout
          </button>
          <div className="line-separator"> Profile Update </div>
          {/* <span>Profile Update</span> */}
          {updateForm()}
          <div className="center-vert">
            <Link
              to="privacy"
              className="cta"
              style={{
                fontSize: "10px",
                textDecorationLine: "underline",
              }}
            >
              Privacy Policy
            </Link>
            <Link
              to="terms"
              className="cta"
              style={{
                fontSize: "10px",
                textDecorationLine: "underline",
              }}
            >
              Terms of Service
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Settings;
