import React, { createContext, useReducer, useEffect, useState } from "react";
import { quoteReducer } from "./quoteReducer";
// import QUOTE_DATA from "./QUOTE_DATA.json";

export const QuoteContext = createContext();

const QuoteContextProvider = (props) => {
  const [isQuoteOpen, setIsQuoteOpen] = useState(false);
  const [quoteLineItems, dispatch] = useReducer(quoteReducer, [], () => {
    const localData = localStorage.getItem("quoteLineItems");
    return localData ? JSON.parse(localData) : [];
  });
  // const [quoteLineItems, dispatch] = useReducer(quoteReducer, [], () => {
  //   return [...QUOTE_DATA];
  // });
  useEffect(() => {
    localStorage.setItem("quoteLineItems", JSON.stringify(quoteLineItems));
  }, [quoteLineItems]);

  return (
    <QuoteContext.Provider
      value={{ quoteLineItems, dispatch, isQuoteOpen, setIsQuoteOpen }}
    >
      {props.children}
    </QuoteContext.Provider>
  );
};

export default QuoteContextProvider;
