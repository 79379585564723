import React, { useState, useEffect, useContext } from "react";
import { PlateDataContext } from "../PlateForm/PlateDataContext";
import LengthBox from "./LengthBox";
import TapDepth from "./TapDepth";
import {
  getHoleData,
  convertMeterToLengthUnit,
  getTipLengthMeter,
  getTapBottomClearanceMeter,
  determineHoleZ,
} from "../Features/FeatureHole/helpers";
import InfoUnit from "./InfoUnit";
import CheckBox from "./CheckBox";

export default function DrillDepth(props) {
  const {
    featureIndex,
    needsUpdateFlag,
    drillDepthNeedsUpdate,
    setDrillDepthNeedsUpdate,
    drillDepthError,
    setDrillDepthError,
    drillThroughNeedsUpdate,
    setDrillThroughNeedsUpdate,
    tapDepthNeedsUpdate = false,
    setTapDepthNeedsUpdate,
    tapDepthError = false,
    setTapDepthError,
    tapThroughNeedsUpdate = false,
    setTapThroughNeedsUpdate,
    tapBothEndsNeedsUpdate = false,
    setTapBothEndsNeedsUpdate,
    featureNode,
    hidden,
  } = props;
  const { userData, setUserData } = useContext(PlateDataContext);
  const [minDrillDepth, setMinDrillDepth] = useState({
    meter: "0",
    mm: "0",
    inch: "0",
  });
  const [maxDrillDepth, setMaxDrillDepth] = useState({
    meter: "0",
    mm: "0",
    inch: "0",
  });
  const [tipDepth, setTipDepth] = useState({
    meter: "0",
    mm: "0",
    inch: "0",
  });
  const [maxTapDepth, setMaxTapDepth] = useState({
    meter: "0",
    mm: "0",
    inch: "0",
  });
  const [minTapDepth, setMinTapDepth] = useState({
    meter: "0",
    mm: "0",
    inch: "0",
  });
  const [disableDrillDepth, setDisableDrillDepth] = useState(false);

  useEffect(() => {
    // console.log("update");
    // https://www.designnews.com/how-long-jobber-length-drill-bit
    // ANSI/ASME B94.11M-1993
    // This should be based on the drill and taken from a table; jobberTable or screw_machineTable
    // Going to use 10X for now
    const holeData = getHoleData(featureNode);
    const {
      innerDiameter,
      outerDiameter,
      pitchMeter,
      plugChamferTeeth,
      bottomChamferTeeth,
      maxDepthMultiplier,
    } = holeData;
    // TODO: Make 'maxDrillDepthMultiplier'. This can be part of tap data or drill data.
    // Right now there is no drill data, so nada.
    const maxDrillDepth_Meter = innerDiameter * 10; // this will ALWAYS be inner diameter
    setMaxDrillDepth(convertMeterToLengthUnit(maxDrillDepth_Meter));
    const tipDepth_Meter =
      Number(featureNode.drillDepth.meter) + getTipLengthMeter(innerDiameter);
    setTipDepth(convertMeterToLengthUnit(tipDepth_Meter));
    if (featureNode.holeFeatureType === "TAP") {
      const maxTapDepth_Meter = outerDiameter * maxDepthMultiplier;
      setMaxTapDepth(convertMeterToLengthUnit(maxTapDepth_Meter));
      const minTapDepth_Meter = pitchMeter * 3.5; //.5 is for the entry chamfer
      setMinTapDepth(convertMeterToLengthUnit(minTapDepth_Meter));
      // http://www.tapmatic.com/tapping_questions_drill_depth_clearance.ydev
      let chamferTeeth = 0;
      if (featureNode.tapThrough) {
        chamferTeeth = plugChamferTeeth;
      } else {
        chamferTeeth = bottomChamferTeeth;
      }
      const minDrillDepth_Meter =
        Number(featureNode.tapDepth.meter) +
        Number(getTapBottomClearanceMeter(chamferTeeth, pitchMeter));
      if (featureNode.drillThrough) {
        setMinDrillDepth({
          meter: "0",
          mm: "0",
          inch: "0",
        });
      } else {
        setMinDrillDepth(convertMeterToLengthUnit(minDrillDepth_Meter));
      }
    }
  }, [
    featureNode,
    needsUpdateFlag,
    featureNode.drillDepth,
    featureNode.tapDepth,
    featureNode.standard,
    featureNode.holeFeatureType,
    featureNode.metricTap,
    featureNode.inchTap,
    featureNode.metricClearanceSize,
    featureNode.inchClearanceSize,
    featureNode.fit,
  ]);

  useEffect(() => {
    if (featureNode.drillThrough) {
      setDisableDrillDepth(true);
      const holeZ = determineHoleZ(featureNode.cubeFace, userData);
      if (featureNode.drillDepth !== holeZ) {
        featureNode.drillDepth = holeZ;
      }
    } else setDisableDrillDepth(false);
  }, [
    featureNode.cubeFace,
    featureNode.drillThrough,
    featureNode.drillDepth,
    userData,
  ]);

  const drillThroughReset = (tempData) => {
    const holeZ = determineHoleZ(featureNode.cubeFace, userData);
    if (tempData.plate.features[featureIndex].drillThrough) {
      tempData.plate.features[featureIndex].drillDepth = holeZ;
      setUserData((currentData) => {
        return {
          ...currentData,
          ...tempData,
        };
      });
    } else {
      if (featureNode.holeFeatureType === "TAP") {
        if (featureNode.tapThrough) {
          tempData.plate.features[featureIndex].drillDepth = holeZ;
        } else {
          const holeData = getHoleData(featureNode);
          const { pitchMeter, bottomChamferTeeth } = holeData;
          let drillClearance = getTapBottomClearanceMeter(
            bottomChamferTeeth,
            pitchMeter
          );
          tempData.plate.features[featureIndex].drillDepth =
            convertMeterToLengthUnit(
              Number(featureNode.tapDepth.meter) + Number(drillClearance)
            );
        }
      }
      setUserData((currentData) => {
        return {
          ...currentData,
          ...tempData,
        };
      });
    }
  };

  return (
    <>
      <div
        className={`input_boxes input_boxes_close ${hidden ? "hidden" : ""}`}
      >
        <LengthBox
          name="Drill Depth"
          keyName="drillDepth"
          featureIndex={featureIndex}
          needsUpdate={drillDepthNeedsUpdate}
          setNeedsUpdate={setDrillDepthNeedsUpdate}
          error={drillDepthError}
          setError={setDrillDepthError}
          placeholder="drill depth"
          min={minDrillDepth}
          max={maxDrillDepth}
          featureNode={featureNode.drillDepth}
          disabled={disableDrillDepth}
        />
        <InfoUnit name="Tip Depth" value={tipDepth} />
        <CheckBox
          name="Through"
          keyName="drillThrough"
          featureIndex={featureIndex}
          setNeedsUpdate={setDrillThroughNeedsUpdate}
          needsUpdate={drillThroughNeedsUpdate}
          featureNode={featureNode.drillThrough}
          auxChange={drillThroughReset}
        />
      </div>
      <TapDepth
        hidden={featureNode.holeFeatureType === "TAP" ? false : true}
        featureIndex={featureIndex}
        tapDepthNeedsUpdate={tapDepthNeedsUpdate}
        setTapDepthNeedsUpdate={setTapDepthNeedsUpdate}
        tapDepthError={tapDepthError}
        setTapDepthError={setTapDepthError}
        tapThroughNeedsUpdate={tapThroughNeedsUpdate}
        setTapThroughNeedsUpdate={setTapThroughNeedsUpdate}
        tapBothEndsNeedsUpdate={tapBothEndsNeedsUpdate}
        setTapBothEndsNeedsUpdate={setTapBothEndsNeedsUpdate}
        minTapDepth={minTapDepth}
        maxTapDepth={maxTapDepth}
        featureNode={featureNode}
        setDisableDrillDepth={setDisableDrillDepth}
      />
    </>
  );
}
