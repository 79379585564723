import React, { useContext, useState, useEffect } from "react";
import { PlateDataContext } from "../PlateForm/PlateDataContext";
import Units from "../UnitSwitcher/Units";


export default function LengthBox(props) {
  const {
    name,
    keyName,
    featureIndex,
    setNeedsUpdate = ()=>{},
    needsUpdate,
    error,
    setError = ()=>{},
    placeholder,
    max,
    min,
    featureNode,
    hidden,
    disabled,
  } = props;
  const { unit, userData, setUserData, onshapeData } =
    useContext(PlateDataContext);
  const [skipCompare, setSkipCompare] = useState(false);
  const [pasted, setPasted] = useState(false);

  useEffect(() => {
    // console.log(keyName, "loaded");
    return () => {
      setNeedsUpdate(false); // no longer testing this
      // console.log(keyName, "unloaded");
    };
  }, [setNeedsUpdate]);

  // Below checks if userValue is within min and max range
  // ***************************************************************************************
  // current units seems better than comparing meter; this causes edge issues,
  // ie: 25.39mm is 1.000in; one works, one does not. Going to ignore for now.
  // Another way to handles this is to allow more decimal places like OS and SW. This
  // could cause latter confusion or more problems.
  // ***************************************************************************************
  useEffect(() => {
    // console.log("lengthBox error check called");
    // console.log(featureNode)
    if (featureNode == null) return;

    
    // console.log("skipCompare:", skipCompare, "disabled:", disabled);

    if (skipCompare) {
      setError("");
      return;
    }

    if (
      Number(featureNode[unit.keyName]) <= Number(max[unit.keyName]) &&
      Number(featureNode[unit.keyName]) >= Number(min[unit.keyName])
    ) {
      setError("");
      // console.log("error off", userValue.meter);
      // console.log("error off", featureNode[unit.keyName]);
    } else {
      setError(`${min[unit.keyName]} < val > ${max[unit.keyName]}`);
      // console.log("error on", userValue.meter);
      // console.log("error on", featureNode[unit.keyName]);
    }

    if (featureNode[unit.keyName] === "") {
      setError(`${min[unit.keyName]} < val > ${max[unit.keyName]}`);
    }
  }, [featureNode, max, min, skipCompare, unit.keyName, setError, disabled]);

  // compares featureData with onshapeData
  useEffect(() => {
    if (featureNode == null) return;

    // console.log(
    //   "featureNode[unit.keyName]",
    //   keyName,
    //   featureNode[unit.keyName]
    // );

    if (typeof onshapeData.plate.features[featureIndex] == "undefined") {
      setNeedsUpdate(true);
      return;
    }

    // console.log(
    //   "getOnshapeValue",
    //   keyName,
    //   onshapeData.plate.features[featureIndex][keyName][unit.keyName]
    // );

    if (
      Number(featureNode[unit.keyName]) ===
      Number(onshapeData.plate.features[featureIndex][keyName][unit.keyName])
    ) {
      setNeedsUpdate(false);
    } else {
      setNeedsUpdate(true);
    }
  }, [
    featureIndex,
    featureNode,
    keyName,
    onshapeData.plate.features,
    setNeedsUpdate,
    unit.keyName,
  ]);

  const handlePaste = (e) => {
    setPasted(true);
    //console.log("pasted", e.target.userValue.toString());
  };

  const handleChange = (values) => {
    //console.log("values", values);
    let newValue = values.target.value;
    //console.log("newValue", newValue);

    if (pasted && /^[0-9]*[.]?[0-9]*$/.test(newValue)) {
      //if numeric round any last significant digit
      //console.log("pasted handleChange made check");
      if (unit === Units.MM) {
        newValue = Number.parseFloat(newValue).toFixed(2);
      } else {
        newValue = Number.parseFloat(newValue).toFixed(3);
      }
    }

    let modifiedValue = newValue
      .replace(/[^0-9.-]/g, "") // removes any character which is not a number or . or -
      .replace(/(\..*)\./g, "$1") // removes two .
      .replace(/(--*)-/g, "$1"); // removes two -
    //console.log("modifiedValue", modifiedValue);

    if (
      modifiedValue === "" ||
      modifiedValue === "0." ||
      modifiedValue === "."
    ) {
      // Below error gets set on lost focus
      //setError(`${min[unit.keyName]} < val > ${max[unit.keyName]}xxx`);
      setSkipCompare(true);
    } else {
      setSkipCompare(false);
      // round to correct precision
      // limits decimal places for entry
      var pos = modifiedValue.indexOf(".");
      // console.log("pos", pos);
      if (pos !== -1) {
        if (unit === Units.MM) {
          modifiedValue = modifiedValue.substr(0, pos + 3);
        } else {
          modifiedValue = modifiedValue.substr(0, pos + 4);
        }
      }

      setPasted(false);
    }

    const modifiedObject = setLength(modifiedValue);
    // console.log("modifiedObject", modifiedObject);
    // console.log("modifiedValue", modifiedValue);

    const tempData = { ...userData };
    tempData.plate.features[featureIndex][keyName] = modifiedObject;

    setUserData({
      ...userData,
      ...tempData,
    });
  };

  const handleBlur = () => {
    setSkipCompare(false);
  };

  const setLength = (value) => {
    let data = {};
    if (unit === Units.MM) {
      const meter = Number(value) / 1000;
      const inch = Number.parseFloat(meter / 0.0254).toFixed(3);

      data = { ...data, meter: meter.toString(), mm: value, inch: inch };
    } else {
      const meter = (Number(value) * 25.4) / 1000;
      const mm = Number.parseFloat(meter * 1000).toFixed(2);

      data = { ...data, meter: meter.toString(), mm: mm, inch: value };
    }
    return data;
  };

  return (
    <div
      className={`form-group ${hidden ? "hidden" : ""} ${
        disabled ? "disabled" : ""
      }`}
    >
      <div className="label">{name}:</div>
      <p>
        <input
          type="text"
          className={`input ${needsUpdate && "input_needsUpdate"} ${
            error && "input_invalid"
          }`}
          name={name}
          placeholder={`${placeholder}...`}
          value={featureNode[unit.keyName]}
          onChange={handleChange}
          onBlur={handleBlur}
          onPaste={handlePaste}
          autoComplete="off"
          disabled={disabled}
        />
        <small className={`unit ${disabled ? "disabled" : ""}`}>
          {unit.displayUC}
        </small>
        {/* <button
          className="btn control_button"
          onClick={() => {
            console.log(featureNode);
          }}
        >
          featureNode
        </button> */}
      </p>
      {error && <div className="error">{error}</div>}
    </div>
  );
}

// Future Reference: https://mathjs.org/docs/getting_started.html
