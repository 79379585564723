import React, { useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import Feedback from "./Feedback";
import QuoteContextProvider from "../Procurement/QuoteContext";
import Header from "./Header";
import { isAuth } from "../auth/helpers";
import "../App.css";

const Layout = ({ children, match, imageOn = false }) => {
  const user = isAuth();
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  const footer = () => (
    <footer className="footer">
      <div className="footer-help-buttons">
        <li>
          <button
            className="btn"
            onClick={() => console.log("need a way to get started shit")}
          >
            Getting Started
          </button>
        </li>
        {/* <li>
          <button
            className="btn"
            onClick={() => console.log("need a way to help shit")}
          >
            Help
          </button>
        </li> */}
        <li>
          <button className={`btn ${!user ? "hidden" : ""}`} onClick={() => setIsFeedbackOpen(true)}>
            Improvements/Feedback
          </button>
          <Feedback
            className="info"
            open={isFeedbackOpen}
            onClose={() => setIsFeedbackOpen(false)}
          />
        </li>
      </div>
    </footer>
  );

  return (
    <QuoteContextProvider>
      <Fragment>
        <Header currentPath={match.path} />
        {/* {header()} */}
        <main className={`main ${imageOn ? "background-image" : ""}`}>
          {children}
        </main>
        {footer()}
      </Fragment>
    </QuoteContextProvider>
  );
};

export default withRouter(Layout);
