import React, { useState, useEffect, useContext } from "react";
import { PlateDataContext } from "../PlateForm/PlateDataContext";
import { AccordionContext } from "../Accordion/AccordionContext";
import LengthBox from "../InputBoxes/LengthBox";
import CornerTypeBox from "../InputBoxes/CornerTypeBox";

export default function FeatureExternal({ featureIndex }) {
  const {
    userData,
    setPreviewData,
    unit,
    showDevData,
    handleModify,
    handleCancel,
  } = useContext(PlateDataContext);
  const featureData = userData.plate.features[featureIndex];
  const [needsUpdateFlag, setNeedsUpdateFlag] = useState(false);
  const [errorsFlag, setErrorsFlag] = useState(false);
  const [cornerValueMax, setCornerValueMax] = useState({
    meter: "0.00635",
    mm: "6.35",
    inch: "0.25",
  });
  const [xLengthNeedsUpdate, setXLengthNeedsUpdate] = useState(false);
  const [xLengthError, setXLengthError] = useState("");
  const [yWidthNeedsUpdate, setYWidthNeedsUpdate] = useState(false);
  const [yWidthError, setYWidthError] = useState("");
  const [zHeightNeedsUpdate, setZHeightNeedsUpdate] = useState(false);
  const [zHeightError, setZHeightError] = useState("");
  const [cornerTypeNeedsUpdate, setCornerTypeNeedsUpdate] = useState(false);
  const [cornerTypeError, setCornerTypeError] = useState("");
  const [cornerValueNeedsUpdate, setCornerValueNeedsUpdate] = useState(false);
  const [cornerValueError, setCornerValueError] = useState("");
  const { titleValues, activeValues, currentAccordionOpen, highlight } =
    useContext(AccordionContext);
  const [, setTitle] = titleValues;
  const [, setActive] = activeValues;

  // sets the highlight on/off
  useEffect(() => {
    // console.log("highlight:", highlight);
    // console.log(currentAccordionOpen);
    // prevents highlight if the FeatureExternal accordion is open
    // TODO: this may not be the best logic as is gets turned back on below, but it works
    if (currentAccordionOpen === 1) {
      setPreviewData({});
      setNeedsUpdateFlag(false);
      return;
    }
    if (highlight) {
      setPreviewData({ ...featureData });
      setNeedsUpdateFlag(true);
    } else {
      setPreviewData({});
      setNeedsUpdateFlag(false);
    }
  }, [highlight, currentAccordionOpen, featureData, setPreviewData]);

  useEffect(() => {
    setTitle(
      `[${featureIndex}] Plate  ${featureData.xLength[unit.keyName]} L X  ${
        featureData.yWidth[unit.keyName]
      } W X  ${featureData.zHeight[unit.keyName]} H`
    );
  }, [userData, featureData, setTitle, featureIndex, unit]);

  // Below sets corner sides
  useEffect(() => {
    // console.log("useEffect FeatureExternal");
    /* Corner depends on value of Corner Condition and X & Y values */

    let longestSide;
    let shortestSide;

    //  cornerMax = must leave .75" longest edge for grip in vise
    if (featureData.xLength.inch >= featureData.yWidth.inch) {
      longestSide = featureData.xLength.inch;
      shortestSide = featureData.yWidth.inch;
    } else {
      longestSide = featureData.yWidth.inch;
      shortestSide = featureData.xLength.inch;
    }

    let maxCorner = (longestSide - 0.75) / 2;

    // console.log(`test1`);
    // console.log(`longestSide: ${longestSide}`);
    // console.log(`shortestSide: ${shortestSide}`);
    // console.log(`maxCorner: ${maxCorner}`);

    //maxCorner must never exceed 1.5" due to chatter
    if (maxCorner > 1.5) {
      maxCorner = 1.5;
    }

    // console.log(`test2`);
    // console.log(`longestSide: ${longestSide}`);
    // console.log(`shortestSide: ${shortestSide}`);
    // console.log(`maxCorner: ${maxCorner}`);

    //maxCorner can never be more than 1/2 smallest side
    if (maxCorner > shortestSide / 2) {
      maxCorner = shortestSide / 2;
    }

    // console.log(`test3`);
    // console.log(`longestSide: ${longestSide}`);
    // console.log(`shortestSide: ${shortestSide}`);
    //console.log(`maxCorner: ${maxCorner}`);

    setCornerValueMax({
      meter: maxCorner * 0.0254,
      mm: maxCorner * 25.4,
      inch: maxCorner,
    });

    if (
      xLengthNeedsUpdate ||
      yWidthNeedsUpdate ||
      zHeightNeedsUpdate ||
      cornerTypeNeedsUpdate ||
      cornerValueNeedsUpdate
    ) {
      // console.log("reDraw Needed");
      // console.log(featureData);
      setPreviewData({ ...featureData });
      setNeedsUpdateFlag(true);
    } else {
      setPreviewData({});
      // console.log("NO reDraw Needed");
      setNeedsUpdateFlag(false);
    }
  }, [
    userData,
    cornerTypeNeedsUpdate,
    cornerValueNeedsUpdate,
    featureData,
    setPreviewData,
    xLengthNeedsUpdate,
    yWidthNeedsUpdate,
    zHeightNeedsUpdate,
  ]);

  // Below checks InputBoxes for errors
  useEffect(() => {
    if (
      xLengthError ||
      yWidthError ||
      zHeightError ||
      cornerTypeError ||
      cornerValueError
    ) {
      setErrorsFlag(true);
    } else {
      setErrorsFlag(false);
    }
  }, [
    xLengthError,
    yWidthError,
    zHeightError,
    cornerTypeError,
    cornerValueError,
  ]);

  return (
    <div>
      <div className="input_boxes">
        <LengthBox
          name={"X-Length"}
          keyName="xLength"
          featureIndex={featureIndex}
          needsUpdate={xLengthNeedsUpdate}
          setNeedsUpdate={setXLengthNeedsUpdate}
          error={xLengthError}
          setError={setXLengthError}
          placeholder="xLength"
          max={{ meter: "0.149225", mm: "149.23", inch: "6" }}
          min={{ meter: "0.0254", mm: "25.4", inch: "1" }}
          featureNode={featureData.xLength}
        />
        <LengthBox
          name={"Y-Width"}
          keyName="yWidth"
          featureIndex={featureIndex}
          needsUpdate={yWidthNeedsUpdate}
          setNeedsUpdate={setYWidthNeedsUpdate}
          error={yWidthError}
          setError={setYWidthError}
          placeholder="yWidth"
          max={featureData.xLength}
          min={{ meter: "0.0254", mm: "25.4", inch: "1" }}
          featureNode={featureData.yWidth}
        />
        <LengthBox
          name={"Z-Height"}
          keyName="zHeight"
          featureIndex={featureIndex}
          needsUpdate={zHeightNeedsUpdate}
          setNeedsUpdate={setZHeightNeedsUpdate}
          error={zHeightError}
          setError={setZHeightError}
          placeholder="zHeight"
          max={{ meter: "0.0381", mm: "38.1", inch: "1.5" }}
          min={{ meter: "0.00635", mm: "6.35", inch: "0.25" }}
          featureNode={featureData.zHeight}
        />
        <CornerTypeBox
          name={"Corner Type"}
          keyName="cornerType"
          featureIndex={featureIndex}
          needsUpdate={cornerTypeNeedsUpdate}
          setNeedsUpdate={setCornerTypeNeedsUpdate}
          error={cornerTypeError}
          setError={setCornerTypeError}
          featureNode={featureData}
        />
        {/* Corner depends on value of Corner Condition and X & Y values */}
        {/* MIN = 0 = cornerType = NONE */}
        {/* MAX = must leave .75" longest edge; must be less than 2" */}
        {/* punting and leaving it at .5" for now */}

        <LengthBox
          name={"Corner Value"}
          keyName="cornerValue"
          featureIndex={featureIndex}
          needsUpdate={cornerValueNeedsUpdate}
          setNeedsUpdate={setCornerValueNeedsUpdate}
          error={cornerValueError}
          setError={setCornerValueError}
          placeholder="Corner"
          max={cornerValueMax}
          min={{ meter: "0", mm: "0", inch: "0" }}
          featureNode={featureData.cornerValue}
          hidden={featureData.cornerType !== "NONE" ? false : true}
        />
      </div>
      <div className="input_boxes control_buttons">
        <button
          className="btn control_button"
          onClick={() => handleModify(setActive, featureIndex)}
          disabled={!(needsUpdateFlag && !errorsFlag)}
        >
          Modify
        </button>
        <button
          className="btn control_button"
          onClick={() => handleCancel(setActive)}
        >
          Cancel
        </button>
      </div>
      {showDevData && (
        <button
          className="btn"
          onClick={() => console.log("featureData", featureData)}
        >
          featureData
        </button>
      )}
    </div>
  );
}
