import React, { useState } from "react";
import Layout from "./core/Layout";
import { Link, Redirect } from "react-router-dom";
import { authenticate, isAuth } from "./auth/helpers";
import { ToastContainer, toast } from "react-toastify";
import Google from "./auth/Google";
// import Facebook from "./auth/Facebook";
// import LinkedIn from "./auth/LinkedIn";
import { Helmet, HelmetProvider } from "react-helmet-async"; // https://www.digitalocean.com/community/tutorials/react-react-helmet
import axios from "axios";
import "./App.css";
import "react-toastify/dist/ReactToastify.min.css";

const App = ({ history }) => {
  const head = () => (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Custom Machined Plates</title>
        <link rel="canonical" href="https://machinedplates.com" />
      </Helmet>
    </HelmetProvider>
  );

  const [values, setValues] = useState({
    email: "",
    password: "",
    buttonText: "Login",
  });

  const { email, password, buttonText } = values;

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const informParent = (response) => {
    //console.log("response", response);
    authenticate(response, () => {
      isAuth() && isAuth().role === "admin"
        ? history.push("/admin")
        : history.push("/plate");
    });
  };

  const clickSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, buttonText: "Submitting" });
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API}/signin`,
      data: { email, password },
    })
      .then((response) => {
        console.log("SIGNIN SUCCESS", response);

        // save the response (user, token) localstorage/cookie
        authenticate(response, () => {
          setValues({
            ...values,
            email: "",
            password: "",
            buttonText: "Submitted",
          });
          //toast.success(`Hello ${response.data.user.name}, Welcome back!`);
          isAuth() && isAuth().role === "admin"
            ? history.push("/admin")
            : history.push("/plate");
        });
      })
      .catch((error) => {
        console.log("SIGNIN ERROR", error.response.data);
        setValues({ ...values, buttonText: "Login" });
        toast.error(error.response.data.error);
      });
  };

  const signinForm = () => (
    <form className="signin">
      <div className="form-group form-group-main">
        <i
          className="fas fa-envelope-square login-icon"
          style={{
            color: "hsl(194, 55%, 30%)",
          }}
        ></i>
        <input
          onChange={handleChange("email")}
          value={email}
          type="email"
          placeholder="Email"
          className="input input-main"
        />
      </div>
      <div className="form-group form-group-main">
        <i
          className="fas fa-lock login-icon"
          style={{
            color: "hsl(194, 55%, 30%)",
          }}
        ></i>
        <input
          onChange={handleChange("password")}
          value={password}
          type="password"
          placeholder="Password"
          className="input input-main"
        />
      </div>
      <button onClick={clickSubmit} className="btn btn-login">
        <i className="fas fa-sign-in-alt login-icon"></i>
        {buttonText}
      </button>
    </form>
  );

  return (
    <Layout imageOn={true}>
      {head()}
      <div className="signin">
        <div className="info info-signin">
          {/* <h1 className="p-5"> */}
          <div
            className="branding cce"
            style={{ fontSize: "35px", margin: "20px" }}
          >
            machinedplates.com
          </div>
          <h1 style={{ fontSize: "30px", margin: "20px" }}>
            Build Machined Aluminum Plates Fast And Accurate!
          </h1>
          <p>
            optical mounting plates, motor mount plates, heat sink plates,
            camera mount plates, gripper mount plates, transition plates, etc..
          </p>
        </div>
        <div className="info info-signin">
          <ToastContainer />
          {isAuth() ? <Redirect to="/" /> : null}
          <div className="line-separator"> Signin with </div>
          {/* <Facebook informParent={informParent} /> */}
          {/* <LinkedIn informParent={informParent} /> */}
          <div id="google-btn">
            <Google informParent={informParent} />
          </div>
          <div className="line-separator"> OR </div>
          {signinForm()}
          <div className="center-vert">
            <Link
              to="/auth/password/forgot"
              className="cta"
              style={{
                fontSize: "12px",
                textDecorationLine: "underline",
              }}
            >
              Forgot Password
            </Link>
            <Link
              to="privacy"
              className="cta"
              style={{
                fontSize: "10px",
                textDecorationLine: "underline",
              }}
            >
              Privacy Policy
            </Link>
            <Link
              to="terms"
              className="cta"
              style={{
                fontSize: "10px",
                textDecorationLine: "underline",
              }}
            >
              Terms of Service
            </Link>
          </div>
        </div>
      </div>
      {/* </background-image> */}
    </Layout>
  );
};

export default App;
