import React, { useState } from "react";
import ReactDom from "react-dom";
import TextareaAutosize from "react-autosize-textarea";
import axios from "axios";
import { getCookie, isAuth } from "../auth/helpers";

const MODAL_STYLES = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFF",
  padding: "10px",
  borderRadius: "10px",
  width: "600px",
  //   maxHeight: "50px", //screws it all up
  zIndex: 1001,
};

const SUCCESS_STYLES = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "hsl(161, 20%, 84%)",
  padding: "25px",
  borderRadius: "10px",
  zIndex: 1001,
};

const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,.4)",
  zIndex: 1000,
};

export default function Feedback({ open, onClose }) {
  const token = getCookie("token");
  // console.log("token", token);
  const user = isAuth();
  // console.log("user", user);
  const [value, setValue] = useState("");
  const [replySuccess, setReplySuccess] = useState(false);
  //   const [value, setValue] = useState(new Array(15).join("\nLine."));

  if (!open) return null;

  const clickSubmit = () => {
    const header = {
      headers: { Authorization: `Bearer ${token}` },
    };

    console.log("user", user);

    const body = {
      user_id: user._id,
      email: user.email,
      type: "",
      value: value,
    };
    console.log("body", body);

    axios
      .put(`${process.env.REACT_APP_API}/feedback`, body, header)
      .then((response) => {
        console.log("FEEDBACK SENT SUCCESS", response);
        setValue("");
        setReplySuccess(true);
        setTimeout(function () {
          setReplySuccess(false);
          onClose();
        }, 3000);
      })
      .catch((error) => {
        console.log("FEEDBACK ERROR", error);
      });
  };

  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLES} />

      {!replySuccess ? (
        <div style={MODAL_STYLES}>
          <TextareaAutosize
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className="feedback size input "
            placeholder="Your Feedback..."
          />
          <button onClick={clickSubmit} className="btn btn-login">
            <i className="fas fa-sign-in-alt login-icon"></i>
            "Submit"
          </button>
          <button onClick={onClose} className="btn btn-cancel btn-100-width">
            Cancel/Pause
          </button>
        </div>
      ) : (
        <div style={SUCCESS_STYLES}>
          <h1>Feedback Sent!</h1>
        </div>
      )}
    </>,
    document.getElementById("feedback")
  );
}
