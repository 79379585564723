import React, { useState, useEffect, useContext } from "react";
// import initialData from "../json/PlateData.json";
import FeatureList from "../FeatureList/FeatureList";
//import UnitContext from "../UnitSwitcher/unit-context";
import Units from "../UnitSwitcher/Units";
import UnitSwitcher from "../UnitSwitcher/UnitSwitcher";
import loading from "../img/loading.gif";
import errorImg from "../img/error.png";
import { PlateDataContext } from "../PlateForm/PlateDataContext";
import { getCookie, isAuth } from "../../../auth/helpers";
import axios from "axios";
//import ortho from "./ortho.png";

import ModelViewWrapper from "../Views/ModelViewWrapper";
import PlateCanvasWrapper from "../Views/PlateCanvasWrapper";
import PlateCanvasControls from "../Views/PlateCanvasControls";
import { QuoteContext } from "../../../Procurement/QuoteContext";

const PlateForm = ({ initialData }) => {
  const [onshapeData, setOnshapeData] = useState({ ...initialData });
  const [userData, setUserData] = useState(() => {
    // breaks any pointers and makes a true detached copy
    // Does not work with undefined, NaN, infinity, functions....
    const deepCopyData = JSON.parse(JSON.stringify(onshapeData));
    return { ...onshapeData, ...deepCopyData, ...{ views: {} } };
  });
  const [unit, setUnit] = useState(Units.MM);
  const enumView = {
    LEFT: "LEFT_FACE",
    RIGHT: "RIGHT_FACE",
    TOP: "TOP_FACE",
    FRONT: "FRONT_FACE",
    BOTTOM: "BOTTOM_FACE",
    BACK: "BACK_FACE",
    LOADING: "Loading",
  };
  const [currentView, setCurrentView] = useState(enumView.LOADING); //sets current view for all the views below
  const [viewStatic, setViewStatic] = useState({
    title: "Loading...",
    src: loading,
    alt: "loading image",
    rotation: "",
    x: 0,
    y: 0,
    z: 0,
  });
  const [viewRight, setViewRight] = useState({
    title: "Loading...",
    src: loading,
    alt: "loading image",
    rotation: "",
  });
  const [showView3D, setShowView3D] = useState(false);
  const [viewsDisabled, setViewsDisabled] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [currentEditId, setCurrentEditId] = useState("");
  const token = getCookie("token");
  const [showDevData, setShowDevData] = useState(false);
  const { setIsQuoteOpen, dispatch } = useContext(QuoteContext);
  const [cartDisabled, setCartDisabled] = useState(false);

  useEffect(() => {
    // Below gets data directly from database
    // This data is also available in the localStorage
    // The localStorage, however, requires a logout and login back in
    // This just requires a refresh
    const header = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const id = isAuth()._id;

    // const response = axios
    axios
      .get(`${process.env.REACT_APP_API}/user/${id}`, header)
      .then(function (response) {
        // handle success
        // console.log(response.data.showDevData);
        setShowDevData(response.data.showDevData);
      })
      .catch((error) => {
        // TODO: add error handler
        console.log("Update went horribly wrong");
        console.log(
          "INITIAL DATA REQUEST ERROR",
          error.request.status,
          error.request.statusText
        );
        throw error;
      });
  }, [token]);

  useEffect(() => {
    // breaks any pointers and makes a true detached copy
    // Does not work with undefined, NaN, infinity, functions....
    const deepCopyData = JSON.parse(JSON.stringify(onshapeData));
    setUserData((p) => ({
      ...p,
      ...deepCopyData,
      ...{ views: {} },
      ...{ partNumber: null },
      ...{ parasolid: null },
    }));
  }, [onshapeData]);

  useEffect(() => {
    // console.log("unit", unit.displayUC);
    setUserData((p) => ({ ...p, units: unit.displayUC }));
  }, [unit]);

  useEffect(() => {
    if (onshapeData.partNumber) {
      setCartDisabled(false);
      // console.log("set cartDisable = false");
    } else {
      setCartDisabled(true);
      // console.log("set cartDisable = true");
    }
  }, [onshapeData]);

  // useEffect(() => {
  //   // remove views and properly clone nested arrays

  //   console.log("onshapeData Changed");
  //   let features = [];
  //   const osFeatures = onshapeData["plate"]["features"];
  //   osFeatures.map((feature) => {
  //     // console.log("feature", feature);
  //     features.push({ ...feature });
  //   });

  //   setUserData({
  //     ...onshapeData,
  //     views: {},
  //     plate: { features: features },
  //   });
  // }, [onshapeData]);

  useEffect(() => {
    // console.log("useEffect[0] Called");
    setCurrentView("TOP_FACE");
    setUnit(Units[initialData.units]);
  }, [initialData.units]);

  // useEffect(() => {
  //   // console.log("plateDataChanged", plateDataChanged);
  // }, [plateDataChanged]);

  // const canvasRefPreview = useRef(null);
  // const ctxRefPreview = useRef(null);

  // useEffect(() => {
  //   // console.log("useEffect[2] EventListener Called");

  //   //Instructions (states how to get rid of intervention error. gonna ignore for now)
  //   //https://dev.to/chromiumdev/sure-you-want-to-leavebrowser-beforeunload-event-4eg5
  //   window.addEventListener("beforeunload", function (event) {
  //     // Cancel the event as stated by the standard.
  //     event.preventDefault();
  //     // Chrome requires returnValue to be set.
  //     event.returnValue = "";
  //     // deleteDocument();
  //   });
  //   return () => {
  //     // cleanup;
  //     console.log("cleanup");
  //     // window.removeEventListener("beforeunload", deleteDocument);
  //   };
  // }, []);
  // // }, [deleteDocument]);

  useEffect(() => {
    let xText = "";
    let yText = "";

    if (!onshapeData) return;
    if (!onshapeData.views) return;
    switch (currentView) {
      case "LEFT_FACE":
        xText = `${onshapeData.plate.features[0].zHeight[unit.keyName]} ${
          unit.displayUC
        }`;
        yText = `${onshapeData.plate.features[0].yWidth[unit.keyName]} ${
          unit.displayUC
        }`;

        setViewStatic({
          title: "LEFT",
          src: onshapeData.views.leftView,
          alt: "Left View",
          rotation: 90,
          x: onshapeData.plate.features[0].zHeight.meter,
          y: onshapeData.plate.features[0].yWidth.meter,
          z: onshapeData.plate.features[0].xLength.meter,
          xText: xText,
          yText: yText,
        });
        setShowView3D(true);
        break;

      case "TOP_FACE":
        xText = `${onshapeData.plate.features[0].xLength[unit.keyName]} ${
          unit.displayUC
        }`;
        yText = `${onshapeData.plate.features[0].yWidth[unit.keyName]} ${
          unit.displayUC
        }`;

        setViewStatic({
          title: "TOP",
          src: onshapeData.views.topView,
          alt: "Top View",
          rotation: 0,
          x: onshapeData.plate.features[0].xLength.meter,
          y: onshapeData.plate.features[0].yWidth.meter,
          z: onshapeData.plate.features[0].zHeight.meter,
          xText: xText,
          yText: yText,
        });
        setShowView3D(true);
        break;

      case "RIGHT_FACE":
        xText = `${onshapeData.plate.features[0].zHeight[unit.keyName]} ${
          unit.displayUC
        }`;
        yText = `${onshapeData.plate.features[0].yWidth[unit.keyName]} ${
          unit.displayUC
        }`;

        setViewStatic({
          title: "RIGHT",
          src: onshapeData.views.rightView,
          alt: "Right View",
          rotation: 0,
          x: onshapeData.plate.features[0].zHeight.meter,
          y: onshapeData.plate.features[0].yWidth.meter,
          z: onshapeData.plate.features[0].xLength.meter,
          xText: xText,
          yText: yText,
        });
        setShowView3D(true);
        break;

      case "FRONT_FACE":
        xText = `${onshapeData.plate.features[0].xLength[unit.keyName]} ${
          unit.displayUC
        }`;
        yText = `${onshapeData.plate.features[0].zHeight[unit.keyName]} ${
          unit.displayUC
        }`;

        setViewStatic({
          title: "FRONT",
          src: onshapeData.views.frontView,
          alt: "Front View",
          rotation: 0,
          x: onshapeData.plate.features[0].xLength.meter,
          y: onshapeData.plate.features[0].zHeight.meter,
          z: onshapeData.plate.features[0].yWidth.meter,
          xText: xText,
          yText: yText,
        });
        setShowView3D(true);
        break;

      case "BOTTOM_FACE":
        xText = `${onshapeData.plate.features[0].xLength[unit.keyName]} ${
          unit.displayUC
        }`;
        yText = `${onshapeData.plate.features[0].yWidth[unit.keyName]} ${
          unit.displayUC
        }`;

        setViewStatic({
          title: "BOTTOM",
          src: onshapeData.views.bottomView,
          alt: "Bottom View",
          rotation: 180,
          x: onshapeData.plate.features[0].xLength.meter,
          y: onshapeData.plate.features[0].yWidth.meter,
          z: onshapeData.plate.features[0].zHeight.meter,
          xText: xText,
          yText: yText,
        });
        setShowView3D(true);
        break;

      case "BACK_FACE":
        xText = `${onshapeData.plate.features[0].xLength[unit.keyName]} ${
          unit.displayUC
        }`;
        yText = `${onshapeData.plate.features[0].zHeight[unit.keyName]} ${
          unit.displayUC
        }`;

        setViewStatic({
          title: "BACK",
          src: onshapeData.views.backView,
          alt: "Back View",
          rotation: 180,
          x: onshapeData.plate.features[0].xLength.meter,
          y: onshapeData.plate.features[0].zHeight.meter,
          z: onshapeData.plate.features[0].yWidth.meter,
          xText: xText,
          yText: yText,
        });
        setShowView3D(true);
        break;

      case "Loading":
        setViewStatic({
          title: "Loading...",
          src: loading,
          alt: "loading image",
        });
        setViewRight({
          title: "Loading...",
          src: loading,
          alt: "loading image",
        });
        setShowView3D(false);

        break;

      default:
        setViewStatic({
          title: "Error",
          src: errorImg,
          alt: "Error image",
        });
        setViewRight({
          title: "Error",
          src: errorImg,
          alt: "Error image",
        });
        setShowView3D(false);

        break;
    }
  }, [onshapeData, userData, currentView, unit]);

  // FIXME: Fix initialize data strategy
  // Two ways to initialize data
  // 1. New user. New data is set to 'PlateData_initial.json'.
  // 2. Existing user. Set to currentPlateDataSet from database. This will
  //    ensure they can work on any computer.
  // 3. If changes are made after a download or mfg a new _id is assigned.
  // 4. User gets prompted if they want to save a design. This goes into their WIP list. When they
  //    exit, the last wip is saved. This is used in '#2 initialize data' above.
  // 5. A user can delete any items on their list except order history.

  const handleModify = async (setActive, featureIndex) => {
    const actions = {
      actions: [{ type: "modify", features: [{ featureIndex: featureIndex }] }],
    };
    update(setActive, actions);
  };
  const handleDelete = async (setActive, featureIndex, addFlag) => {
    if (addFlag) {
      console.log("here handleDelete");
      handleCancel(setActive);
      return;
    }

    //****** remove node ******
    const tempFeatures = userData.plate.features;
    // console.log("features before ", JSON.stringify(tempFeatures));
    // console.log("features before ", Object.keys(tempFeatures).length);
    tempFeatures.splice(featureIndex, 1);
    // console.log("features after ", Object.keys(tempFeatures).length);
    //****** Update userData ******
    let plate = { plate: { features: tempFeatures } };
    setUserData({
      ...userData,
      ...plate,
    });

    const actions = {
      actions: [{ type: "delete", features: [{ featureIndex: featureIndex }] }],
    };
    update(setActive, actions);
  };

  const handleAdd = async (setActive, featureIndex) => {
    const actions = {
      actions: [{ type: "add", features: [{ featureIndex: featureIndex }] }],
    };
    update(setActive, actions);
  };
  const handleReorder = async (setActive) => {
    // placeholder
  };

  const handleCancel = (setActive) => {
    setActive(false);
    // breaks any pointers and makes a true detached copy
    // Does not work with undefined, NaN, infinity, functions....
    const deepCopyData = JSON.parse(JSON.stringify(onshapeData));
    setUserData((p) => ({ ...p, ...deepCopyData }));
    setViewsDisabled(false);
  };

  const update = async (setActive, actions) => {
    setActive(false);
    let tempView = currentView;
    setCurrentView("Loading");

    // setOnshapeData(...onshapeData)

    const body = {
      onshapeData: { ...onshapeData },
      actions: actions,
      userData: { ...userData },
    };
    const header = {
      headers: { Authorization: `Bearer ${token}` },
    };

    // console.log("tempData", tempData);
    // console.log("body", body);
    // console.log("header", header);

    const update = await axios
      .post(`${process.env.REACT_APP_API}/update`, body, header)
      .catch((error) => {
        // TODO: add error handler
        console.log("shit went horribly wrong");
        console.log("UPDATE REQUEST ERROR", error);
        throw error;
      });
    // const data = await response.json();
    const data = update;
    // console.log("INITIAL DATA REQUEST", res.status, res.statusText);
    // console.log("UPDATE REQUEST", data);

    // breaks any pointers and makes a true detached copy
    // Does not work with undefined, NaN, infinity, functions....
    const deepCopyData = JSON.parse(JSON.stringify(data.data));
    // { ...onshapeData, ...deepCopyData, ...{ views: {} } };

    setOnshapeData({ ...onshapeData, ...deepCopyData }); //userData gets updated via useEffect
    setCurrentView(tempView);
  };

  const handleDownload = async () => {
    // FIXME:
    // Note: It is assumed that onshapeData and userData equal each other. There should
    //  be a mechanism to disable button if this is not the case.
    // This would also not be run if the data is initial data.

    if (onshapeData.partNumber && onshapeData.parasolid) {
      open(onshapeData.parasolid);
      return;
    }

    const actions = {
      actions: [{ type: "download", formats: [{ format: "parasolid" }] }],
    };

    const body = {
      onshapeData: { ...onshapeData },
      actions: actions,
      userData: { ...userData },
    };
    const header = {
      headers: { Authorization: `Bearer ${token}` },
    };

    // console.log("tempData", tempData);
    console.log("body", body);
    // console.log("header", header);

    const update = await axios
      .post(`${process.env.REACT_APP_API}/update`, body, header)
      .catch((error) => {
        // TODO: add error handler
        console.log("something went horribly wrong");
        console.log("UPDATE REQUEST ERROR", error);
        throw error;
      });
    // const data = await response.json();
    const data = update;
    // console.log("INITIAL DATA REQUEST", res.status, res.statusText);
    // console.log("UPDATE REQUEST", data);

    // breaks any pointers and makes a true detached copy
    // Does not work with undefined, NaN, infinity, functions....
    const deepCopyData = JSON.parse(JSON.stringify(data.data));
    // { ...onshapeData, ...deepCopyData, ...{ views: {} } };

    setOnshapeData({ ...onshapeData, ...deepCopyData }); //userData gets updated via useEffect
    const { parasolid } = data.data;
    console.log("parasolid", parasolid);
    open(parasolid);
  };

  function open(url) {
    const win = window.open(url, "_blank");
    if (win != null) {
      win.focus();
    }
  }

  return (
    <PlateDataContext.Provider
      value={{
        unit,
        setUnit,
        onshapeData,
        currentView,
        setCurrentView,
        viewsDisabled,
        setViewsDisabled,
        enumView,
        userData,
        setUserData,
        previewData,
        setPreviewData,
        currentEditId,
        setCurrentEditId,
        showDevData,
        handleModify,
        handleDelete,
        handleAdd,
        handleReorder,
        handleCancel,
      }}
    >
      <section className="data-view">
        <div className="data-section">
          <li>
            <UnitSwitcher />
          </li>
        </div>
        <FeatureList />
      </section>
      <section className="model-view">
        <h1>{viewStatic.title}</h1>
        {showView3D ? (
          <PlateCanvasWrapper
            view={viewStatic}
            previewData={previewData}
            currentView={currentView}
          />
        ) : (
          <img
            className={`viewport`}
            src={viewStatic.src}
            alt={viewStatic.alt}
          />
        )}
        <PlateCanvasControls
          currentView={currentView}
          enumView={enumView}
          setCurrentView={setCurrentView}
        />
      </section>
      <section className="model-view">
        <h1>3D</h1>
        {showView3D ? (
          <ModelViewWrapper gltf3D={onshapeData.views.gltf3D} />
        ) : (
          <img className={`viewport`} src={viewRight.src} alt={viewRight.alt} />
        )}
        <div className="control-box">
          <div className="instructions">
            <strong>Instructions</strong>
            <ul className="dot-list">
              <li>click and move mouse or arrow keys to rotate </li>
              <li>middle mouse wheel to zoom </li>
            </ul>
          </div>
          {!cartDisabled ? (
            <h1 className="partNumber">
              Part Number: {onshapeData.partNumber}
            </h1>
          ) : (
            <h1 className="partNumber space-holder-text">_</h1>
          )}
          <div className="threeD-buttons">
            <li>
              <button
                className="btn btn-action"
                onClick={() => handleDownload()}
              >
                1. Save/Download
              </button>
            </li>
            <li>
              <button
                className="btn btn-action"
                onClick={() => {
                  dispatch({ type: "ADD_LINE", onshapeData: onshapeData });
                  setIsQuoteOpen(true);
                }}
                disabled={cartDisabled}
              >
                2. Quote/Add to Cart
              </button>
            </li>
            {/* <button
              style={{ width: "160px" }}
              className="btn"
              onClick={() => setCartDisabled(!cartDisabled)}
            >
              cartDisabled {cartDisabled.toString()}
            </button> */}
          </div>
        </div>
      </section>
    </PlateDataContext.Provider>
  );
};

export default PlateForm;
