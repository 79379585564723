import React, { useState, useMemo, useEffect } from "react";
import { useTable } from "react-table";
import { priceEach } from "./quoteReducer";
import axios from "axios";
import { getCookie } from "../../src/auth/helpers";
import X from "./Delete Line Icon.png";
import "./table.css";

export const QuoteTable = ({
  quoteLineItems,
  dispatch,
  shippingCost,
  setShippingCost,
  salesTax,
  setSalesTax,
}) => {
  const [subTotal, setSubTotal] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [taxRate, setTaxRate] = useState(0);
  const token = getCookie("token");

  useEffect(() => {
    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    async function taxRate() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/tax-rate`,
          axiosConfig
        );
        // console.log(response.data.taxRate);
        setTaxRate(response.data.taxRate);
      } catch (error) {
        console.error(error);
      }
    }

    taxRate();
  }, [token]);

  useEffect(() => {
    // map thru quoteLineItems
    // Shipping
    // https://www.usps.com/ship/priority-mail.htm?fbclid=IwAR2IGizty39fkR2TcE0lhu-j41yK1768_pit47tdnEKiyVcSx5xUCVq0Zu8#flatrate
    // This should be based on size and nesting for now just going to use:
    // if 1X $10
    // if 5x $18
    // if 5x > < 10X $25
    let totalQuantity = 0;
    let totalSalesTax = 0;
    let deriveSubTotal = 0;
    quoteLineItems.map((lineItem) => {
      // console.log("lineItem", lineItem);
      totalQuantity = totalQuantity + lineItem.quantity;
      const itemSubTotal = lineItem.cost_each * lineItem.quantity;
      deriveSubTotal = deriveSubTotal + itemSubTotal;
      if (lineItem.taxable) {
        const itemTax = itemSubTotal * taxRate;
        // console.log("itemTax", itemTax);
        totalSalesTax = totalSalesTax + itemTax;
        // console.log("totalSalesTax", totalSalesTax);
      }
      return null;
    });
    setShippingCost(() => {
      if (totalQuantity === 1) return (15).toFixed(2);
      if (totalQuantity > 1 && totalQuantity <= 5) return (20).toFixed(2);
      if (totalQuantity > 5 && totalQuantity <= 10) return (30).toFixed(2);
      if (totalQuantity > 10) return (45).toFixed(2);
    });
    setSalesTax(totalSalesTax.toFixed(2));
    setSubTotal(deriveSubTotal);
  }, [quoteLineItems, taxRate, setSalesTax, setShippingCost]);

  useEffect(() => {
    // console.log("shippingCost", shippingCost );
    const cost = subTotal + Number(shippingCost) + Number(salesTax);
    setTotalCost(cost.toFixed(2));
  }, [subTotal, shippingCost, salesTax, quoteLineItems]);

  // Create an editable cell renderer
  const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    const onChange = (e) => {
      // console.log("onChange");
      setValue(Number(e.target.value.replace(/[^0-9]/, "")));
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
      // console.log("onBlur", index, id, value);
      updateMyData(index, id, value);
    };

    const onKeyUp = (e) => {
      // console.log("keyup");
      //Enter key
      if (e.keyCode === 13) {
        // console.log("keyup Enter", index, id, value);
        if (Number.isInteger(value)) updateMyData(index, id, value);
        // console.log(value);
      }
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <input
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
      />
    );
  };

  const COLUMNS = [
    {
      Header: "Item #",
      id: "row",
      filterable: false,
      Cell: ({ row }) => {
        // console.log("row.index", row.index);
        return <div>{row.index + 1}</div>;
      },
      Footer: (
        <span>
          <button
            onClick={() => {
              console.log("quoteLineItems", JSON.stringify(quoteLineItems));
            }}
          >
            quoteLineItems
          </button>
        </span>
      ),
    },
    {
      Header: "Image",
      accessor: "img",
      Cell: ({ cell: { value } }) => (
        <img src={value} width={60} alt="preview" />
      ),
    },
    {
      Header: "Part Number",
      accessor: "partNumber",
      Cell: ({ cell: { value } }) => (
        <a href={`${process.env.REACT_APP_CLIENT_URL}/${value}`} width={60}>
          {value}
        </a>
      ),
    },
    {
      Header: "Description",
      id: "description",
      accessor: "description",
    },
    {
      Header: "Quantity",
      id: "quantity",
      accessor: "quantity",
      Cell: EditableCell,
    },
    {
      Header: "Cost Each $",
      accessor: "cost_each",

      Footer: (
        <span>
          <div className="table-footer">Shipping</div>
          <div className="table-footer">Sales Tax</div>
          <div className="table-footer total">
            <strong>Total</strong>
          </div>
        </span>
      ),
    },
    {
      Header: "Total Cost $",
      id: "totalCost",
      filterable: false,
      accessor: (row) => {
        // console.log(row.quantity, row.cost_each);
        const lineTotalCost = row.quantity * row.cost_each;
        // console.log("Total Cost", row);
        return lineTotalCost.toFixed(2);
      },
      Footer: (
        <span>
          <div className="table-footer">{shippingCost}</div>
          <div className="table-footer">{salesTax}</div>
          <div className="table-footer total">
            <strong>{totalCost}</strong>
          </div>
        </span>
      ),
    },
    {
      Header: "Taxable",
      id: "taxable",
      accessor: (str) => "taxable",
      Cell: (tableProps) => (
        <span
          style={{
            cursor: "pointer",
          }}
        >
          <input
            style={{ width: "20px", height: "20px" }}
            type="checkbox"
            id="taxable"
            checked={tableProps.data[tableProps.row.index].taxable}
            onChange={(e) => {
              const tempData = [...quoteLineItems];
              tempData[tableProps.row.index].taxable = e.target.checked;
              dispatch({ type: "RESET_DATA", newData: tempData });
            }}
          />
        </span>
      ),
    },

    {
      Header: "Delete",
      id: "delete",
      accessor: (str) => "delete",

      Cell: (tableProps) => (
        <span
          onClick={() => {
            dispatch({ type: "REMOVE_LINE", id: tableProps.row.original.id });
          }}
        >
          <img
            src={X}
            alt="X"
            style={{
              cursor: "pointer",
              width: "20px",
            }}
          />
        </span>
      ),
    },
  ];

  const [skipPageReset, setSkipPageReset] = React.useState(false);

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);

    const tempData = quoteLineItems.map((row, index) => {
      if (index === rowIndex) {
        // machiningPriceEach, nonRecurringPrice, qty
        const costEach = priceEach(
          row.machiningPriceEach,
          row.nonRecurringPrice,
          value
        );

        // console.log("costEach", costEach);

        return {
          ...quoteLineItems[rowIndex],
          [columnId]: Number(value),
          cost_each: Number(costEach).toFixed(2),
        };
      }
      return row;
    });

    // console.log("tempData", tempData);

    dispatch({ type: "RESET_DATA", newData: tempData });
  };

  // After data changes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    setSkipPageReset(false);
  }, [quoteLineItems]);

  //This throws a warning but it works
  const columns = useMemo(() => COLUMNS, [salesTax, shippingCost, totalCost]);
  const data = useMemo(() => quoteLineItems, [quoteLineItems]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data, // use the skipPageReset option to disable page resetting temporarily
    autoResetPage: !skipPageReset,
    // updateMyData isn't part of the API, but
    // anything we put into these options will
    // automatically be available on the instance.
    // That way we can call this function from our
    // cell renderer!
    updateMyData,
  });

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map((footerGroup) => (
            <tr {...footerGroup.getFooterGroupProps()}>
              {footerGroup.headers.map((column) => (
                <td {...column.getFooterProps()}>{column.render("Footer")}</td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </>
  );
};

export default QuoteTable;
