import React from "react";
import { withResizeDetector } from "react-resize-detector";
import ModelViewer from "./ModelViewer";

const s = {
  columnSub: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    flex: 1,
    fontSize: "30px",
    textAlign: "center",
    // backgroundColor: "pink",
    // border: "1px dotted maroon",
  },
};

const MainFrame = ({ width, height, gltf3D, targetRef }) => {
  // const [count, setCount] = useState(0);

  // useEffect(() => {
  //   if (width || height) {
  //     setCount((count) => count + 1);
  //   }
  // }, [width, height, setCount]);

  return (
    <div ref={targetRef} style={s.columnSub}>
      {/* {console.log(`ModelViewWrapper resized ${count} times`)}
      {console.log(`ModelViewWrapper Width: ${width}, Height: ${height}`)} */}
      <ModelViewer gltf3D={gltf3D} />
    </div>
  );
};

const MainFrameWithResizeDetector = withResizeDetector(MainFrame);

const ModelViewWrapper = (gltf3D) => {
  //   const butt = "top";
  return <MainFrameWithResizeDetector gltf3D={gltf3D} />;
};

export default ModelViewWrapper;
