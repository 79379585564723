import React, { useState, useEffect, useContext } from "react";
import { PlateDataContext } from "../PlateForm/PlateDataContext";
import LengthBox from "./LengthBox";
import Info from "./Info";
import CheckBox from "./CheckBox";
import {
  getHoleData,
  convertMeterToLengthUnit,
  determineHoleZ,
} from "../Features/FeatureHole/helpers";

export default function TapDepth(props) {
  const {
    hidden,
    featureIndex,
    tapDepthNeedsUpdate,
    setTapDepthNeedsUpdate,
    tapDepthError,
    setTapDepthError,
    minTapDepth,
    maxTapDepth,
    tapThroughNeedsUpdate,
    setTapThroughNeedsUpdate,
    featureNode,
    setDisableDrillDepth,
  } = props;
  const { userData, setUserData } = useContext(PlateDataContext);
  const [numThreads, setNumThreads] = useState(0);
  const [disableTapDepth, setDisableTapDepth] = useState(false);

  useEffect(() => {
    const holeData = getHoleData(featureNode);
    const { pitchMeter } = holeData;
    setNumThreads((featureNode.tapDepth.meter / pitchMeter).toFixed(1));
  }, [featureNode.tapDepth, featureNode]);

  useEffect(() => {
    if (featureNode.tapThrough) {
      setDisableTapDepth(true);
      const holeZ = determineHoleZ(featureNode.cubeFace, userData);
      if (featureNode.tapDepth !== holeZ) {
        featureNode.tapDepth = holeZ;
      }
    } else setDisableTapDepth(false);
  }, [
    featureNode.cubeFace,
    featureNode.tapThrough,
    featureNode.tapDepth,
    userData,
  ]);

  const tapThroughReset = (tempData) => {
    const holeZ = determineHoleZ(featureNode.cubeFace, userData);
    if (tempData.plate.features[featureIndex].tapThrough) {
      const holeZ = determineHoleZ(featureNode.cubeFace, userData);
      tempData.plate.features[featureIndex].drillDepth = holeZ;
      tempData.plate.features[featureIndex].tapDepth = holeZ;
      tempData.plate.features[featureIndex].drillThrough = true;
      setDisableDrillDepth(true);
      setUserData((currentData) => {
        return {
          ...currentData,
          ...tempData,
        };
      });
    } else {
      // don't fuck with drill thru
      // if 2X tap diameter fits set to 2X
      const holeData = getHoleData(featureNode);
      const { outerDiameter } = holeData;
      // console.log("holeZ.meter", holeZ.meter);
      // console.log("2 * outerDiameter", 2 * outerDiameter);
      if (holeZ.meter >= 2 * outerDiameter) {
        // console.log("here", outerDiameter);
        tempData.plate.features[featureIndex].tapDepth =
          convertMeterToLengthUnit(2 * outerDiameter);
      }
      setUserData((currentData) => {
        return {
          ...currentData,
          ...tempData,
        };
      });
    }
  };

  return (
    <div className={`input_boxes input_boxes_close ${hidden ? "hidden" : ""}`}>
      <LengthBox
        name="Tap Depth"
        keyName="tapDepth"
        featureIndex={featureIndex}
        needsUpdate={tapDepthNeedsUpdate}
        setNeedsUpdate={setTapDepthNeedsUpdate}
        error={tapDepthError}
        setError={setTapDepthError}
        placeholder="drill depth"
        min={minTapDepth}
        max={maxTapDepth}
        featureNode={featureNode.tapDepth}
        disabled={disableTapDepth}
      />
      <Info
        name="# Threads"
        value={numThreads}
        hidden={featureNode.holeFeatureType === "TAP" ? false : true}
      />
      <CheckBox
        name="Through"
        keyName="tapThrough"
        featureIndex={featureIndex}
        setNeedsUpdate={setTapThroughNeedsUpdate}
        needsUpdate={tapThroughNeedsUpdate}
        featureNode={featureNode.tapThrough}
        auxChange={tapThroughReset}
      />
      {/* <CheckBox
        name="Tap Both Ends"
        keyName="tapBothEnds"
        featureIndex={featureIndex}
        setNeedsUpdate={setTapBothEndsNeedsUpdate}
        needsUpdate={tapBothEndsNeedsUpdate}
        featureNode={featureNode.tapBothEnds}
      /> */}
    </div>
  );
}
