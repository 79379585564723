import React, { useEffect, useContext } from "react";
import { PlateDataContext } from "../PlateForm/PlateDataContext";

export default function CornerTypeBox(props) {
  const {
    name,
    keyName,
    featureIndex,
    setNeedsUpdate,
    needsUpdate,
    featureNode,
  } = props;
  const { userData, setUserData, onshapeData } = useContext(PlateDataContext);

  useEffect(() => {
    // console.log(keyName, "loaded");
    return () => {
      setNeedsUpdate(false); // no longer testing this
      // console.log(keyName, "unloaded");
    };
  }, [setNeedsUpdate]);

  // compares featureData with onshapeData
  useEffect(() => {
    //console.log(featureNode);
    if (featureNode == null) return;

    //console.log("featureNode", keyName, featureNode[keyName]);

    if (typeof onshapeData.plate.features[featureIndex] == "undefined") {
      setNeedsUpdate(true);
      return;
    }

    // console.log(
    //   "onshapeData",
    //   keyName,
    //   onshapeData.plate.features[featureIndex][keyName]
    // );

    if (
      featureNode[keyName] === onshapeData.plate.features[featureIndex][keyName]
    ) {
      setNeedsUpdate(false);
    } else {
      setNeedsUpdate(true);
    }
  }, [
    featureIndex,
    featureNode,
    userData,
    keyName,
    onshapeData.plate.features,
    setNeedsUpdate,
  ]);

  const radioOnChange = (event) => {
    //console.log("featureNode", featureNode);
    let { value } = event.target;
    // console.log("name", name);
    // console.log("value", value);
    const tempData = { ...userData };
    tempData.plate.features[featureIndex][keyName] = value;
    setUserData({
      ...userData,
      ...tempData,
    });
  };

  return (
    <div className="form-group">
      <div className="label">{name}: </div>
      <div className={`input options ${needsUpdate && "input_needsUpdate"}`}>
        <label className="radio radio1st">
          <input
            type="radio"
            name="cornerType"
            value="NONE"
            checked={featureNode.cornerType === "NONE"}
            onChange={radioOnChange}
          />
          None
        </label>
        <label className="radio">
          <input
            type="radio"
            name="cornerType"
            value="RADIUS"
            checked={featureNode.cornerType === "RADIUS"}
            onChange={radioOnChange}
          />
          Radius
        </label>
        <label className="radio">
          <input
            type="radio"
            name="cornerType"
            value="CHAMFER"
            checked={featureNode.cornerType === "CHAMFER"}
            onChange={radioOnChange}
          />
          Chamfer
        </label>
        {/* <button
          className="btn control_button"
          onClick={() => {
            console.log(featureNode);
          }}
        >
          featureNode
        </button> */}
      </div>
    </div>
  );
}
