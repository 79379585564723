import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { isAuth } from "../auth/helpers";
import { QuoteContext } from "../Procurement/QuoteContext";
import Quote from "../Procurement/Quote";

const Header = ({ currentPath }) => {
  const isActive = (path) => {
    if (currentPath === path) {
      return true;
    } else {
      return false;
    }
  };
  const { isQuoteOpen, setIsQuoteOpen } = useContext(QuoteContext);

  return (
    <Fragment>
      <Quote
        className="info"
        open={isQuoteOpen}
        onClose={() => setIsQuoteOpen(false)}
      />
      <nav className="header">
        <header className="branding logo-title">
          <img
            className="logo"
            src={require("../onshape/components/img/CCElogo.png")}
            alt="CCE Logo"
          />
          <div className="branding cce">Circuit Case Engineering</div>
        </header>
        <ul className="nav-list">
          {!isAuth() && (
            <Fragment>
              <li className="nav-link">
                <Link to="/" className={isActive("/") ? "nav-active" : "cta"}>
                  Login
                </Link>
              </li>
              <li className="nav-link">
                <Link
                  to="/signup"
                  className={isActive("/signup") ? "nav-active" : "cta"}
                >
                  Signup
                </Link>
              </li>
            </Fragment>
          )}

          {isAuth() && isAuth().role === "admin" && (
            <li className="nav-link">
              <Link
                className={isActive("/admin") ? "nav-active" : "cta"}
                to="/admin"
              >
                {isAuth().name}
              </Link>
            </li>
          )}

          {isAuth() && isAuth().role === "subscriber" && (
            <>
              {/* //TODO: need a contact form or something 
            <li className="nav-link">
              <Link
                className={isActive("/settings") ? "nav-active" : ""}
                to="/settings"
              >
                Contact
              </Link>
            </li> */}
              <li className="nav-link">
                <div
                  onClick={() => {
                    setIsQuoteOpen(true);
                  }}
                >
                  <i className="fas fa-shopping-cart cta" />
                </div>
              </li>
              <li className="nav-link">
                <Link
                  className={isActive("/plate") ? "nav-active" : "cta"}
                  to="/plate"
                >
                  Plate
                </Link>
              </li>
              <li className="nav-link">
                <Link
                  className={isActive("/settings") ? "nav-active" : ""}
                  to="/settings"
                >
                  <i className="fas fa-cog cta"></i>
                </Link>
              </li>
            </>
          )}
        </ul>
      </nav>
    </Fragment>
  );
};

export default Header;
