// TODO: Move costing to feature?? This would be beneficial
// if we are getting machining data back.

// reference: https://cad.onshape.com/documents/bfd97dab8e9c07f07a0a1059/w/008443ae9ee67cdabb4b320a/e/954ef9649e41ee4e83319eb1
// Dials I can play with
//    pricePerVolume
//    camPrice
//    millTableSetupPrice
//    holeToolSetupPrice
//

export default function priceItem(onshapeData) {
  const features = onshapeData.plate.features;
  const unit = (() => {
    if (onshapeData.unit === "MM") {
      return "mm";
    } else {
      return "inch";
    }
  })();
  //   console.log(features);
  let setups = [];
  let numUniqueHoleTools = []; // this would be non standard tooling which would need to be loaded
  let materialPriceEach = 0;
  let facingRoughingProfilingPriceEach = 0;
  let totalHolePriceEach = 0;
  let description = "";

  features.forEach((feature) => {
    if (feature.featureType === "featureExternal") {
      //featureExternal has two setups
      addToArray(setups, "TOP_FACE");
      addToArray(setups, "BOTTOM_FACE");
      description = `Plate ${feature.xLength[unit]}L X ${feature.yWidth[unit]}W X ${feature.zHeight[unit]}H`;
      // console.log(feature);
      //markup = cut cost and shipping costs
      //www.mcmaster.com/9008K14-9008K141/
      //pricePerVolume = $7.74 / 1"*1"*12" = .645 + 1.15
      // const pricePerVolume = 0.742; too low
      const pricePerVolume = 1.0;

      materialPriceEach =
        feature.xLength.inch *
        feature.yWidth.inch *
        feature.zHeight.inch *
        pricePerVolume;
      // console.log("materialPriceEach", materialPriceEach);
      let cornerTypeAdder = 0;
      if (feature.cornerType !== "NONE") cornerTypeAdder = 8;
      facingRoughingProfilingPriceEach =
        2.8 * materialPriceEach + cornerTypeAdder;
      // console.log(
      //   "facingRoughingProfilingPriceEach",
      //   facingRoughingProfilingPriceEach
      // );
      // -facing [stockSize]
      // -rouging [stockSize, finishSize, cornerType, cornerValue]
      // -profiling [finishSize, cornerType, cornerValue]
    } else if (feature.featureType === "featureHole") {
      addToArray(setups, feature.cubeFace);
      //   setTitle(`[${featureIndex}] ${quantity}X ${size} ${holeFeatureType}`);

      let size = "";
      let holeCostEach = 0;

      if (feature.standard === "METRIC") {
        size = "metric";
        switch (feature.holeFeatureType) {
          case "TAP":
            size = feature.metricTap;
            holeCostEach = 2.87;
            break;
          case "CLEARANCE_HOLE":
            size = feature.metricClearanceSize;
            holeCostEach = 1.38;
            break;
          case "CBORE":
            size = feature.metricClearanceSize;
            holeCostEach = 2.56;
            break;
          case "CSINK":
            size = feature.metricClearanceSize;
            holeCostEach = 2.27;
            break;
          // case "DRILL":
          //   size = featureData.;
          //   break;

          default:
            break;
        }
      } else if (feature.standard === "INCH") {
        size = "metric";
        switch (feature.holeFeatureType) {
          case "TAP":
            size = feature.inchTap;
            break;
          case "CLEARANCE_HOLE":
            size = feature.inchClearanceSize;
            break;
          case "CBORE":
            size = feature.inchClearanceSize;
            break;
          case "CSINK":
            size = feature.inchClearanceSize;
            break;
          // case "DRILL":
          //   size = featureData.;
          //   break;

          default:
            break;
        }
      }

      const holeType = size + " " + feature.holeFeatureType;
      //   console.log(holeType);
      addToArray(numUniqueHoleTools, holeType);

      var holeQuantityEach = "";

      switch (feature.featurePattern) {
        case "NONE":
          holeQuantityEach = 1;
          break;
        case "CIRCULAR":
          holeQuantityEach = feature.numberCircular;
          break;
        case "LINEAR":
          holeQuantityEach = feature.numberX * feature.numberY;
          break;
        default:
          break;
      }
      //   console.log(holeQuantityEach);
      totalHolePriceEach = totalHolePriceEach + holeCostEach * holeQuantityEach;
    }
  });

  //   console.log(setups);
  //   console.log(numUniqueHoleTools);
  // console.log("totalHolePriceEach", totalHolePriceEach);
  // console.log("description", description);

  const camPrice = setups.length * 12 + numUniqueHoleTools.length * 5;
  // console.log("camPrice", camPrice);
  const millTableSetupPrice = setups.length * 4;
  // console.log("millTableSetupPrice", millTableSetupPrice);
  const holeToolSetupPrice = numUniqueHoleTools.length * 6;
  // console.log("holeToolSetupPrice", holeToolSetupPrice);
  const nonRecurringPrice = camPrice + millTableSetupPrice + holeToolSetupPrice;
  // console.log("nonRecurringPrice", nonRecurringPrice);

  const machiningPriceEach =
    materialPriceEach + totalHolePriceEach + facingRoughingProfilingPriceEach;
  // console.log("machiningPriceEach", machiningPriceEach);

  return {
    description: description,
    machiningPriceEach: Number(machiningPriceEach.toFixed(2)),
    nonRecurringPrice: Number(nonRecurringPrice.toFixed(2)),
    priceDate: new Date(),
  };
}

const addToArray = (array, item) => {
  if (!array.includes(item)) array.push(item);
};
