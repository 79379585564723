import React, { useState, useRef, useEffect } from "react";
import { AccordionContext } from "./AccordionContext";
import Chevron from "./Chevron";
import "./Accordion.css";

export default function Accordion({
  currentAccordionOpen,
  setCurrentAccordionOpen,
  accordionIndex,
  content,
}) {
  const contentRef = useRef(null);
  const [title, setTitle] = useState("");
  const [primaryView, setPrimaryView] = useState("");
  const [active, setActive] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [showChevron, setShowChevron] = useState(true);
  const [highlight, setHighlight] = useState(false);

  // sets currentAccordionOpen on Delete
  useEffect(() => {
    return () => {
      setCurrentAccordionOpen(0);
      // console.log("accordionIndex", accordionIndex, "was deleted");
    };
  }, [setCurrentAccordionOpen]);

  useEffect(() => {
    if (active) {
      setCurrentAccordionOpen(accordionIndex);
    } else {
      setCurrentAccordionOpen(0);
    }
    // contentRef.current.style.maxHeight = active
    //   ? `${contentRef.current.scrollHeight}px`
    //   : "0px";
    contentRef.current.style.maxHeight = active ? "500px" : "0px";
  }, [contentRef, active, accordionIndex, setCurrentAccordionOpen]);

  useEffect(() => {
    if (currentAccordionOpen > 0) {
      setShowChevron(false);
    } else {
      setShowChevron(true);
    }
  }, [currentAccordionOpen]);

  const expand = () => {
    if (!showChevron) return;
    setActive(true);
    //FIX: set view here
    // switch (featureData.cubeFace) {
    //   case "TOP_FACE":
    //     setCurrentView("Top");
    //     break;
    //   case "FRONT_FACE":
    //     setCurrentView("Front");
    //     break;
    //   case "BOTTOM_FACE":
    //     setCurrentView("Bottom");
    //     break;
    //   case "BACK_FACE":
    //     setCurrentView("Back");
    //     break;
    //   case "LEFT_FACE":
    //     setCurrentView("Left");
    //     break;
    //   case "RIGHT_FACE":
    //     setCurrentView("Right");
    //     break;
    //   default:
    //     break;
    // }
    // disable view buttons
  };

  return (
    <AccordionContext.Provider
      value={{
        titleValues: [title, setTitle],
        setPrimaryView,
        activeValues: [active, setActive],
        titleErrorValues: [titleError, setTitleError],
        currentAccordionOpen,
        highlight,
      }}
    >
      <div className="accordion__section">
        <button
          className={`accordion ${active && "active"}`}
          onClick={expand}
          onMouseOver={() => {
            /* console.log("onMouseOver"); */
            setHighlight(true);
          }}
          onMouseOut={() => {
            /* console.log("onMouseOut"); */
            setHighlight(false);
          }}
        >
          <div
            className={`accordion__title  ${
              titleError && "accordion__title__error"
            }`}
          >
            {title} <i>{primaryView}</i>
          </div>
          <Chevron
            className={
              showChevron ? "accordion__icon rotate" : "accordion__icon"
            }
            width={15}
            fill={"var(--cta-color)"}
          />
        </button>
        <div ref={contentRef} className="accordion__content">
          {content}
        </div>
      </div>
    </AccordionContext.Provider>
  );
}
