import React, { useRef, useEffect, useState, useContext } from "react";
import { PlateDataContext } from "../PlateForm/PlateDataContext";
import previewFeatureExternal from "./previewFeatureExternal";
import previewFeatureHole from "./previewFeatureHole";

export default function PlateCanvas({
  parentWidth,
  parentHeight,
  src,
  alt,
  x,
  y,
  z,
  xText,
  yText,
  previewData,
  rotation,
  currentView,
}) {
  const { unit } = useContext(PlateDataContext);
  const canvasRefImage = useRef(null);
  const ctxRefDimension = useRef(null);
  const ctxRef = useRef(null);
  const canvasRefPreview = useRef(null);
  const ctxRefPreview = useRef(null);
  const [scale, setScale] = useState(1);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [sideLength, setSideLength] = useState(0);
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const spaceAround = 150;

  //***Set scale and image
  useEffect(() => {
    // console.log("running Image Update");
    // console.log("src", src);
    // console.log("parentWidth", parentWidth);
    // console.log("parentHeight", parentHeight);
    if (!parentHeight || !parentWidth) return;

    const image = new Image();
    image.src = src;

    image.onload = function () {
      scaleImage(this);
    };

    function scaleImage(img) {
      // Scale image to fit container
      // console.log("img.width", img.width);
      // console.log("img.height", img.height);
      if (parentWidth <= parentHeight) {
        img.width = parentWidth - spaceAround;
        img.height = parentWidth - spaceAround;
      } else {
        img.width = parentHeight - spaceAround;
        img.height = parentHeight - spaceAround;
      }

      //Set view scale to largest possible scale of x,y,z so all views are a consistent size

      //sort x,y,z from smallest to largest
      let sizeOrder = {
        x: x,
        y: y,
        z: z,
      };

      sizeOrder = Object.entries(sizeOrder).sort((a, b) => a[1] - b[1]);
      // console.log("sizeOrder", sizeOrder);
      // console.log("sizeOrder[2][0]", sizeOrder[2][0]);

      let viewRatio = 1;
      switch (sizeOrder[2][0]) {
        case "x":
          setScale(img.width / x);
          break;
        case "y":
          setScale(img.height / y);
          break;
        default:
          //Z (not in current view) is largest size
          viewRatio = sizeOrder[1][1] / sizeOrder[2][1];
          //set scale to second longest side * viewRatio
          if (sizeOrder[1][0] === "x") {
            setScale((img.width / x) * viewRatio);
          } else {
            setScale((img.height / y) * viewRatio);
          }
          break;
      }

      const canvas = canvasRefImage.current;

      // console.log("parentWidth", parentWidth);
      // console.log("parentHeight", parentHeight);

      if (parentWidth <= parentHeight) {
        canvas.width = parentWidth;
        canvas.height = parentWidth;
        setSideLength(parentWidth);
        setTop((parentHeight - parentWidth) / 2);
        setLeft(0);
      } else {
        canvas.width = parentHeight;
        canvas.height = parentHeight;
        setSideLength(parentHeight);
        setTop(0);
        setLeft((parentWidth - parentHeight) / 2);
      }

      const ctx = canvas.getContext("2d");

      // // for layout visual reference border
      // ctx.beginPath();
      // if (parentWidth <= parentHeight) {
      //   ctx.rect(1, 1, parentWidth - 2, parentWidth - 2);
      // } else {
      //   ctx.rect(1, 1, parentHeight - 2, parentHeight - 2);
      // }
      // ctx.strokeStyle = "purple";
      // ctx.stroke();

      // // for layout visual reference dimension
      // ctx.beginPath();
      // // if (parentWidth <= parentHeight) {
      // ctx.rect(
      //   spaceAround / 2 - 2,
      //   spaceAround / 2 - 2,
      //   img.width + 4,
      //   img.height + 4
      // );
      // // } else {
      // ctx.rect(1, 1, parentHeight - 2, parentHeight - 2);
      // // }
      // ctx.strokeStyle = "orange";
      // ctx.stroke();
      // ctx.strokeStyle = "black";

      ctx.save(); //save initial coordinate system
      ctx.translate(canvas.width / 2, canvas.height / 2);
      // rotate the canvas to the specified degrees
      ctx.rotate((rotation * Math.PI) / 180);

      // add 1 pixel to each side and draw image
      // somehow a pixel gets lost to each side??
      // below aligns dimensions and previews

      const dx = (-img.width * viewRatio) / 2 - 1;
      const dy = (-img.height * viewRatio) / 2 - 1;
      const dWidth = img.width * viewRatio + 2;
      const dHeight = img.height * viewRatio + 2;

      ctx.drawImage(img, dx, dy, dWidth, dHeight);

      // original; dimensions did not line up

      // ctx.drawImage(
      //   img,
      //   (-img.width * viewRatio) / 2,
      //   (-img.height * viewRatio) / 2,
      //   img.width * viewRatio,
      //   img.height * viewRatio
      // );
    }
    setImageLoaded(true);
    // console.log("imageLoaded");
  }, [src, x, y, z, rotation, parentWidth, parentHeight]);

  //***Set dimensions
  useEffect(() => {
    // console.log("running Dimension Update");
    //TODO: Add arrows to dimension lines
    //TODO: Extend Extension lines to radii and chamfers

    if (!imageLoaded) return;

    // console.log("image loaded");

    const canvas = ctxRefDimension.current;
    canvas.width = sideLength;
    canvas.height = sideLength;
    const ctx = canvas.getContext("2d");
    ctx.lineCap = "round";
    ctx.strokeStyle = "black";
    ctx.lineWidth = 1;
    ctxRef.current = ctx;

    ctx.save(); //save initial coordinate system
    ctx.translate(canvas.width / 2, canvas.height / 2);
    // rotate the canvas to the specified degrees
    ctx.rotate((rotation * Math.PI) / 180);

    ctx.restore(); //reset original

    let xUpperLeftCorner = (canvas.width - x * scale) / 2;
    let yUpperLeftCorner = (canvas.height - y * scale) / 2;

    //Horizontal
    ctx.font = "12px Arial";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    let txtWidth = ctx.measureText(xText).width;
    let xDimLineLength = (x * scale - txtWidth - 10) / 2;
    //extension lines
    ctx.moveTo(xUpperLeftCorner, yUpperLeftCorner - 5);
    ctx.lineTo(xUpperLeftCorner, yUpperLeftCorner - 50);
    ctx.moveTo(xUpperLeftCorner + x * scale, yUpperLeftCorner - 5);
    ctx.lineTo(xUpperLeftCorner + x * scale, yUpperLeftCorner - 50);
    //dimension lines
    if (txtWidth + 30 <= x * scale) {
      ctx.moveTo(xUpperLeftCorner, yUpperLeftCorner - 40);
      ctx.lineTo(xUpperLeftCorner + xDimLineLength, yUpperLeftCorner - 40);
      ctx.moveTo(xUpperLeftCorner + x * scale, yUpperLeftCorner - 40);
      ctx.lineTo(
        xUpperLeftCorner + x * scale - xDimLineLength,
        yUpperLeftCorner - 40
      );
      ctx.lineWidth = "1";
      ctx.strokeStyle = "green";
      ctx.stroke();
      //text
      ctx.fillStyle = "black";
      ctx.fillText(
        xText,
        xUpperLeftCorner + (x * scale) / 2,
        yUpperLeftCorner - 40
      );
    } else {
      ctx.moveTo(xUpperLeftCorner, yUpperLeftCorner - 40);
      ctx.lineTo(xUpperLeftCorner + x * scale + 15, yUpperLeftCorner - 40);
      ctx.lineWidth = "1";
      ctx.strokeStyle = "green";
      ctx.stroke();
      //text
      ctx.fillStyle = "black";
      ctx.fillText(
        xText,
        xUpperLeftCorner + x * scale + 20 + txtWidth / 2,
        yUpperLeftCorner - 40
      );
    }

    //Vertical
    txtWidth = ctx.measureText(yText).width;

    let yDimLineLength = (y * scale - txtWidth - 10) / 2;
    //extension lines
    ctx.moveTo(xUpperLeftCorner - 5, yUpperLeftCorner);
    ctx.lineTo(xUpperLeftCorner - 50, yUpperLeftCorner);
    ctx.moveTo(xUpperLeftCorner - 5, yUpperLeftCorner + y * scale);
    ctx.lineTo(xUpperLeftCorner - 50, yUpperLeftCorner + y * scale);
    //dimension lines
    if (txtWidth + 30 <= y * scale) {
      ctx.moveTo(xUpperLeftCorner - 40, yUpperLeftCorner);
      ctx.lineTo(xUpperLeftCorner - 40, yUpperLeftCorner + yDimLineLength);
      ctx.moveTo(xUpperLeftCorner - 40, yUpperLeftCorner + y * scale);
      ctx.lineTo(
        xUpperLeftCorner - 40,
        yUpperLeftCorner + y * scale - yDimLineLength
      );
      ctx.lineWidth = "1";
      ctx.strokeStyle = "green";
      ctx.stroke();
      ctx.save();
      //text
      ctx.fillStyle = "black";
      ctx.translate(xUpperLeftCorner - 40, yUpperLeftCorner + (y * scale) / 2);
      ctx.rotate(-Math.PI / 2);
      ctx.fillText(yText, 0, 0);
      ctx.restore();
    } else {
      ctx.moveTo(xUpperLeftCorner - 40, yUpperLeftCorner - 15);
      ctx.lineTo(xUpperLeftCorner - 40, yUpperLeftCorner + y * scale);
      ctx.lineWidth = "1";
      ctx.strokeStyle = "green";
      ctx.stroke();
      //text
      ctx.fillStyle = "black";
      ctx.translate(
        xUpperLeftCorner - 40,
        yUpperLeftCorner - 20 - txtWidth / 2
      );
      ctx.rotate(-Math.PI / 2);
      ctx.fillText(yText, 0, 0);
    }
  }, [unit, scale, x, y, xText, yText, imageLoaded, rotation, sideLength]);

  //***Set previewFeatureExternal or previewFeatureHole
  useEffect(() => {
    // console.log("previewData", previewData);
    //TODO: scale existing only if bigger. part = 1x1  preview = 8x8

    //Below clears preview every time
    const canvas = canvasRefPreview.current;
    canvas.width = sideLength;
    canvas.height = sideLength;
    const ctxPreview = canvas.getContext("2d");
    ctxRefPreview.current = ctxPreview;
    ctxRefPreview.current.strokeStyle = "Maroon";
    ctxRefPreview.current.setLineDash([20, 3, 3, 3, 3, 3]);
    //ctxRefPreview.current.setLineDash([15, 5]);
    ctxRefPreview.current.lineWidth = 1;

    let xCenter = canvas.width / 2;
    let yCenter = canvas.height / 2;

    if (Object.keys(previewData).length) {
      // console.log("previewData.featureType", previewData.featureType);
      switch (previewData.featureType) {
        case "featureExternal":
          // console.log("previewData featureExternal", previewData);

          previewFeatureExternal(
            ctxRefPreview,
            previewData,
            currentView,
            scale,
            xCenter,
            yCenter
          );
          break;

        case "featureHole":
          // console.log("previewData featureHole", previewData);
          previewFeatureHole(
            ctxRefPreview,
            previewData,
            currentView,
            scale,
            xCenter,
            yCenter,
            x,
            y
          );
          break;

        default:
          break;
      }
    }
  }, [scale, previewData, currentView, unit, sideLength, x, y, z]);

  return (
    <div style={{ position: "relative" }}>
      {/* {console.log("width at return", width)} */}
      <canvas
        id="canvas1"
        ref={canvasRefImage}
        style={{
          position: "absolute",
          left: `${left}px`,
          top: `${top}px`,
          width: `${sideLength}px`,
          zIndex: 1,
          backgroundColor: "transparent",
          visibility: "visible", // set to "hidden" for troubleshooting
        }}
      >
        {alt} <br /> Sorry, your browser does not support the canvas tag.
      </canvas>
      <canvas
        ref={ctxRefDimension}
        style={{
          position: "absolute",
          left: `${left}px`,
          top: `${top}px`,
          width: `${sideLength}px`,
          zIndex: 2,
          backgroundColor: "transparent",
          visibility: "visible", // set to "hidden" for troubleshooting
        }}
      >
        {alt} <br /> Sorry, your browser does not support the canvas tag.
      </canvas>
      <canvas
        ref={canvasRefPreview}
        style={{
          position: "absolute",
          left: `${left}px`,
          top: `${top}px`,
          width: `${sideLength}px`,
          zIndex: 3,
          backgroundColor: "transparent",
          visibility: "visible", // set to "hidden" for troubleshooting
        }}
      >
        {alt} <br /> Sorry, your browser does not support the canvas tag.
      </canvas>
    </div>
  );
}
