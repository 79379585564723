// Reference: https://reactjsexample.com/google-oauth2-using-the-new-google-identity-services-sdk-for-react/

import React from "react";
import axios from "axios";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

const Google = ({ informParent = (f) => f }) => {
  const responseGoogle = (response) => {
    console.log("response", response);
    console.log("tokenId", response.credential);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API}/google-login`,
      data: { idToken: response.credential },
    })
      .then((response) => {
        console.log("GOOGLE SIGNIN SUCCESS", response);
        // inform parent component
        informParent(response);
      })
      .catch((error) => {
        console.log("GOOGLE SIGNIN ERROR", response);
        // setDisabled(true);
      });
  };

  return (
    <div className="pb-3">
      <GoogleOAuthProvider
        clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
      >
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            responseGoogle(credentialResponse);
          }}
          onError={() => {
            console.log("Google Login Failed");
          }}
          useOneTap
          
        />
      </GoogleOAuthProvider>
    </div>
  );
};

export default Google;
