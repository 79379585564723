import React, { useState, useEffect, useContext } from "react";
import { PlateDataContext } from "../../PlateForm/PlateDataContext";
import { AccordionContext } from "../../Accordion/AccordionContext";
import {
  cubeFaces,
  startOrigins,
  holeFeatureTypes,
  metricTaps,
  inchTaps,
  metricClearanceSizes,
  metricCBoreSizes,
  metricCSinkSizes,
  inchClearanceSizes,
  fits,
  featurePatterns,
} from "./constantsFeatureHoles";
import LengthBox from "../../InputBoxes/LengthBox";
import DegreeBox from "../../InputBoxes/DegreeBox";
import IntegerBox from "../../InputBoxes/IntegerBox";
import SelectBox from "../../InputBoxes/SelectBox";
import StandardBox from "../../InputBoxes/StandardBox";
import DrillDepth from "../../InputBoxes/DrillDepth";
import {
  getHoleData,
  convertMeterToLengthUnit,
  determineHoleZ,
  getTapBottomClearanceMeter,
  getTipLengthMeter,
} from "./helpers";

//const token = getCookie("token");

export default function FeatureHole({ featureIndex }) {
  const {
    userData,
    setUserData,
    onshapeData,
    currentView,
    setCurrentView,
    previewData,
    setPreviewData,
    setViewsDisabled,
    unit,
    showDevData,
    handleModify,
    handleDelete,
    handleAdd,
    handleCancel,
  } = useContext(PlateDataContext);
  // const [featureData, setFeatureData] = useState(() => {
  //   // breaks any pointers and makes a true detached copy
  //   // Does not work with undefined, NaN, infinity, functions....
  //   const deepCopyData = JSON.parse(
  //     JSON.stringify(userData.plate.features[featureIndex])
  //   );
  //   return deepCopyData;
  // });
  const featureData = userData.plate.features[featureIndex];
  const [addFlag, setAddFlag] = useState(false);
  const [needsUpdateFlag, setNeedsUpdateFlag] = useState(false);
  const [errorsFlag, setErrorsFlag] = useState(false);
  const [cubeFaceNeedsUpdate, setCubeFaceNeedsUpdate] = useState(false);
  const [cubeFaceError, setCubeFaceError] = useState(false);
  const [featureOriginNeedsUpdate, setFeatureOriginNeedsUpdate] =
    useState(false);
  const [featureOriginError, setFeatureOriginError] = useState(false);
  const [xOffsetNeedsUpdate, setXOffsetNeedsUpdate] = useState(false);
  const [xOffsetError, setXOffsetError] = useState(false);
  const [yOffsetNeedsUpdate, setYOffsetNeedsUpdate] = useState(false);
  const [yOffsetError, setYOffsetError] = useState(false);
  const [standardNeedsUpdate, setStandardNeedsUpdate] = useState(false);
  const [standardError, setStandardError] = useState(false);
  const [holeFeatureTypeNeedsUpdate, setHoleFeatureTypeNeedsUpdate] =
    useState(false);
  const [holeFeatureTypeError, setHoleFeatureTypeError] = useState(false);
  const [metricTapNeedsUpdate, setMetricTapNeedsUpdate] = useState(false);
  const [metricTapError, setMetricTapError] = useState(false);
  const [inchTapNeedsUpdate, setInchTapNeedsUpdate] = useState(false);
  const [inchTapError, setInchTapError] = useState(false);
  const [metricClearanceSizeNeedsUpdate, setMetricClearanceSizeNeedsUpdate] =
    useState(false);
  const [metricClearanceSizeError, setMetricClearanceSizeError] =
    useState(false);
  const [inchClearanceSizeNeedsUpdate, setInchClearanceSizeNeedsUpdate] =
    useState(false);
  const [inchClearanceSizeError, setInchClearanceSizeError] = useState(false);
  const [fitNeedsUpdate, setFitNeedsUpdate] = useState(false);
  const [fitError, setFitError] = useState(false);
  const [drillDepthNeedsUpdate, setDrillDepthNeedsUpdate] = useState(false);
  const [drillDepthError, setDrillDepthError] = useState(false);
  const [drillThroughNeedsUpdate, setDrillThroughNeedsUpdate] = useState(false);
  const [tapDepthNeedsUpdate, setTapDepthNeedsUpdate] = useState(false);
  const [tapDepthError, setTapDepthError] = useState(false);
  const [tapThroughNeedsUpdate, setTapThroughNeedsUpdate] = useState(false);
  const [tapBothEndsNeedsUpdate, setTapBothEndsNeedsUpdate] = useState(false);
  const [featurePatternNeedsUpdate, setFeaturePatternNeedsUpdate] =
    useState(false);
  const [featurePatternError, setFeaturePatternError] = useState(false);
  const [patternDiameterNeedsUpdate, setPatternDiameterNeedsUpdate] =
    useState(false);
  const [patternDiameterError, setPatternDiameterError] = useState(false);
  const [startAngleNeedsUpdate, setStartAngleNeedsUpdate] = useState(false);
  const [startAngleError, setStartAngleError] = useState(false);
  const [arcAngleNeedsUpdate, setArcAngleNeedsUpdate] = useState(false);
  const [arcAngleError, setArcAngleError] = useState(false);
  const [numberCircularNeedsUpdate, setNumberCircularNeedsUpdate] =
    useState(false);
  const [numberCircularError, setNumberCircularError] = useState(false);
  const [patternOriginNeedsUpdate, setPatternOriginNeedsUpdate] =
    useState(false);
  const [patternOriginError, setPatternOriginError] = useState(false);
  const [patternXNeedsUpdate, setPatternXNeedsUpdate] = useState(false);
  const [patternXError, setPatternXError] = useState(false);
  const [numberXNeedsUpdate, setNumberXNeedsUpdate] = useState(false);
  const [numberXError, setNumberXError] = useState(false);
  const [patternYNeedsUpdate, setPatternYNeedsUpdate] = useState(false);
  const [patternYError, setPatternYError] = useState(false);
  const [numberYNeedsUpdate, setNumberYNeedsUpdate] = useState(false);
  const [numberYError, setNumberYError] = useState(false);
  const {
    titleValues,
    setPrimaryView,
    activeValues,
    titleErrorValues,
    currentAccordionOpen,
    highlight,
  } = useContext(AccordionContext);
  const [, setTitle] = titleValues;
  const [active, setActive] = activeValues;
  const [, setTitleError] = titleErrorValues;

  // sets the highlight on/off
  useEffect(() => {
    // console.log("highlight:", highlight);
    //only preview in the current view; maybe add face to title
    if (featureData.cubeFace !== currentView) return;
    if (highlight) {
      setPreviewData({ ...featureData });
      setNeedsUpdateFlag(true);
    } else {
      setPreviewData({});
      setNeedsUpdateFlag(false);
    }
  }, [highlight, currentView, featureData, setPreviewData]);

  //test if this is a new feature
  useEffect(() => {
    if (onshapeData.plate.features[featureIndex]) {
      setAddFlag(false);
    } else {
      setAddFlag(true);
      setActive(true);
    }
    return;
  }, [userData, featureIndex, onshapeData.plate.features, setActive]);

  // Sets the title; this is repeated in get_hole_title.py
  useEffect(() => {
    // console.log("unit ", unit);

    var holeFeatureType = "";
    holeFeatureTypes.forEach(({ label, value }) => {
      //console.log(value);
      if (value === featureData.holeFeatureType) {
        holeFeatureType = label;
        //console.log(value);
      }
    });

    var size = "";

    if (featureData.standard === "METRIC") {
      switch (featureData.holeFeatureType) {
        case "TAP":
          size = featureData.metricTap;
          break;
        case "CLEARANCE_HOLE":
          size = featureData.metricClearanceSize;
          break;
        case "CBORE":
          size = featureData.metricClearanceSize;
          break;
        case "CSINK":
          size = featureData.metricClearanceSize;
          break;
        // case "DRILL":
        //   size = featureData.;
        //   break;
        default:
          break;
      }
    } else {
      switch (featureData.holeFeatureType) {
        case "TAP":
          size = featureData.inchTap;
          break;
        case "CLEARANCE_HOLE":
          size = featureData.inchClearanceSize;
          break;
        case "CBORE":
          size = featureData.inchClearanceSize;
          break;
        case "CSINK":
          size = featureData.inchClearanceSize;
          break;
        // case "DRILL":
        //   size = featureData.;
        //   break;
        default:
          break;
      }
    }

    var quantity = "";

    switch (featureData.featurePattern) {
      case "NONE":
        quantity = 1;
        break;
      case "CIRCULAR":
        quantity = featureData.numberCircular;
        break;
      case "LINEAR":
        quantity = featureData.numberX * featureData.numberY;
        break;
      default:
        break;
    }

    setTitle(`[${featureIndex}] ${quantity}X ${size} ${holeFeatureType}`);

    var view = "";
    switch (featureData.cubeFace) {
      case "TOP_FACE":
        view = "[Top]";
        break;
      case "FRONT_FACE":
        view = "[Front]";
        break;
      case "BOTTOM_FACE":
        view = "[Bottom]";
        break;
      case "BACK_FACE":
        view = "[Back]";
        break;
      case "LEFT_FACE":
        view = "[Left]";
        break;
      case "RIGHT_FACE":
        view = "[Right]";
        break;
      default:
        break;
    }

    setPrimaryView(view);
  }, [
    setPrimaryView,
    featureData,
    featureData.standard,
    featureData.holeFeatureType,
    featureData.metricTap,
    featureData.inchTap,
    featureData.metricClearanceSize,
    featureData.inchClearanceSize,
    featureData.featurePattern,
    featureData.numberCircular,
    featureData.numberX,
    featureData.numberY,
    setTitle,
    featureIndex,
    unit,
  ]);

  // setCurrentView to current cubeFace if changed
  useEffect(() => {
    if (!active) {
      return;
    } else setViewsDisabled(true);
    setCurrentView(featureData.cubeFace);
  }, [
    featureData.cubeFace,
    setCurrentView,
    active,
    setViewsDisabled,
    userData.plate.features,
  ]);

  // Below checks InputBoxes for errors
  useEffect(() => {
    // if (cubeFaceError) console.log("cubeFaceError", cubeFaceError);
    // if (featureOriginError)
    //   console.log("featureOriginError", featureOriginError);
    // if (xOffsetError) console.log("xOffsetError", xOffsetError);
    // if (yOffsetError) console.log("yOffsetError", yOffsetError);
    // if (standardError) console.log("standardError", standardError);
    // if (holeFeatureTypeError)
    //   console.log("holeFeatureTypeError", holeFeatureTypeError);
    // if (metricTapError) console.log("metricTapError", metricTapError);
    // if (inchTapError) console.log("inchTapError", inchTapError);
    // if (metricClearanceSizeError)
    //   console.log("metricClearanceSizeError", metricClearanceSizeError);
    // if (inchClearanceSizeError)
    //   console.log("inchClearanceSizeError", inchClearanceSizeError);
    // if (fitError) console.log("fitError", fitError);
    // if (drillDepthError) {
    //   console.log("drillDepthError", drillDepthError);
    // }
    // if (tapDepthError) {
    //   console.log("tapDepthError", tapDepthError);
    // }
    // if (featurePatternError)
    //   console.log("featurePatternError", featurePatternError);
    // if (patternDiameterError)
    //   console.log("patternDiameterError", patternDiameterError);
    // if (startAngleError) console.log("startAngleError", startAngleError);
    // if (arcAngleError) console.log("arcAngleError", arcAngleError);
    // if (numberCircularError)
    //   console.log("numberCircularError", numberCircularError);
    // if (patternOriginError)
    //   console.log("patternOriginError", patternOriginError);
    // if (patternXError) console.log("patternXError", patternXError);
    // if (numberXError) console.log("numberXError", numberXError);
    // if (patternYError) console.log("patternYError", patternYError);
    // if (numberYError) console.log("numberYError", numberYError);
    if (
      cubeFaceError ||
      featureOriginError ||
      xOffsetError ||
      yOffsetError ||
      standardError ||
      holeFeatureTypeError ||
      metricTapError ||
      inchTapError ||
      metricClearanceSizeError ||
      inchClearanceSizeError ||
      fitError ||
      drillDepthError ||
      tapDepthError ||
      featurePatternError ||
      patternDiameterError ||
      startAngleError ||
      arcAngleError ||
      numberCircularError ||
      patternOriginError ||
      patternXError ||
      numberXError ||
      patternYError ||
      numberYError
    ) {
      setErrorsFlag(true);
      setTitleError(true);
      // console.log("FeatureHole error");
    } else {
      setErrorsFlag(false);
      setTitleError(false);
    }
  }, [
    cubeFaceError,
    featureOriginError,
    xOffsetError,
    yOffsetError,
    standardError,
    holeFeatureTypeError,
    metricTapError,
    inchTapError,
    metricClearanceSizeError,
    inchClearanceSizeError,
    fitError,
    drillDepthError,
    tapDepthError,
    featurePatternError,
    patternDiameterError,
    startAngleError,
    arcAngleError,
    numberCircularError,
    patternOriginError,
    patternXError,
    numberXError,
    patternYError,
    numberYError,
    setTitleError,
  ]);

  // Below checks InputBoxes for NeedsUpdate
  useEffect(() => {
    // console.log("cubeFaceNeedsUpdate", cubeFaceNeedsUpdate);
    // console.log("featureOriginNeedsUpdate", featureOriginNeedsUpdate);
    // console.log("xOffsetNeedsUpdate", xOffsetNeedsUpdate);
    // console.log("yOffsetNeedsUpdate", yOffsetNeedsUpdate);
    // console.log("standardNeedsUpdate", standardNeedsUpdate);
    // console.log("holeFeatureTypeNeedsUpdate", holeFeatureTypeNeedsUpdate);
    // console.log("metricTapNeedsUpdate", metricTapNeedsUpdate);
    // console.log("inchTapNeedsUpdate", inchTapNeedsUpdate);
    // console.log(
    //   "metricClearanceSizeNeedsUpdate",
    //   metricClearanceSizeNeedsUpdate
    // );
    // console.log("inchClearanceSizeNeedsUpdate", inchClearanceSizeNeedsUpdate);
    // console.log("fitNeedsUpdate", fitNeedsUpdate);
    // console.log("drillDepthNeedsUpdate", drillDepthNeedsUpdate);
    // console.log("tapDepthNeedsUpdate", tapDepthNeedsUpdate);
    // console.log("featurePatternNeedsUpdate", featurePatternNeedsUpdate);
    // console.log("patternDiameterNeedsUpdate", patternDiameterNeedsUpdate);
    // if (startAngleNeedsUpdate)
    //   console.log("startAngleNeedsUpdate", startAngleNeedsUpdate);
    // if (arcAngleNeedsUpdate)
    //   console.log("arcAngleNeedsUpdate", arcAngleNeedsUpdate);
    // console.log("numberCircularNeedsUpdate", numberCircularNeedsUpdate);
    // console.log("patternOriginNeedsUpdate", patternOriginNeedsUpdate);
    // console.log("patternXNeedsUpdate", patternXNeedsUpdate);
    // console.log("numberXNeedsUpdate", numberXNeedsUpdate);
    // console.log("patternYNeedsUpdate", patternYNeedsUpdate);
    // console.log("numberYNeedsUpdate", numberYNeedsUpdate);

    if (
      cubeFaceNeedsUpdate ||
      featureOriginNeedsUpdate ||
      xOffsetNeedsUpdate ||
      yOffsetNeedsUpdate ||
      standardNeedsUpdate ||
      holeFeatureTypeNeedsUpdate ||
      metricTapNeedsUpdate ||
      inchTapNeedsUpdate ||
      metricClearanceSizeNeedsUpdate ||
      inchClearanceSizeNeedsUpdate ||
      fitNeedsUpdate ||
      drillDepthNeedsUpdate ||
      drillThroughNeedsUpdate ||
      tapDepthNeedsUpdate ||
      tapThroughNeedsUpdate ||
      featurePatternNeedsUpdate ||
      patternDiameterNeedsUpdate ||
      startAngleNeedsUpdate ||
      arcAngleNeedsUpdate ||
      numberCircularNeedsUpdate ||
      patternOriginNeedsUpdate ||
      patternXNeedsUpdate ||
      numberXNeedsUpdate ||
      patternYNeedsUpdate ||
      numberYNeedsUpdate
    ) {
      // console.log("currentAccordionOpen", currentAccordionOpen);
      // console.log("featureIndex", featureIndex);
      if (currentAccordionOpen - 1 === featureIndex) {
        // console.log("previewData.featureId", previewData.featureId);
        // console.log("featureData.featureId", featureData.featureId);
        // console.log("YES reDraw Needed");
        setPreviewData({ ...featureData });
        setNeedsUpdateFlag(true);
      }
    } else {
      // console.log("currentAccordionOpen", currentAccordionOpen);
      // console.log("featureIndex", featureIndex);
      if (
        currentAccordionOpen - 1 === featureIndex ||
        currentAccordionOpen === 0
      ) {
        if (currentAccordionOpen !== 0) {
          setNeedsUpdateFlag(false);
          setPreviewData({});
        }
        // console.log("NO reDraw Needed");
        // console.log("currentAccordionOpen", currentAccordionOpen);
        // console.log("featureIndex", featureIndex);
      }
    }
  }, [
    featureData,
    userData,
    setPreviewData,
    cubeFaceNeedsUpdate,
    featureOriginNeedsUpdate,
    xOffsetNeedsUpdate,
    yOffsetNeedsUpdate,
    standardNeedsUpdate,
    holeFeatureTypeNeedsUpdate,
    metricTapNeedsUpdate,
    inchTapNeedsUpdate,
    metricClearanceSizeNeedsUpdate,
    inchClearanceSizeNeedsUpdate,
    fitNeedsUpdate,
    drillDepthNeedsUpdate,
    drillThroughNeedsUpdate,
    tapDepthNeedsUpdate,
    tapThroughNeedsUpdate,
    featurePatternNeedsUpdate,
    patternDiameterNeedsUpdate,
    startAngleNeedsUpdate,
    arcAngleNeedsUpdate,
    numberCircularNeedsUpdate,
    patternOriginNeedsUpdate,
    patternXNeedsUpdate,
    numberXNeedsUpdate,
    patternYNeedsUpdate,
    numberYNeedsUpdate,
    currentAccordionOpen,
    featureIndex,
    previewData.featureId,
  ]);

  // Note: This completely resets the logic depth to the most likely assumed
  // engineered values. It drill thru on all clearance holes, and the tap depth
  // is based on the part thickness.
  //
  // The tapThrough and drillThrough will have their own separate changes
  // logics. These must be called via the physical action and not a useEffect
  // as the data logic will fight one another.
  const resetDepthLogic = (tempData) => {
    const holeZ = determineHoleZ(featureData.cubeFace, userData);
    if (
      featureData.holeFeatureType === "CLEARANCE_HOLE" ||
      featureData.holeFeatureType === "CBORE" ||
      featureData.holeFeatureType === "CSINK"
    ) {
      setUserData((currentData) => {
        tempData.plate.features[featureIndex].drillThrough = true;
        tempData.plate.features[featureIndex].drillDepth = holeZ;
        return {
          ...currentData,
          ...tempData,
        };
      });
    } else if (featureData.holeFeatureType === "TAP") {
      const holeData = getHoleData(featureData);
      const { innerDiameter, outerDiameter, pitchMeter, bottomChamferTeeth } =
        holeData;
      if (holeZ.meter <= 2.5 * outerDiameter) {
        tempData.plate.features[featureIndex].tapThrough = true;
        tempData.plate.features[featureIndex].tapDepth = holeZ;
        tempData.plate.features[featureIndex].drillThrough = true;
        tempData.plate.features[featureIndex].drillDepth = holeZ;
      } else {
        //Verify fit
        let tapDepthMeter = 2 * outerDiameter;
        let drillClearance = getTapBottomClearanceMeter(
          bottomChamferTeeth,
          pitchMeter
        );
        let totalDrillClearance =
          drillClearance + getTipLengthMeter(innerDiameter);
        // console.log(totalDrillClearance);
        // console.log("tapDepthMeter", tapDepthMeter);
        while (tapDepthMeter + totalDrillClearance + 0.000508 >= holeZ.meter) {
          // console.log(holeZ.meter);
          // console.log(tapDepthMeter + totalDrillClearance + 0.000508);
          tapDepthMeter = tapDepthMeter - pitchMeter;
          // console.log("tapDepthMeter", tapDepthMeter);
        }
        // console.log(tapDepthMeter + totalDrillClearance + 0.000508);
        tempData.plate.features[featureIndex].tapThrough = false;
        tempData.plate.features[featureIndex].drillThrough = false;
        tempData.plate.features[featureIndex].tapDepth =
          convertMeterToLengthUnit(tapDepthMeter);
        // const tapBottomClearance_Meter =
        //   bottomChamferTeeth * pitchMeter + pitchMeter + 0.001;
        // const minDrillDepth_Meter =
        //   Number(2 * outerDiameter) + Number(tapBottomClearance_Meter);
        tempData.plate.features[featureIndex].drillDepth =
          convertMeterToLengthUnit(tapDepthMeter + drillClearance);
      }
      setUserData((currentData) => {
        return {
          ...currentData,
          ...tempData,
        };
      });
    }
  };

  return (
    <div>
      <hr />
      <div className="accordion__subtitle">Part Origin</div>
      <hr />
      <div className="input_boxes">
        <SelectBox
          name={"Face"}
          keyName="cubeFace"
          featureIndex={featureIndex}
          list={cubeFaces}
          needsUpdate={cubeFaceNeedsUpdate}
          setNeedsUpdate={setCubeFaceNeedsUpdate}
          error={cubeFaceError}
          setError={setCubeFaceError}
          featureNode={featureData}
          auxChange={resetDepthLogic}
        />
        <SelectBox
          name={"Origin"}
          keyName="featureOrigin"
          featureIndex={featureIndex}
          list={startOrigins}
          needsUpdate={featureOriginNeedsUpdate}
          setNeedsUpdate={setFeatureOriginNeedsUpdate}
          error={featureOriginError}
          setError={setFeatureOriginError}
          featureNode={featureData}
        />
        <div className="input_boxes">
          <LengthBox
            name={"xOffset"}
            keyName="xOffset"
            featureIndex={featureIndex}
            needsUpdate={xOffsetNeedsUpdate}
            setNeedsUpdate={setXOffsetNeedsUpdate}
            error={xOffsetError}
            setError={setXOffsetError}
            placeholder="xOffset"
            max={{ meter: "0.149225", mm: "149.23", inch: "5.875" }}
            min={{ meter: "-0.149225", mm: "-149.23", inch: "-5.875" }}
            featureNode={featureData.xOffset}
          />
          <div className="input_boxes">
            <LengthBox
              name={"yOffset"}
              keyName="yOffset"
              featureIndex={featureIndex}
              needsUpdate={yOffsetNeedsUpdate}
              setNeedsUpdate={setYOffsetNeedsUpdate}
              error={yOffsetError}
              setError={setYOffsetError}
              placeholder="yOffset"
              max={{ meter: "0.149225", mm: "149.23", inch: "5.875" }}
              min={{ meter: "-0.149225", mm: "-149.23", inch: "-5.875" }}
              featureNode={featureData.yOffset}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="accordion__subtitle">Hole Specification</div>
      <hr />
      <div className="input_boxes">
        <StandardBox
          name={"Standard"}
          keyName="standard"
          featureIndex={featureIndex}
          needsUpdate={standardNeedsUpdate}
          setNeedsUpdate={setStandardNeedsUpdate}
          error={standardError}
          setError={setStandardError}
          featureNode={featureData.standard}
          auxChange={resetDepthLogic}
        />
        <SelectBox
          name={"Feature Type"}
          keyName="holeFeatureType"
          featureIndex={featureIndex}
          list={holeFeatureTypes}
          needsUpdate={holeFeatureTypeNeedsUpdate}
          setNeedsUpdate={setHoleFeatureTypeNeedsUpdate}
          error={holeFeatureTypeError}
          setError={setHoleFeatureTypeError}
          featureNode={featureData}
          auxChange={resetDepthLogic}
        />
        {featureData.standard === "METRIC" &&
          featureData.holeFeatureType === "TAP" && (
            <SelectBox
              name={"Tap Size"}
              keyName="metricTap"
              featureIndex={featureIndex}
              list={metricTaps}
              needsUpdate={metricTapNeedsUpdate}
              setNeedsUpdate={setMetricTapNeedsUpdate}
              error={metricTapError}
              setError={setMetricTapError}
              featureNode={featureData}
              auxChange={resetDepthLogic}
            />
          )}
        {featureData.standard === "INCH" &&
          featureData.holeFeatureType === "TAP" && (
            <SelectBox
              name={"Tap Size"}
              keyName="inchTap"
              featureIndex={featureIndex}
              list={inchTaps}
              needsUpdate={inchTapNeedsUpdate}
              setNeedsUpdate={setInchTapNeedsUpdate}
              error={inchTapError}
              setError={setInchTapError}
              featureNode={featureData}
              auxChange={resetDepthLogic}
            />
          )}
        {featureData.standard === "METRIC" &&
          featureData.holeFeatureType === "CLEARANCE_HOLE" && (
            <SelectBox
              name={"Size"}
              keyName="metricClearanceSize"
              featureIndex={featureIndex}
              list={metricClearanceSizes}
              needsUpdate={metricClearanceSizeNeedsUpdate}
              setNeedsUpdate={setMetricClearanceSizeNeedsUpdate}
              error={metricClearanceSizeError}
              setError={setMetricClearanceSizeError}
              featureNode={featureData}
              auxChange={resetDepthLogic}
            />
          )}
        {featureData.standard === "METRIC" &&
          featureData.holeFeatureType === "CBORE" && (
            <SelectBox
              name={"Size"}
              keyName="metricClearanceSize"
              featureIndex={featureIndex}
              list={metricCBoreSizes}
              needsUpdate={metricClearanceSizeNeedsUpdate}
              setNeedsUpdate={setMetricClearanceSizeNeedsUpdate}
              error={metricClearanceSizeError}
              setError={setMetricClearanceSizeError}
              featureNode={featureData}
              auxChange={resetDepthLogic}
            />
          )}
        {featureData.standard === "METRIC" &&
          featureData.holeFeatureType === "CSINK" && (
            <SelectBox
              name={"Size"}
              keyName="metricClearanceSize"
              featureIndex={featureIndex}
              list={metricCSinkSizes}
              needsUpdate={metricClearanceSizeNeedsUpdate}
              setNeedsUpdate={setMetricClearanceSizeNeedsUpdate}
              error={metricClearanceSizeError}
              setError={setMetricClearanceSizeError}
              featureNode={featureData}
              auxChange={resetDepthLogic}
            />
          )}
        {featureData.standard === "INCH" &&
          (featureData.holeFeatureType === "CLEARANCE_HOLE" ||
            featureData.holeFeatureType === "CBORE" ||
            featureData.holeFeatureType === "CSINK") && (
            <SelectBox
              name={"Size"}
              keyName="inchClearanceSize"
              featureIndex={featureIndex}
              list={inchClearanceSizes}
              needsUpdate={inchClearanceSizeNeedsUpdate}
              setNeedsUpdate={setInchClearanceSizeNeedsUpdate}
              error={inchClearanceSizeError}
              setError={setInchClearanceSizeError}
              featureNode={featureData}
              auxChange={resetDepthLogic}
            />
          )}
        <SelectBox
          name={"Fit"}
          keyName="fit"
          featureIndex={featureIndex}
          list={fits}
          needsUpdate={fitNeedsUpdate}
          setNeedsUpdate={setFitNeedsUpdate}
          error={fitError}
          setError={setFitError}
          featureNode={featureData}
          auxChange={resetDepthLogic}
          disabled={
            featureData.holeFeatureType === "CLEARANCE_HOLE" ? false : true
          }
        />
        {(featureData.holeFeatureType === "CLEARANCE_HOLE" ||
          featureData.holeFeatureType === "CBORE" ||
          featureData.holeFeatureType === "CSINK") && (
          <DrillDepth
            featureIndex={featureIndex}
            needsUpdateFlag={needsUpdateFlag}
            drillDepthNeedsUpdate={drillDepthNeedsUpdate}
            setDrillDepthNeedsUpdate={setDrillDepthNeedsUpdate}
            drillDepthError={drillDepthError}
            setDrillDepthError={setDrillDepthError}
            drillThroughNeedsUpdate={drillThroughNeedsUpdate}
            setDrillThroughNeedsUpdate={setDrillThroughNeedsUpdate}
            featureNode={featureData}
          />
        )}
        {featureData.holeFeatureType === "TAP" && (
          <DrillDepth
            featureIndex={featureIndex}
            needsUpdateFlag={needsUpdateFlag}
            drillDepthNeedsUpdate={drillDepthNeedsUpdate}
            setDrillDepthNeedsUpdate={setDrillDepthNeedsUpdate}
            drillDepthError={drillDepthError}
            setDrillDepthError={setDrillDepthError}
            drillThroughNeedsUpdate={drillThroughNeedsUpdate}
            setDrillThroughNeedsUpdate={setDrillThroughNeedsUpdate}
            tapDepthNeedsUpdate={tapDepthNeedsUpdate}
            setTapDepthNeedsUpdate={setTapDepthNeedsUpdate}
            tapDepthError={tapDepthError}
            setTapDepthError={setTapDepthError}
            tapThroughNeedsUpdate={tapThroughNeedsUpdate}
            setTapThroughNeedsUpdate={setTapThroughNeedsUpdate}
            tapBothEndsNeedsUpdate={tapBothEndsNeedsUpdate}
            setTapBothEndsNeedsUpdate={setTapBothEndsNeedsUpdate}
            featureNode={featureData}
          />
        )}
      </div>
      <hr />
      <div className="accordion__subtitle">Pattern</div>
      <hr />
      <div className="input_boxes">
        <SelectBox
          name={"Pattern Type"}
          keyName="featurePattern"
          featureIndex={featureIndex}
          list={featurePatterns}
          needsUpdate={featurePatternNeedsUpdate}
          setNeedsUpdate={setFeaturePatternNeedsUpdate}
          error={featurePatternError}
          setError={setFeaturePatternError}
          featureNode={featureData}
        />
        {featureData.featurePattern === "CIRCULAR" && (
          <LengthBox
            name={"Diameter"}
            keyName="patternDiameter"
            featureIndex={featureIndex}
            needsUpdate={patternDiameterNeedsUpdate}
            setNeedsUpdate={setPatternDiameterNeedsUpdate}
            error={patternDiameterError}
            setError={setPatternDiameterError}
            placeholder="diameter"
            max={{ meter: "0.149225", mm: "149.23", inch: "5.875" }}
            min={{ meter: "0.00635", mm: "6.35", inch: "0.25" }}
            featureNode={featureData.patternDiameter}
          />
        )}
        {featureData.featurePattern === "CIRCULAR" && (
          <DegreeBox
            name={"Start Angle"}
            keyName="startAngle"
            featureIndex={featureIndex}
            needsUpdate={startAngleNeedsUpdate}
            setNeedsUpdate={setStartAngleNeedsUpdate}
            error={startAngleError}
            setError={setStartAngleError}
            placeholder="angle"
            max={360}
            min={-360}
            featureNode={featureData.startAngle}
          />
        )}
        {featureData.featurePattern === "CIRCULAR" && (
          <DegreeBox
            name={"Arc Angle"}
            keyName="arcAngle"
            featureIndex={featureIndex}
            needsUpdate={arcAngleNeedsUpdate}
            setNeedsUpdate={setArcAngleNeedsUpdate}
            error={arcAngleError}
            setError={setArcAngleError}
            placeholder="angle"
            max={360}
            min={-360}
            featureNode={featureData.arcAngle}
          />
        )}
        {featureData.featurePattern === "CIRCULAR" && (
          <IntegerBox
            name={"Instances"}
            keyName="numberCircular"
            featureIndex={featureIndex}
            needsUpdate={numberCircularNeedsUpdate}
            setNeedsUpdate={setNumberCircularNeedsUpdate}
            error={numberCircularError}
            setError={setNumberCircularError}
            placeholder="#"
            max={1000}
            min={2}
            featureNode={featureData}
          />
        )}
        {featureData.featurePattern === "LINEAR" && (
          <>
            <SelectBox
              name={"Pattern Origin"}
              keyName="patternOrigin"
              featureIndex={featureIndex}
              list={startOrigins}
              needsUpdate={patternOriginNeedsUpdate}
              setNeedsUpdate={setPatternOriginNeedsUpdate}
              error={patternOriginError}
              setError={setPatternOriginError}
              featureNode={featureData}
            />
            <LengthBox
              name={"patternX"}
              keyName="patternX"
              featureIndex={featureIndex}
              needsUpdate={patternXNeedsUpdate}
              setNeedsUpdate={setPatternXNeedsUpdate}
              error={patternXError}
              setError={setPatternXError}
              placeholder="patternX"
              max={{ meter: "0.149225", mm: "149.23", inch: "5.875" }}
              min={{ meter: "0.00635", mm: "6.35", inch: "0.25" }}
              featureNode={featureData.patternX}
            />
            <IntegerBox
              name={"X increment"}
              keyName="numberX"
              featureIndex={featureIndex}
              needsUpdate={numberXNeedsUpdate}
              setNeedsUpdate={setNumberXNeedsUpdate}
              error={numberXError}
              setError={setNumberXError}
              placeholder="# X"
              max={1000}
              min={1}
              featureNode={featureData}
            />
            <LengthBox
              name={"patternY"}
              keyName="patternY"
              featureIndex={featureIndex}
              needsUpdate={patternYNeedsUpdate}
              setNeedsUpdate={setPatternYNeedsUpdate}
              error={patternYError}
              setError={setPatternYError}
              placeholder="patternY"
              max={{ meter: "0.149225", mm: "149.23", inch: "5.875" }}
              min={{ meter: "0.00635", mm: "6.35", inch: "0.25" }}
              featureNode={featureData.patternY}
            />
            <IntegerBox
              name={"Y increment"}
              keyName="numberY"
              featureIndex={featureIndex}
              needsUpdate={numberYNeedsUpdate}
              setNeedsUpdate={setNumberYNeedsUpdate}
              error={numberYError}
              setError={setNumberYError}
              placeholder="# Y"
              max={1000}
              min={1}
              featureNode={featureData}
            />
          </>
        )}
      </div>
      <div className="input_boxes control_buttons">
        <button
          className="btn control_button"
          onClick={() => {
            handleDelete(setActive, featureIndex, addFlag);
          }}
        >
          Delete
        </button>
        {!addFlag && (
          <button
            className="btn control_button"
            onClick={() => {
              setViewsDisabled(false);
              handleModify(setActive, featureIndex);
            }}
            disabled={!(needsUpdateFlag && !errorsFlag)}
          >
            Modify
          </button>
        )}
        {addFlag && (
          <button
            className="btn control_button"
            onClick={() => {
              setViewsDisabled(false);
              handleAdd(setActive, featureIndex);
            }}
            disabled={!(needsUpdateFlag && !errorsFlag)}
          >
            Add
          </button>
        )}
        <button
          className="btn control_button"
          onClick={() => {
            handleCancel(setActive);
          }}
          disabled={addFlag}
        >
          Cancel
        </button>
      </div>
      {showDevData && (
        <button
          className="btn"
          onClick={() => console.log("featureData", featureData)}
        >
          featureData
        </button>
      )}
    </div>
  );
}
