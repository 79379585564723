import React, { useEffect, useContext } from "react";
import { PlateDataContext } from "../PlateForm/PlateDataContext";

export default function SelectBox(props) {
  const {
    name,
    keyName,
    featureIndex,
    list,
    setNeedsUpdate,
    needsUpdate,
    error,
    featureNode,
    hidden,
    disabled,
    auxChange,
  } = props;
  const { userData, setUserData, onshapeData } = useContext(PlateDataContext);

  useEffect(() => {
    // console.log(keyName, "loaded");
    return () => {
      setNeedsUpdate(false); // no longer testing this
      // console.log(keyName, "unloaded");
    };
  }, [setNeedsUpdate]);

  // compares featureData with onshapeData
  useEffect(() => {
    if (featureNode == null) return;

    // console.log("featureNode", keyName, featureNode[keyName]);

    if (typeof onshapeData.plate.features[featureIndex] == "undefined") {
      setNeedsUpdate(true);
      return;
    }

    // console.log(
    //   "onshapeData",
    //   keyName,
    //   onshapeData.plate.features[featureIndex][keyName]
    // );

    if (
      featureNode[keyName] === onshapeData.plate.features[featureIndex][keyName]
    ) {
      setNeedsUpdate(false);
    } else {
      setNeedsUpdate(true);
    }
  }, [
    userData,
    featureIndex,
    featureNode,
    keyName,
    onshapeData.plate.features,
    setNeedsUpdate,
  ]);

  const handleChange = (values) => {
    const tempData = { ...userData };
    tempData.plate.features[featureIndex][keyName] = values.target.value;
    if (!auxChange) {
      setUserData({
        ...userData,
        ...tempData,
      });
    } else {
      auxChange(tempData);
    }
  };

  return (
    <div
      className={`form-group ${hidden ? "hidden" : ""} ${
        disabled ? "disabled" : ""
      }`}
    >
      {/*reference: https://www.carlrippon.com/drop-down-data-binding-with-react-hooks/ */}
      <div className="label">{name}:</div>
      <select
        value={featureNode[keyName]}
        onChange={handleChange}
        disabled={disabled}
        className={`input select ${needsUpdate && "input_needsUpdate"} ${
          error && "input_invalid"
        }`}
      >
        {list.map(({ label, value }) => (
          <option key={value} value={value}>
            {/* {console.log(value)} */}
            {label}
          </option>
        ))}
      </select>
    </div>
  );
}
