import React, { useContext, useState } from "react";
import ReactDom from "react-dom";
import { QuoteContext } from "./QuoteContext";
import QuoteTable from "./QuoteTable";
import Stripe from "./Stripe";
import axios from "axios";
import { getCookie } from "../auth/helpers";
import X from "./Delete Line Icon.png";

const token = getCookie("token");

const MODAL_STYLES = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFF",
  padding: "10px",
  borderRadius: "10px",
  // width: "100%",
  //   maxHeight: "50px", //screws it all up
  zIndex: 1001,
};

const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,.7)",
  zIndex: 1000,
};

export default function Quote({ open, onClose }) {
  const { quoteLineItems, dispatch } = useContext(QuoteContext);
  const [shippingCost, setShippingCost] = useState(0);
  const [salesTax, setSalesTax] = useState(0);

  if (!open) return null;

  const FormAdd = () => {
    const [addPartNumber, setAddPartNumber] = useState("");

    return (
      <div className="panel nav-list panel-btn data-section panel-btn-end">
        <div className="">
          <label>Enter Part Number </label>
          <input
            type="text"
            key="random1"
            name="addPartNumber"
            value={addPartNumber}
            onChange={(e) => setAddPartNumber(e.target.value)}
            autoComplete="off"
            style={{
              width: "120px",
              margin: "10px",
              borderColor: "black",
              borderRadius: "4px",
              border: "1px",
              borderStyle: "solid",
            }}
          />
        </div>
        <button
          className="btn"
          onClick={() => {
            if (addPartNumber.length !== 9) {
              alert("Part Number requires 9 digits");
              return;
            }

            const header = {
              headers: { Authorization: `Bearer ${token}` },
            };

            const fetchPlate = async () => {
              return await axios
                .get(
                  `${process.env.REACT_APP_API}/plate-data/?partNumber=${addPartNumber}`,
                  header
                )
                .then((res) => {
                  //   console.log("res.data", res.data);
                  if (res.data) {
                    // console.log("res.data.onshapeData", res.data.onshapeData);
                    dispatch({
                      type: "ADD_LINE",
                      onshapeData: res.data.onshapeData,
                    });
                  } else {
                    alert(`Part Number ${addPartNumber} not found.`);
                  }
                  //   return res;
                })
                .catch((error) => {
                  // TODO: add error handler
                  console.log("FETCH PLATE ERROR", error);
                  throw error;
                });
            };
            fetchPlate();
          }}
        >
          Add Item
        </button>
      </div>
    );
  };

  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLES} onClick={onClose} />
      <div style={MODAL_STYLES}>
        <div className="panel-btn panel-btn-end">
          <span
            style={{ padding: "5px" }}
            onClick={onClose}
            className="panel-btn-end"
          >
            <img
              src={X}
              alt="X"
              style={{
                cursor: "pointer",
                width: "20px",
              }}
            />
          </span>
        </div>
        <QuoteTable
          quoteLineItems={quoteLineItems}
          dispatch={dispatch}
          shippingCost={shippingCost}
          setShippingCost={setShippingCost}
          salesTax={salesTax}
          setSalesTax={setSalesTax}
        />
        <FormAdd />
        <div className="panel nav-list panel-btn data-section panel-btn-space-between">
          <button onClick={onClose} className="btn btn-cancel">
            Cancel/Pause
          </button>
          {/* <button
            onClick={() => console.log("write some code here")}
            className="btn btn-action"
          >
            Email
          </button> */}
          <Stripe
            quoteLineItems={quoteLineItems}
            shippingCost={shippingCost}
            salesTax={salesTax}
          />
        </div>
      </div>
    </>,
    document.getElementById("quote")
  );
}
