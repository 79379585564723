export const header = {
  version: "v01",
  date: "6/17/21",
  note:
    "This data must be updated 3 places." &
    "1. Onshape for CAD geometry." &
    "2. React for preview geometry." &
    "3. Python for machining geometry.",
};

export const cubeFaces = [
  { label: "Top Face", value: "TOP_FACE" },
  { label: "Front Face", value: "FRONT_FACE" },
  { label: "Bottom Face", value: "BOTTOM_FACE" },
  { label: "Back Face", value: "BACK_FACE" },
  { label: "Left Face", value: "LEFT_FACE" },
  { label: "Right Face", value: "RIGHT_FACE" },
];

export const startOrigins = [
  { label: "Center Part", value: "CENTER" },
  { label: "Top Left", value: "TOP_LEFT" },
  { label: "Top Right", value: "TOP_RIGHT" },
  { label: "Bottom Right", value: "BOTTOM_RIGHT" },
  { label: "Bottom Left", value: "BOTTOM_LEFT" },
];

export const holeFeatureTypes = [
  { label: "Tap", value: "TAP" },
  { label: "Clearance Hole", value: "CLEARANCE_HOLE" },
  { label: "Counter Bore", value: "CBORE" },
  { label: "Counter Sink", value: "CSINK" },
  //{ label: "Drill", value: "DRILL" }, //punting see holelayout.ods
  //  { label:  "Slot", value: "SLOT" }, //future
  //  { label:  "Ream", value: "REAM" },
  //  { label:  "Pipe Tap", value: "PIPE_TAP" },
  //  { label:  "Raised Boss", value: "RAISED_BOSS" }
];

// Note: Maximum tap depth is closer to 4Xd. I used 3X to be conservative.This will need some testing.
export const metricTaps = [
  {
    label: "M1.6",
    value: "M1.6",
    innerPreviewSize: "0.00125",
    outerPreviewSize: "0.0016",
    pitch: "0.35",
    plugChamferTeeth: 5,
    bottomChamferTeeth: 5,
    maxDepthMultiplier: 3,
  },
  {
    label: "M2",
    value: "M2",
    innerPreviewSize: "0.0016",
    outerPreviewSize: "0.002",
    pitch: "0.4",
    plugChamferTeeth: 5,
    bottomChamferTeeth: 5,
    maxDepthMultiplier: 3,
  },
  {
    label: "M2.5",
    value: "M2.5",
    innerPreviewSize: "0.002",
    outerPreviewSize: "0.0025",
    pitch: "0.45",
    plugChamferTeeth: 5,
    bottomChamferTeeth: 2,
    maxDepthMultiplier: 3,
  },
  {
    label: "M3",
    value: "M3",
    innerPreviewSize: "0.0025",
    outerPreviewSize: "0.003",
    pitch: "0.5",
    plugChamferTeeth: 5,
    bottomChamferTeeth: 2,
    maxDepthMultiplier: 3,
  },
  {
    label: "M4",
    value: "M4",
    innerPreviewSize: "0.0033",
    outerPreviewSize: "0.004",
    pitch: "0.7",
    plugChamferTeeth: 5,
    bottomChamferTeeth: 2,
    maxDepthMultiplier: 3,
  },
  {
    label: "M5",
    value: "M5",
    innerPreviewSize: "0.0042",
    outerPreviewSize: "0.005",
    pitch: "0.8",
    plugChamferTeeth: 5,
    bottomChamferTeeth: 2,
    maxDepthMultiplier: 3,
  },
  {
    label: "M6",
    value: "M6",
    innerPreviewSize: "0.005",
    outerPreviewSize: "0.006",
    pitch: "1",
    plugChamferTeeth: 5,
    bottomChamferTeeth: 2,
    maxDepthMultiplier: 3,
  },
  {
    label: "M8",
    value: "M8",
    innerPreviewSize: "0.0068",
    outerPreviewSize: "0.008",
    pitch: "1.25",
    plugChamferTeeth: 5,
    bottomChamferTeeth: 2,
    maxDepthMultiplier: 3,
  },
  {
    label: "M10",
    value: "M10",
    innerPreviewSize: "0.0085",
    outerPreviewSize: "0.010",
    pitch: "1.5",
    plugChamferTeeth: 5,
    bottomChamferTeeth: 2,
    maxDepthMultiplier: 3,
  },
  {
    label: "M12",
    value: "M12",
    innerPreviewSize: "0.0102",
    outerPreviewSize: "0.012",
    pitch: "1.75",
    plugChamferTeeth: 5,
    bottomChamferTeeth: 2,
    maxDepthMultiplier: 3,
  },
];

export const metricClearanceSizes = [
  {
    label: "M1",
    value: "M1",
    Close: "0.0011",
    Normal: "0.0012",
    Loose: "0.0013",
  },
  {
    label: "M1.1",
    value: "M1.1",
    Close: "0.0012",
    Normal: "0.0013",
    Loose: "0.0014",
  },
  {
    label: "M1.2",
    value: "M1.2",
    Close: "0.0013",
    Normal: "0.0014",
    Loose: "0.0015",
  },
  {
    label: "M1.4",
    value: "M1.4",
    Close: "0.0015",
    Normal: "0.0016",
    Loose: "0.0018",
  },
  {
    label: "M1.6",
    value: "M1.6",
    Close: "0.0017",
    Normal: "0.0018",
    Loose: "0.002",
  },
  {
    label: "M1.8",
    value: "M1.8",
    Close: "0.0020",
    Normal: "0.0021",
    Loose: "0.0022",
  },
  {
    label: "M2",
    value: "M2",
    Close: "0.0022",
    Normal: "0.0024",
    Loose: "0.0026",
  },
  {
    label: "M2.2",
    value: "M2.2",
    Close: "0.0024",
    Normal: "0.0026",
    Loose: "0.0028",
  },
  {
    label: "M2.5",
    value: "M2.5",
    Close: "0.0027",
    Normal: "0.0029",
    Loose: "0.0031",
  },
  {
    label: "M3",
    value: "M3",
    Close: "0.0032",
    Normal: "0.0034",
    Loose: "0.0036",
  },
  {
    label: "M4",
    value: "M4",
    Close: "0.0043",
    Normal: "0.0045",
    Loose: "0.0048",
  },
  {
    label: "M4.5",
    value: "M4.5",
    Close: "0.0048",
    Normal: "0.005",
    Loose: "0.0053",
  },
  {
    label: "M5",
    value: "M5",
    Close: "0.0053",
    Normal: "0.0055",
    Loose: "0.0058",
  },
  {
    label: "M6",
    value: "M6",
    Close: "0.0064",
    Normal: "0.0066",
    Loose: "0.007",
  },
  {
    label: "M7",
    value: "M7",
    Close: "0.0074",
    Normal: "0.0076",
    Loose: "0.008",
  },
  {
    label: "M8",
    value: "M8",
    Close: "0.0084",
    Normal: "0.009",
    Loose: "0.010",
  },
  {
    label: "M10",
    value: "M10",
    Close: "0.0105",
    Normal: "0.011",
    Loose: "0.012",
  },
  {
    label: "M12",
    value: "M12",
    Close: "0.013",
    Normal: "0.0135",
    Loose: "0.0145",
  },
];

export const metricCBoreSizes = [
  {
    label: "M1.6",
    value: "M1.6",
    innerPreviewSize: "0.0018",
    outerPreviewSize: "0.0035",
  },
  {
    label: "M2",
    value: "M2",
    innerPreviewSize: "0.0024",
    outerPreviewSize: "0.0044",
  },
  {
    label: "M2.5",
    value: "M2.5",
    innerPreviewSize: "0.0029",
    outerPreviewSize: "0.0055",
  },
  {
    label: "M3",
    value: "M3",
    innerPreviewSize: "0.0034",
    outerPreviewSize: "0.0065",
  },
  {
    label: "M4",
    value: "M4",
    innerPreviewSize: "0.0045",
    outerPreviewSize: "0.00825",
  },
  {
    label: "M5",
    value: "M5",
    innerPreviewSize: "0.0055",
    outerPreviewSize: "0.00975",
  },
  {
    label: "M6",
    value: "M6",
    innerPreviewSize: "0.0066",
    outerPreviewSize: "0.01125",
  },
  {
    label: "M8",
    value: "M8",
    innerPreviewSize: "0.009",
    outerPreviewSize: "0.01425",
  },
  {
    label: "M10",
    value: "M10",
    innerPreviewSize: "0.011",
    outerPreviewSize: "0.01725",
  },
  {
    label: "M12",
    value: "M12",
    innerPreviewSize: "0.0135",
    outerPreviewSize: "0.01925",
  },
];

export const metricCSinkSizes = [
  {
    label: "M3",
    value: "M3",
    innerPreviewSize: "0.0034",
    outerPreviewSize: "0.00672",
  },
  {
    label: "M4",
    value: "M4",
    innerPreviewSize: "0.0045",
    outerPreviewSize: "0.00896",
  },
  {
    label: "M5",
    value: "M5",
    innerPreviewSize: "0.0055",
    outerPreviewSize: "0.0112",
  },
  {
    label: "M6",
    value: "M6",
    innerPreviewSize: "0.0066",
    outerPreviewSize: "0.01344",
  },
  {
    label: "M8",
    value: "M8",
    innerPreviewSize: "0.009",
    outerPreviewSize: "0.01792",
  },
  {
    label: "M10",
    value: "M10",
    innerPreviewSize: "0.011",
    outerPreviewSize: "0.0224",
  },
  {
    label: "M12",
    value: "M12",
    innerPreviewSize: "0.0135",
    outerPreviewSize: "0.02688",
  },
];

// ********************************************* Inch *********************************************
// Note: Inch algorithm is better than metric

export const inchTaps = [
  {
    label: "1-64",
    value: "1-64",
    pitch: "64 tpi",
    tapDrillDiameter: "0.0595 in",
    majorDiameter: "0.073 in",
    plugChamferTeeth: 5,
    bottomChamferTeeth: 5,
    maxDepthMultiplier: 3,
  },
  {
    label: "2-56",
    value: "2-56",
    pitch: "56 tpi",
    tapDrillDiameter: "0.070 in",
    majorDiameter: "0.086 in",
    plugChamferTeeth: 5,
    bottomChamferTeeth: 5,
    maxDepthMultiplier: 3,
  },
  // {
  //   label: "3-48",
  //   value: "3-48",
  //   pitch: "48 tpi",
  //   tapDrillDiameter: "0.0785 in",
  //   majorDiameter: "0.099 in",
  //   tapClearance: 3.0,
  // },
  {
    label: "4-40",
    value: "4-40",
    pitch: "40 tpi",
    tapDrillDiameter: "0.089 in",
    majorDiameter: "0.112 in",
    plugChamferTeeth: 5,
    bottomChamferTeeth: 2,
    maxDepthMultiplier: 3,
  },
  // {
  //   label: "5-40",
  //   value: "5-40",
  //   pitch: "40 tpi",
  //   tapDrillDiameter: "0.1015 in",
  //   majorDiameter: "0.125 in",
  //   tapClearance: 3.0,
  // },
  {
    label: "6-32",
    value: "6-32",
    pitch: "32 tpi",
    tapDrillDiameter: "0.1065 in",
    majorDiameter: "0.138 in",
    plugChamferTeeth: 5,
    bottomChamferTeeth: 2,
    maxDepthMultiplier: 3,
  },
  {
    label: "8-32",
    value: "8-32",
    pitch: "32 tpi",
    tapDrillDiameter: "0.136 in",
    majorDiameter: "0.164 in",
    plugChamferTeeth: 5,
    bottomChamferTeeth: 2,
    maxDepthMultiplier: 3,
  },
  {
    label: "10-24",
    value: "10-24",
    pitch: "24 tpi",
    tapDrillDiameter: "0.1495 in",
    majorDiameter: "0.190 in",
    plugChamferTeeth: 5,
    bottomChamferTeeth: 2,
    maxDepthMultiplier: 3,
  },
  {
    label: "10-32",
    value: "10-32",
    pitch: "32 tpi",
    tapDrillDiameter: "0.159 in",
    majorDiameter: "0.190 in",
    plugChamferTeeth: 5,
    bottomChamferTeeth: 2,
    maxDepthMultiplier: 3,
  },
  {
    label: "1/4-20",
    value: "1/4-20",
    pitch: "20 tpi",
    tapDrillDiameter: "0.204 in",
    majorDiameter: "0.25 in",
    plugChamferTeeth: 5,
    bottomChamferTeeth: 2,
    maxDepthMultiplier: 3,
  },

  {
    label: "5/16-18",
    value: "5/16-18",
    pitch: "18 tpi",
    tapDrillDiameter: "0.261 in",
    majorDiameter: "0.3125 in",
    plugChamferTeeth: 5,
    bottomChamferTeeth: 2,
    maxDepthMultiplier: 3,
  },
  {
    label: "3/8-16",
    value: "3/8-16",
    pitch: "16 tpi",
    tapDrillDiameter: "0.3125 in",
    majorDiameter: "0.375 in",
    plugChamferTeeth: 5,
    bottomChamferTeeth: 2,
    maxDepthMultiplier: 3,
  },
  {
    label: "7/16-14",
    value: "7/16-14",
    pitch: "14 tpi",
    tapDrillDiameter: "0.368 in",
    majorDiameter: "0.4375 in",
    plugChamferTeeth: 5,
    bottomChamferTeeth: 2,
    maxDepthMultiplier: 3,
  },
  {
    label: "1/2-13",
    value: "1/2-13",
    pitch: "13 tpi",
    tapDrillDiameter: "0.4219 in",
    majorDiameter: "0.500 in",
    plugChamferTeeth: 5,
    bottomChamferTeeth: 2,
    maxDepthMultiplier: 3,
  },
];

// clearance hole, cbore, csink
export const inchClearanceSizes = [
  {
    label: "#0",
    value: "#0",
    entries: {
      Close: {
        holeDiameter: "0.067 in",
        cBoreDiameter: "0.125 in",
        cBoreDepth: "0.06 in",
        cSinkDiameter: "0.138 in",
        cSinkAngle: "82 degree",
      },
      Normal: {
        holeDiameter: "0.076 in",
        cBoreDiameter: "0.125 in",
        cBoreDepth: "0.06 in",
        cSinkDiameter: "0.138 in",
        cSinkAngle: "82 degree",
      },
      Loose: {
        holeDiameter: "0.094 in",
        cBoreDiameter: "0.125 in",
        cBoreDepth: "0.06 in",
        cSinkDiameter: "0.138 in",
        cSinkAngle: "82 degree",
      },
    },
  },
  {
    label: "#1",
    value: "#1",
    entries: {
      Close: {
        holeDiameter: "0.081 in",
        cBoreDiameter: "0.15625 in",
        cBoreDepth: "0.073 in",
        cSinkDiameter: "0.168 in",
        cSinkAngle: "82 degree",
      },
      Normal: {
        holeDiameter: "0.089 in",
        cBoreDiameter: "0.15625 in",
        cBoreDepth: "0.073 in",
        cSinkDiameter: "0.168 in",
        cSinkAngle: "82 degree",
      },
      Loose: {
        holeDiameter: "0.104 in",
        cBoreDiameter: "0.15625 in",
        cBoreDepth: "0.073 in",
        cSinkDiameter: "0.168 in",
        cSinkAngle: "82 degree",
      },
    },
  },
  {
    label: "#2",
    value: "#2",
    entries: {
      Close: {
        holeDiameter: "0.094 in",
        cBoreDiameter: "0.1875 in",
        cBoreDepth: "0.086 in",
        cSinkDiameter: "0.197 in",
        cSinkAngle: "82 degree",
      },
      Normal: {
        holeDiameter: "0.102 in",
        cBoreDiameter: "0.1875 in",
        cBoreDepth: "0.086 in",
        cSinkDiameter: "0.197 in",
        cSinkAngle: "82 degree",
      },
      Loose: {
        holeDiameter: "0.116 in",
        cBoreDiameter: "0.1875 in",
        cBoreDepth: "0.086 in",
        cSinkDiameter: "0.197 in",
        cSinkAngle: "82 degree",
      },
    },
  },
  {
    label: "#3",
    value: "#3",
    entries: {
      Close: {
        holeDiameter: "0.106 in",
        cBoreDiameter: "0.21875 in",
        cBoreDepth: "0.099 in",
        cSinkDiameter: "0.226 in",
        cSinkAngle: "82 degree",
      },
      Normal: {
        holeDiameter: "0.116 in",
        cBoreDiameter: "0.21875 in",
        cBoreDepth: "0.099 in",
        cSinkDiameter: "0.226 in",
        cSinkAngle: "82 degree",
      },
      Loose: {
        holeDiameter: "0.128 in",
        cBoreDiameter: "0.21875 in",
        cBoreDepth: "0.099 in",
        cSinkDiameter: "0.226 in",
        cSinkAngle: "82 degree",
      },
    },
  },
  {
    label: "#4",
    value: "#4",
    entries: {
      Close: {
        holeDiameter: "0.12 in",
        cBoreDiameter: "0.21875 in",
        cBoreDepth: "0.112 in",
        cSinkDiameter: "0.255 in",
        cSinkAngle: "82 degree",
      },
      Normal: {
        holeDiameter: "0.128 in",
        cBoreDiameter: "0.21875 in",
        cBoreDepth: "0.112 in",
        cSinkDiameter: "0.255 in",
        cSinkAngle: "82 degree",
      },
      Loose: {
        holeDiameter: "0.144 in",
        cBoreDiameter: "0.21875 in",
        cBoreDepth: "0.112 in",
        cSinkDiameter: "0.255 in",
        cSinkAngle: "82 degree",
      },
    },
  },
  {
    label: "#5",
    value: "#5",
    entries: {
      Close: {
        holeDiameter: "0.141 in",
        cBoreDiameter: "0.250 in",
        cBoreDepth: "0.125 in",
        cSinkDiameter: "0.281 in",
        cSinkAngle: "82 degree",
      },
      Normal: {
        holeDiameter: "0.156 in",
        cBoreDiameter: "0.250 in",
        cBoreDepth: "0.125 in",
        cSinkDiameter: "0.281 in",
        cSinkAngle: "82 degree",
      },
      Loose: {
        holeDiameter: "0.172 in",
        cBoreDiameter: "0.250 in",
        cBoreDepth: "0.125 in",
        cSinkDiameter: "0.281 in",
        cSinkAngle: "82 degree",
      },
    },
  },
  {
    label: "#6",
    value: "#6",
    entries: {
      Close: {
        holeDiameter: "0.154 in",
        cBoreDiameter: "0.28125 in",
        cBoreDepth: "0.138 in",
        cSinkDiameter: "0.307 in",
        cSinkAngle: "82 degree",
      },
      Normal: {
        holeDiameter: "0.17 in",
        cBoreDiameter: "0.28125 in",
        cBoreDepth: "0.138 in",
        cSinkDiameter: "0.307 in",
        cSinkAngle: "82 degree",
      },
      Loose: {
        holeDiameter: "0.185 in",
        cBoreDiameter: "0.28125 in",
        cBoreDepth: "0.138 in",
        cSinkDiameter: "0.307 in",
        cSinkAngle: "82 degree",
      },
    },
  },
  {
    label: "#8",
    value: "#8",
    entries: {
      Close: {
        holeDiameter: "0.18 in",
        cBoreDiameter: "0.3125 in",
        cBoreDepth: "0.164 in",
        cSinkDiameter: "0.359 in",
        cSinkAngle: "82 degree",
      },
      Normal: {
        holeDiameter: "0.196 in",
        cBoreDiameter: "0.3125 in",
        cBoreDepth: "0.164 in",
        cSinkDiameter: "0.359 in",
        cSinkAngle: "82 degree",
      },
      Loose: {
        holeDiameter: "0.213 in",
        cBoreDiameter: "0.3125 in",
        cBoreDepth: "0.164 in",
        cSinkDiameter: "0.359 in",
        cSinkAngle: "82 degree",
      },
    },
  },
  {
    label: "#10",
    value: "#10",
    entries: {
      Close: {
        holeDiameter: "0.206 in",
        cBoreDiameter: "0.375 in",
        cBoreDepth: "0.19 in",
        cSinkDiameter: "0.411 in",
        cSinkAngle: "82 degree",
      },
      Normal: {
        holeDiameter: "0.221 in",
        cBoreDiameter: "0.375 in",
        cBoreDepth: "0.19 in",
        cSinkDiameter: "0.411 in",
        cSinkAngle: "82 degree",
      },
      Loose: {
        holeDiameter: "0.238 in",
        cBoreDiameter: "0.375 in",
        cBoreDepth: "0.19 in",
        cSinkDiameter: "0.411 in",
        cSinkAngle: "82 degree",
      },
    },
  },
  {
    label: "1/4",
    value: "1/4",
    entries: {
      Close: {
        holeDiameter: "0.266 in",
        cBoreDiameter: "0.4375 in",
        cBoreDepth: "0.25 in",
        cSinkDiameter: "0.531 in",
        cSinkAngle: "82 degree",
      },
      Normal: {
        holeDiameter: "0.281 in",
        cBoreDiameter: "0.4375 in",
        cBoreDepth: "0.25 in",
        cSinkDiameter: "0.531 in",
        cSinkAngle: "82 degree",
      },
      Loose: {
        holeDiameter: "0.297 in",
        cBoreDiameter: "0.4375 in",
        cBoreDepth: "0.25 in",
        cSinkDiameter: "0.531 in",
        cSinkAngle: "82 degree",
      },
    },
  },
  {
    label: "5/16",
    value: "5/16",
    entries: {
      Close: {
        holeDiameter: "0.328 in",
        cBoreDiameter: "0.53125 in",
        cBoreDepth: "0.3125 in",
        cSinkDiameter: "0.656 in",
        cSinkAngle: "82 degree",
      },
      Normal: {
        holeDiameter: "0.344 in",
        cBoreDiameter: "0.53125 in",
        cBoreDepth: "0.3125 in",
        cSinkDiameter: "0.656 in",
        cSinkAngle: "82 degree",
      },
      Loose: {
        holeDiameter: "0.359 in",
        cBoreDiameter: "0.53125 in",
        cBoreDepth: "0.3125 in",
        cSinkDiameter: "0.656 in",
        cSinkAngle: "82 degree",
      },
    },
  },
  {
    label: "3/8",
    value: "3/8",
    entries: {
      Close: {
        holeDiameter: "0.391 in",
        cBoreDiameter: "0.625 in",
        cBoreDepth: "0.375 in",
        cSinkDiameter: "0.781 in",
        cSinkAngle: "82 degree",
      },
      Normal: {
        holeDiameter: "0.406 in",
        cBoreDiameter: "0.625 in",
        cBoreDepth: "0.375 in",
        cSinkDiameter: "0.781 in",
        cSinkAngle: "82 degree",
      },
      Loose: {
        holeDiameter: "0.422 in",
        cBoreDiameter: "0.625 in",
        cBoreDepth: "0.375 in",
        cSinkDiameter: "0.781 in",
        cSinkAngle: "82 degree",
      },
    },
  },
  {
    label: "7/16",
    value: "7/16",
    entries: {
      Close: {
        holeDiameter: "0.453 in",
        cBoreDiameter: "0.71875 in",
        cBoreDepth: "0.4375 in",
        cSinkDiameter: "0.844 in",
        cSinkAngle: "82 degree",
      },
      Normal: {
        holeDiameter: "0.469 in",
        cBoreDiameter: "0.71875 in",
        cBoreDepth: "0.4375 in",
        cSinkDiameter: "0.844 in",
        cSinkAngle: "82 degree",
      },
      Loose: {
        holeDiameter: "0.484 in",
        cBoreDiameter: "0.71875 in",
        cBoreDepth: "0.4375 in",
        cSinkDiameter: "0.844 in",
        cSinkAngle: "82 degree",
      },
    },
  },
  {
    label: "1/2",
    value: "1/2",
    entries: {
      Close: {
        holeDiameter: "0.531 in",
        cBoreDiameter: "0.8125 in",
        cBoreDepth: "0.5 in",
        cSinkDiameter: "0.938 in",
        cSinkAngle: "82 degree",
      },
      Normal: {
        holeDiameter: "0.562 in",
        cBoreDiameter: "0.8125 in",
        cBoreDepth: "0.5 in",
        cSinkDiameter: "0.938 in",
        cSinkAngle: "82 degree",
      },
      Loose: {
        holeDiameter: "0.609 in",
        cBoreDiameter: "0.8125 in",
        cBoreDepth: "0.5 in",
        cSinkDiameter: "0.938 in",
        cSinkAngle: "82 degree",
      },
    },
  },
];

// this must be a lower case value
//"entries" : {
//  "Close" : { "cBoreDiameter" : "-1 mm", "cBoreDepth" : "0 mm", ...
//  "Normal" : { "cBoreDiameter" : "-1 mm", "cBoreDepth" : "0 mm", ...
//  "Loose" : { "cBoreDiameter" : "-1 mm", "cBoreDepth" : "0 mm", ...
export const fits = [
  { label: "Close", value: "Close" },
  { label: "Normal", value: "Normal" },
  { label: "Loose", value: "Loose" },
];

export const featurePatterns = [
  { label: "None", value: "NONE" },
  { label: "Linear", value: "LINEAR" },
  { label: "Circular", value: "CIRCULAR" },
];
