import React, { useEffect, useContext } from "react";
import { PlateDataContext } from "../PlateForm/PlateDataContext";

export default function CheckBox(props) {
  const {
    name,
    keyName,
    featureIndex,
    setNeedsUpdate = () => {},
    needsUpdate,
    featureNode,
    auxChange,
  } = props;
  const { userData, setUserData, onshapeData } = useContext(PlateDataContext);

  // compares featureData with onshapeData
  useEffect(() => {
    if (featureNode == null) return;

    if (typeof onshapeData.plate.features[featureIndex] == "undefined") {
      setNeedsUpdate(true);
      return;
    }

    if (featureNode === onshapeData.plate.features[featureIndex][keyName]) {
      setNeedsUpdate(false);
    } else {
      setNeedsUpdate(true);
    }
  }, [
    userData,
    featureIndex,
    featureNode,
    keyName,
    onshapeData,
    setNeedsUpdate,
  ]);

  const OnChange = () => {
    const tempData = { ...userData };
    tempData.plate.features[featureIndex][keyName] = !featureNode;
    if (!auxChange) {
      setUserData({
        ...userData,
        ...tempData,
      });
    } else {
      auxChange(tempData);
    }
  };

  return (
    <div className={`options ${needsUpdate && "input_needsUpdate"}`}>
      <label className="label">
        <input
          type="checkbox"
          id={keyName}
          name={name}
          value={keyName}
          checked={featureNode}
          onChange={OnChange}
        />
        {name}
      </label>
    </div>
  );
}
