const previewFeatureExternal = (
  ctxRefPreview,
  previewData,
  currentView,
  scale,
  xCenter,
  yCenter
) => {
  // console.log("previewData ", previewData);
  switch (currentView) {
    case "LEFT_FACE":
    case "RIGHT_FACE":
      ctxRefPreview.current.beginPath();
      //upper left hand corner
      ctxRefPreview.current.moveTo(
        xCenter - (previewData.zHeight.meter / 2) * scale,
        yCenter - (previewData.yWidth.meter / 2) * scale
      );
      //upper right hand corner
      ctxRefPreview.current.lineTo(
        xCenter + (previewData.zHeight.meter / 2) * scale,
        yCenter - (previewData.yWidth.meter / 2) * scale
      );
      //lower right hand corner
      ctxRefPreview.current.lineTo(
        xCenter + (previewData.zHeight.meter / 2) * scale,
        yCenter + (previewData.yWidth.meter / 2) * scale
      );
      //lower left hand corner
      ctxRefPreview.current.lineTo(
        xCenter - (previewData.zHeight.meter / 2) * scale,
        yCenter + (previewData.yWidth.meter / 2) * scale
      );
      //home
      ctxRefPreview.current.lineTo(
        xCenter - (previewData.zHeight.meter / 2) * scale,
        yCenter - (previewData.yWidth.meter / 2) * scale
      );

      ctxRefPreview.current.stroke();

      break;

    case "TOP_FACE":
    case "BOTTOM_FACE":
      if (previewData.cornerType === "RADIUS") {
        ctxRefPreview.current.beginPath();
        //upper left hand corner
        ctxRefPreview.current.arc(
          xCenter -
            (previewData.xLength.meter / 2) * scale +
            previewData.cornerValue.meter * scale,
          yCenter -
            (previewData.yWidth.meter / 2) * scale +
            previewData.cornerValue.meter * scale,
          previewData.cornerValue.meter * scale,
          1 * Math.PI,
          1.5 * Math.PI
        );
        //upper right hand corner
        ctxRefPreview.current.arc(
          xCenter +
            (previewData.xLength.meter / 2) * scale -
            previewData.cornerValue.meter * scale,
          yCenter -
            (previewData.yWidth.meter / 2) * scale +
            previewData.cornerValue.meter * scale,
          previewData.cornerValue.meter * scale,
          1.5 * Math.PI,
          0 * Math.PI
        );
        //lower right hand corner
        ctxRefPreview.current.arc(
          xCenter +
            (previewData.xLength.meter / 2) * scale -
            previewData.cornerValue.meter * scale,
          yCenter +
            (previewData.yWidth.meter / 2) * scale -
            previewData.cornerValue.meter * scale,
          previewData.cornerValue.meter * scale,
          0 * Math.PI,
          0.5 * Math.PI
        );
        //lower left hand corner
        ctxRefPreview.current.arc(
          xCenter -
            (previewData.xLength.meter / 2) * scale +
            previewData.cornerValue.meter * scale,
          yCenter +
            (previewData.yWidth.meter / 2) * scale -
            previewData.cornerValue.meter * scale,
          previewData.cornerValue.meter * scale,
          0.5 * Math.PI,
          1 * Math.PI
        );
        ctxRefPreview.current.lineTo(
          xCenter - (previewData.xLength.meter / 2) * scale,
          yCenter -
            (previewData.yWidth.meter / 2) * scale +
            previewData.cornerValue.meter * scale
        );
        ctxRefPreview.current.stroke();
      } else if (previewData.cornerType === "CHAMFER") {
        ctxRefPreview.current.beginPath();
        //upper left hand corner
        ctxRefPreview.current.moveTo(
          xCenter - (previewData.xLength.meter / 2) * scale,
          yCenter -
            (previewData.yWidth.meter / 2) * scale +
            previewData.cornerValue.meter * scale
        );
        ctxRefPreview.current.lineTo(
          xCenter -
            (previewData.xLength.meter / 2) * scale +
            previewData.cornerValue.meter * scale,
          yCenter - (previewData.yWidth.meter / 2) * scale
        );
        //upper right hand corner
        ctxRefPreview.current.lineTo(
          xCenter +
            (previewData.xLength.meter / 2) * scale -
            previewData.cornerValue.meter * scale,
          yCenter - (previewData.yWidth.meter / 2) * scale
        );
        ctxRefPreview.current.lineTo(
          xCenter + (previewData.xLength.meter / 2) * scale,
          yCenter -
            (previewData.yWidth.meter / 2) * scale +
            previewData.cornerValue.meter * scale
        );
        //lower right hand corner
        ctxRefPreview.current.lineTo(
          xCenter + (previewData.xLength.meter / 2) * scale,
          yCenter +
            (previewData.yWidth.meter / 2) * scale -
            previewData.cornerValue.meter * scale
        );
        ctxRefPreview.current.lineTo(
          xCenter +
            (previewData.xLength.meter / 2) * scale -
            previewData.cornerValue.meter * scale,
          yCenter + (previewData.yWidth.meter / 2) * scale
        );
        //lower left hand corner
        ctxRefPreview.current.lineTo(
          xCenter -
            (previewData.xLength.meter / 2) * scale +
            previewData.cornerValue.meter * scale,
          yCenter + (previewData.yWidth.meter / 2) * scale
        );
        ctxRefPreview.current.lineTo(
          xCenter - (previewData.xLength.meter / 2) * scale,
          yCenter +
            (previewData.yWidth.meter / 2) * scale -
            previewData.cornerValue.meter * scale
        );
        //home
        ctxRefPreview.current.lineTo(
          xCenter - (previewData.xLength.meter / 2) * scale,
          yCenter -
            (previewData.yWidth.meter / 2) * scale +
            previewData.cornerValue.meter * scale
        );

        ctxRefPreview.current.stroke();
      } else if (previewData.cornerType === "NONE") {
        ctxRefPreview.current.beginPath();
        //upper left hand corner
        ctxRefPreview.current.moveTo(
          xCenter - (previewData.xLength.meter / 2) * scale,
          yCenter - (previewData.yWidth.meter / 2) * scale
        );
        //upper right hand corner
        ctxRefPreview.current.lineTo(
          xCenter + (previewData.xLength.meter / 2) * scale,
          yCenter - (previewData.yWidth.meter / 2) * scale
        );
        //lower right hand corner
        ctxRefPreview.current.lineTo(
          xCenter + (previewData.xLength.meter / 2) * scale,
          yCenter + (previewData.yWidth.meter / 2) * scale
        );
        //lower left hand corner
        ctxRefPreview.current.lineTo(
          xCenter - (previewData.xLength.meter / 2) * scale,
          yCenter + (previewData.yWidth.meter / 2) * scale
        );
        //home
        ctxRefPreview.current.lineTo(
          xCenter - (previewData.xLength.meter / 2) * scale,
          yCenter - (previewData.yWidth.meter / 2) * scale
        );

        ctxRefPreview.current.stroke();
      }

      break;

    case "FRONT_FACE":
    case "BACK_FACE":
      ctxRefPreview.current.beginPath();
      //upper left hand corner
      ctxRefPreview.current.moveTo(
        xCenter - (previewData.xLength.meter / 2) * scale,
        yCenter - (previewData.zHeight.meter / 2) * scale
      );
      //upper right hand corner
      ctxRefPreview.current.lineTo(
        xCenter + (previewData.xLength.meter / 2) * scale,
        yCenter - (previewData.zHeight.meter / 2) * scale
      );
      //lower right hand corner
      ctxRefPreview.current.lineTo(
        xCenter + (previewData.xLength.meter / 2) * scale,
        yCenter + (previewData.zHeight.meter / 2) * scale
      );
      //lower left hand corner
      ctxRefPreview.current.lineTo(
        xCenter - (previewData.xLength.meter / 2) * scale,
        yCenter + (previewData.zHeight.meter / 2) * scale
      );
      //home
      ctxRefPreview.current.lineTo(
        xCenter - (previewData.xLength.meter / 2) * scale,
        yCenter - (previewData.zHeight.meter / 2) * scale
      );

      ctxRefPreview.current.stroke();

      break;

    default:
      //does nothing
      break;
  }
};

export default previewFeatureExternal;
