import React, { useContext } from "react";
//import UnitContext from "./unit-context";
import Units from "../UnitSwitcher/Units";
import { PlateDataContext } from "../PlateForm/PlateDataContext";
import "./UnitSwitcher.css";

const UnitSwitcher = () => {
  const { unit, setUnit } = useContext(PlateDataContext);

  return (
    <div>
      <label className="btn btn-activated toggle">
        <input
          type="checkbox"
          checked={unit === Units.IN}
          onChange={(e) => {
            // console.log("Checked Value", e.target.checked);
            if (e.target.checked) {
              setUnit(Units.IN);
            } else {
              setUnit(Units.MM);
            }
          }}
        />
        <span className="slider" />
      </label>
      {/* <button
        className="btn control_button"
        onClick={() => {
          console.log(unit);
        }}
      >
        displayUnit
      </button> 
      MATERIAL HERE
      FINISH HERE
      */}
    </div>
  );
};

export default UnitSwitcher;
