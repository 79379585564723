import { v4 as uuidv4 } from "uuid";
import priceItem from "./priceItem";

export const quoteReducer = (state, action) => {
  switch (action.type) {
    case "RESET_DATA":
      return [...action.newData];
    case "ADD_LINE":
      var i;
      for (i = 0; i < state.length; i++) {
        if (state[i].partNumber === action.onshapeData.partNumber) {
          // don't really need to alert them
          // alert(action.onshapeData.partNumber + " is already in cart.");
          return [...state];
        }
      }

      const pricing = priceItem(action.onshapeData);

      const cost_each = priceEach(
        pricing.machiningPriceEach,
        pricing.nonRecurringPrice,
        1
      );

      const item = {
        id: uuidv4(),
        partNumber: action.onshapeData.partNumber,
        description: pricing.description,
        img: action.onshapeData.views.isoView,
        quantity: 1,
        cost_each: cost_each,
        taxable: true,
        machiningPriceEach: pricing.machiningPriceEach,
        nonRecurringPrice: pricing.nonRecurringPrice,
        priceDate: pricing.priceDate,
      };
      return [...state, item];
    case "REMOVE_LINE":
      return state.filter((book) => book.id !== action.id);

    default:
      return state;
  }
};

const getMachiningVolumeDiscount = (quantity) => {
  let volumeDiscount = 1;
  switch (quantity) {
    case 1:
      volumeDiscount = 1;
      break;
    case 2:
      volumeDiscount = 0.98;
      break;
    case 3:
      volumeDiscount = 0.96;
      break;
    case 4:
      volumeDiscount = 0.94;
      break;
    case 5:
      volumeDiscount = 0.92;
      break;
    case 6:
      volumeDiscount = 0.9;
      break;
    case 7:
      volumeDiscount = 0.88;
      break;
    case 8:
      volumeDiscount = 0.86;
      break;
    case 9:
      volumeDiscount = 0.84;
      break;
    default:
      volumeDiscount = 0.82;
      break;
  }

  return volumeDiscount;
};

export const priceEach = (machiningPriceEach, nonRecurringPrice, qty) => {
  const machiningVolumeDiscount = getMachiningVolumeDiscount(qty);
  const totalPrice =
    machiningPriceEach * machiningVolumeDiscount + nonRecurringPrice / qty;
  // console.log(Number(totalPrice).toFixed(2));
  return Number(totalPrice).toFixed(2);
};
