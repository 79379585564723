import React, { useState, useEffect } from "react";
import PlateForm from "./components/PlateForm/PlateForm";
import Layout from "../core/Layout";
import axios from "axios";
import { getCookie, isAuth } from "../auth/helpers";
import PlateData from "./components/json/PlateData.json";

function Plate() {
  const [initialData, setInitialData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const token = getCookie("token");

  useEffect(() => {
    const _id = isAuth()._id;

    const header = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const fetchCurrentPlate = async () => {
      return await axios
        .get(`${process.env.REACT_APP_API}/current-plate/?_id=${_id}`, header)
        .then((res) => {
          //   console.log("res.data", res.data);
          if (res.data) {
            // console.log("res.data", res.data);
            setInitialData(res.data.onshapeData);
            setLoaded(true);
          } else {
            setInitialData(PlateData);
          }
          setLoaded(true);
        })
        .catch((error) => {
          console.log("FETCH PLATE ERROR", JSON.stringify(error));
          console.log(
            `While Calling: ${process.env.REACT_APP_API}/current-plate/?_id=${_id}`
          );
          console.log("Header:", JSON.stringify(header));

          // TODO: add error handler for:
          // -invalid _id
          // -invalid plate_id

          // These errors are from a corrupt database.
          // Instead of handling each error, delete the localStorage
          // This will solve _id issue by forcing user to log back in, but not plate_id
          // I did not solve plate_id at this time since both these are edge cases
          localStorage.clear();

          alert("Database Error: Contact Support");

          throw error;
        });
    };

    fetchCurrentPlate();
  }, [token]);

  return (
    <>
      {loaded && (
        <Layout imageOn={false}>
          <PlateForm initialData={initialData} />
        </Layout>
      )}
    </>
  );
}

export default Plate;
