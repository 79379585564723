import React from "react";

export default function Info(props) {
  const { name, hidden, value } = props;

  return (
    <div className={`form-group form-group-noError ${hidden ? "hidden" : ""}`}>
      <div className="label">{name}:</div>
      <div className="info-text">
        {value}
        {/* <small className="unit">{unit.displayUC}</small> */}
      </div>
      {/* {error && <div className="error">{error}</div>} */}
    </div>
  );
}
