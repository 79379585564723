import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import PlateForm from "../onshape/components/PlateForm/PlateForm";
import Layout from "../core/Layout";
import axios from "axios";
import { getCookie } from "../auth/helpers";
import "react-toastify/dist/ReactToastify.min.css";

const PartNumber = ({ history }) => {
  const [initialData, setInitialData] = useState({});
  const [partNumber, setPartNumber] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [redirectRequired, setRedirectRequired] = useState(false);
  const token = getCookie("token");

  useEffect(() => {
    console.log("history.location.pathname", history.location.pathname);
    let tempPartNumber = history.location.pathname;

    // test for bogus data in part number
    let bogusData = false;

    // Handle /plate/xxxxxxxxx
    //  ex:  http://localhost:3000/plate/rh52pzhsf
    if (tempPartNumber.search("/plate", "") !== -1) {
      tempPartNumber = tempPartNumber.replace("/plate", "");
      bogusData = true;
    }

    if (tempPartNumber.search(/^\/_/) !== -1) bogusData = true;
    if (tempPartNumber.search(/\..+$/) !== -1) bogusData = true;

    // strip out bogus data in part number
    tempPartNumber = tempPartNumber.toLowerCase(); //lowercase
    // partNumber = partNumber.replace(/^_\//, ""); // strip front _/      http://localhost:3000_/n9dtgw7q7_desc.x_t DOES NOT WORK
    tempPartNumber = tempPartNumber.replace(/^\/_/, ""); // strip front  /_     http://localhost:3000/_n9dtgw7q7_desc.x_t
    tempPartNumber = tempPartNumber.replace(/^\//, ""); // strip front just /   http://localhost:3000/n9dtgw7q7_desc.x_t
    tempPartNumber = tempPartNumber.slice(0, 9); // only return part number
    // //tempPartNumber = tempPartNumber.replace(/\..+$/, ""); // strip extension
    // //tempPartNumber = tempPartNumber// http://localhost:3000/n9dtgw7q7rrr.x_t
    console.log("tempPartNumber", tempPartNumber);

    setPartNumber(tempPartNumber);

    // TODO: Most likely will want to create a currentPlate and redirect them to /plate

    if (!tempPartNumber) {
      setLoaded(false);
      return;
    }

    if (bogusData) {
      setRedirectRequired(true);
      return;
    }

    const header = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const fetchPlate = async () => {
      return await axios
        .get(
          `${process.env.REACT_APP_API}/plate-data/?partNumber=${tempPartNumber}`,
          header
        )
        .then((res) => {
          //   console.log("res.data", res.data);
          if (res.data) {
            // console.log("res.data.onshapeData", res.data.onshapeData);
            setInitialData(res.data.onshapeData);
            setLoaded(true);
          }
          //   return res;
        })
        .catch((error) => {
          // TODO: add error handler
          console.log("FETCH PLATE ERROR", error);
          throw error;
        });
    };

    fetchPlate();
  }, [history.location.pathname, token]);

  return (
    <>
      {redirectRequired && <Redirect to={`/${partNumber}`} />}
      {loaded ? (
        <Layout imageOn={false}>
          <PlateForm initialData={initialData} />
        </Layout>
      ) : (
        <Layout imageOn={true}>
          <div className="signin">
            <div className="info info-signin">
              <h1>Sorry.</h1>
              <h1>
                Part Number: <font color="red">{partNumber}</font> not found.
              </h1>
              <h1>Please try again.</h1>
              <div>
                <span className="btn btn-primary">
                  <Link style={{ color: "inherit" }} to="/plate">
                    Click Exit
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default PartNumber;
