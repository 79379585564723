import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { getCookie } from "../../src/auth/helpers";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
// const stripePromise = loadStripe(
//   "pk_test_51HbUNQFwHxDnJA6cLsyGdMx7PBCkYAamuHU5bEKZ3h6krsqlQasmUUzYt3X8kWsx1nzSlw1FwRbw8oQbvqYghZGj00cYBZCaNI"
// );

// TODO: most likely want to do this like
// this: https://www.youtube.com/watch?v=w1oLdAPyuok
// but it f ing works for now

export default function Stripe({ quoteLineItems, shippingCost, salesTax }) {
  const [purchaseSuccessPage, setPurchaseSuccessPage] = useState(false);
  const token = getCookie("token");

  const convertQuote = () => {
    const line_items = [];
    // map thru quoteLineItems for each line item
    quoteLineItems.map((lineItem) => {
      // console.log("lineItem", lineItem);
      const priceItem = {
        price_data: {
          currency: "usd",
          product_data: {
            name: lineItem.partNumber,
            description: lineItem.description,
            images: [lineItem.img],
            metadata: {
              taxable: lineItem.taxable,
            },
          },
          // stripe must be integer in cents, below handles 5 at smallest digit
          unit_amount: Math.round(Number(lineItem.cost_each).toFixed(2) * 100),
        },
        quantity: lineItem.quantity,
      };
      line_items.push(priceItem);
      return null;
    });
    // line item for shipping
    const shippingItem = {
      price_data: {
        currency: "usd",
        product_data: {
          name: "SHIPPING",
        },
        // stripe must be integer in cents, below handles 5 at smallest digit
        unit_amount: Math.round(Number(shippingCost).toFixed(2) * 100),
      },
      quantity: 1,
    };
    line_items.push(shippingItem);
    // line item for salesTax
    const taxItem = {
      price_data: {
        currency: "usd",
        product_data: {
          name: "CA SALES TAX",
        },
        // stripe must be integer in cents, below handles 5 at smallest digit
        unit_amount: Math.round(Number(salesTax).toFixed(2) * 100),
      },
      quantity: 1,
    };
    line_items.push(taxItem);
    return line_items;
  };

  // console.log("quoteLineItems", quoteLineItems);
  // console.log("shippingCost", shippingCost);
  // console.log("salesTax", salesTax);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setPurchaseSuccessPage(true);
    }
  }, []);

  const handleClick = async (event) => {
    console.log("event", event);
    const stripe = await stripePromise;
    const line_items = convertQuote();
    const body = {
      email: "bill@circuitcase.com",
      line_items: line_items,
    };

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    // https://youtu.be/lbEFSP1WAv0?t=2439
    const response = await fetch(
      `${process.env.REACT_APP_API}/create-session`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(body),
        line_items: JSON.stringify(line_items),
      }
    );
    const session = await response.json();
    console.log("session", session);
    // alert(JSON.stringify(session));
    // console.log("session", session);
    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });
    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };

  return (
    <button
      disabled={purchaseSuccessPage}
      className="btn btn-action"
      id="placeOrder-button"
      role="link"
      onClick={handleClick}
    >
      Place Order
    </button>
  );
}
