import React, { useEffect, useContext } from "react";
import { PlateDataContext } from "../PlateForm/PlateDataContext";

export default function IntegerBox(props) {
  const {
    name,
    keyName,
    featureIndex,
    setNeedsUpdate,
    needsUpdate,
    error,
    setError,
    placeholder,
    max,
    min,
    featureNode,
  } = props;
  const { userData, setUserData, onshapeData } = useContext(PlateDataContext);
  const unit = "";

  useEffect(() => {
    // console.log(keyName, "loaded");
    return () => {
      setNeedsUpdate(false); // no longer testing this
      // console.log(keyName, "unloaded");
    };
  }, [setNeedsUpdate]);

  useEffect(() => {
    // console.log("integer error check called");
    if (featureNode == null) return;

    if (
      Number(featureNode[keyName]) <= Number(max) &&
      Number(featureNode[keyName]) >= Number(min)
    ) {
      setError("");
    } else {
      setError(`${min} < val > ${max}`);
    }

    if (featureNode[unit.keyName] === "") {
      setError(`${min} < val > ${max}`);
    }
  }, [userData, featureNode, keyName, max, min, setError]);

  // compares featureData with onshapeData
  useEffect(() => {
    if (featureNode == null) return;

    if (typeof onshapeData.plate.features[featureIndex] == "undefined") {
      setNeedsUpdate(true);
      return;
    }

    // console.log("featureNode[keyName]", featureNode[keyName]);
    // console.log(
    //   "onshapeData.plate.features[featureIndex][keyName]",
    //   onshapeData.plate.features[featureIndex][keyName]
    // );

    if (
      Number(featureNode[keyName]) ===
      Number(onshapeData.plate.features[featureIndex][keyName])
    ) {
      setNeedsUpdate(false);
    } else {
      setNeedsUpdate(true);
    }
  }, [
    userData,
    featureIndex,
    featureNode,
    keyName,
    onshapeData.plate.features,
    setNeedsUpdate,
    unit.keyName,
  ]);

  const handleChange = (values) => {
    //console.log("values.target.value", values.target.value);

    let modifiedValue = values.target.value.replace(/[^0-9]/g, ""); // removes any character which is not a number

    const tempData = { ...userData };
    tempData.plate.features[featureIndex][keyName] = modifiedValue;

    setUserData({
      ...userData,
      ...tempData,
    });
  };

  return (
    <div className="form-group">
      <div className="label">{name}:</div>
      <p>
        <input
          type="text"
          className={`input ${needsUpdate && "input_needsUpdate"} ${
            error && "input_invalid"
          }`}
          name={name}
          placeholder={`${placeholder}...`}
          value={featureNode[keyName]}
          onChange={handleChange}
          autoComplete="off"
        />
        <small className="unit">{unit}</small>
      </p>
      {error && <div className="error">{error}</div>}
    </div>
  );
}

// Future Reference: https://mathjs.org/docs/getting_started.html
